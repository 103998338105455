import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import { PROD_DEFAULT_KEY_WEIGHT } from '../../../../constants/product';
import * as tx from '../../../../constants/strings';

import { 
  breaksToBrs,
  normalizeWeight, 
} from '../../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../../utils/general';
import { getStoreLanguage } from '../../../../utils/language';
import { addMagicManaSymbols } from '../../../../utils/magic';

import LoadingIcon from '../../../Icons/LoadingIcon';
import StaticImage from '../../../Image/StaticImage';

let allActionCreators = Object.assign({});


export class ProductLineDetailsView extends Component {

  constructor(props) {
    super(props);

    this.descriptionElement = React.createRef();
    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getProductName() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.name;
  }

  getProductLineName() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.productLine ? this.props.productObj.productLine.name : '';
  }

  getSku() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.productSet) {
      return this.props.productObj.productSet.name;
    }
    return this.props.productObj.sku.toUpperCase();
  }

  getProductDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      const txt = this.props.productObj.foreignModel.doubleSided ? this.props.productObj.foreignModel.face1.localizedText : this.props.productObj.foreignModel.localizedText;
      return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
    }
    return this.props.productObj.description ? breaksToBrs(this.props.productObj.description, this.descriptionBreakStyle) : '';
  }

  getFlipProductDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard && this.props.productObj.foreignModel.doubleSided) {
      return addMagicManaSymbols(breaksToBrs(this.props.productObj.foreignModel.face2.localizedText, this.descriptionBreakStyle));
    }
    return '';
  }

  shouldTruncateDescription() {
    if(!this.props.productObj) { return false; }
    if(this.props.productObj.foreignModel) {
      return true;
    }
    return false;
  }

  getProductLink() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.getProductPageUrl();
  }

  getProductImage() {
    if(!this.props.productObj) { return <StaticImage imgObj={IMG_GENERIC_PRODUCT} />; }
    if(this.props.productObj.foreignModel && !this.props.productObj.foreignModel.useProductImages) {
      return this.props.productObj.foreignModel.getPrimaryImage()
    } else if(this.props.productObj.media && this.props.productObj.media.length) {
      return (<StaticImage imgObj={{
               src: this.props.productObj.media[0].src, 
               alt: this.props.productObj.media[0].caption ? this.props.productObj.media[0].caption : this.props.t(tx.TX_ALT_PRODUCT_IMAGE), 
             }} />);
    } else {
      return (<StaticImage imgObj={IMG_GENERIC_PRODUCT} />);
    }
  }

  isEditAllowed() {
    if(!this.props.productObj) { return true; }
    if(this.props.productObj.foreignModel) {
      return false;
    }
    return true;
  }

  isComponentImg() {
    if(!this.props.productObj) { return false; }
    return this.props.productObj.foreignModel ? true : false;
  }

  getCost() {
    if(!this.props.productObj) { return null; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      return <div 
              className='pdvTitleCost'
              dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.props.productObj.foreignModel.manaCost, { shadow: true })}} />;
    }    
    return null;
  }

  getSecondaryDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      const txt = this.props.productObj.foreignModel.doubleSided ? this.props.productObj.foreignModel.face1.flavorText : this.props.productObj.foreignModel.flavorText;
      return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
    }
    return '';
  }

  getFlipSecondaryDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard && this.props.productObj.foreignModel.doubleSided) {
      return addMagicManaSymbols(breaksToBrs(this.props.productObj.foreignModel.face2.flavorText, this.descriptionBreakStyle));
    }
    return '';
  }

  // For managed products where language can live on product object
  getProductLanguage() {
    if(!this.props.productObj) { return this.getLanguage(); }
    if(this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.language;
    }
    return this.getLanguage();
  }

  getDefaultDisplayValue(segment) {
    if(!this.props.productLineObj) { return ''; }

    const defValue = this.props.productLineObj.getDefaultValue(PROD_DEFAULT_KEY_WEIGHT, segment);
    if(defValue) {
      return `${normalizeWeight(defValue, getStoreDefaultWeightUnit())} ${getStoreDefaultWeightUnit().display}`;
    }
    return '';
  }

  hasDefaultValue(segment) {
    return this.props.productLineObj.getDefaultValue(PROD_DEFAULT_KEY_WEIGHT, segment) ? true : false;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductLineDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_LINE_DETAILS)}</div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>

            <div className={'detailBlock adminViewEditable'}>
              <div className='detailBlockWrapper'>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_NAME)}</div>
                  <div className='adminViewValue'>{this.props.productLineObj ? this.props.productLineObj.name : ''}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_INV_PL_PERMALINK)}</div>
                  <div className={'adminViewValue'}>{this.props.productLineObj ? this.props.productLineObj.permalink : ''}</div>
                </div> 


                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_ENABLED_QUESTION)}</div>
                  <div className='adminViewValue'>{t(this.props.productLineObj && this.props.productLineObj.isEnabled ? tx.TX_ENABLED : tx.TX_DISABLED)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_INV_PL_IN_MENU)}</div>
                  <div className={'adminViewValue'}>{t(this.props.productLineObj && this.props.productLineObj.inMenu ? tx.TX_YES : tx.TX_NO)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_INV_PL_HAS_BUYLIST)}</div>
                  <div className='adminViewValue'>{t(this.props.productLineObj && this.props.productLineObj.hasBuylist ? tx.TX_YES : tx.TX_NO)}</div>
                </div>
                <div className='adminViewItem'>
                  <div className='adminViewLabel'>{t(tx.TX_INV_PL_HAS_EVENTS)}</div>
                  <div className={'adminViewValue'}>{t(this.props.productLineObj && this.props.productLineObj.hasEvents ? tx.TX_YES : tx.TX_NO)}</div>
                </div>
                {this.props.productLineObj ?
                  <div className='adminViewItem'>
                    <div className='adminViewLabel'>{t(tx.TX_INV_PL_DEFAULT_PRODUCT_WEIGHT)}</div>
                    <div className='adminViewValue'>
                      {this.props.productLineObj.segments && this.props.productLineObj.segments.length === 0 ?
                        <div className='adminViewValueRow'>
                          {this.hasDefaultValue(null) ? this.getDefaultDisplayValue(null) : t(tx.TX_NONE)}
                        </div> :
                        <>
                          {this.props.productLineObj.segments.map((segment, i) => {
                            return <div key={i} className='adminViewValueRow'>
                              {`${segment.nameLocalizationKey ? t(segment.nameLocalizationKey) : segment.name}: ${this.hasDefaultValue(segment) ? this.getDefaultDisplayValue(segment) : t(tx.TX_NONE)}`}
                            </div>;
                          })}
                        </>
                      }
                    </div>
                  </div> :
                  null
                }
              </div>
              <div className='adminViewButtonWrapper'>
                <div className={'shippingMethodDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.productPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductLineDetailsView));