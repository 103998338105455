import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_QUICK_SEARCH, 
} from '../../../../constants/api';
import {
  ICON_CHECK,
  ICON_CLOSE,
  ICON_TRASH,
} from '../../../../constants/icons';
import { 
  TX_CANCEL,
  TX_INV_CATEGORY_REMOVE_PROMPT,
  TX_PLACEHOLDER_CATEGORY_NAME, 
  TX_REMOVE, 
  TX_SAVING,
} from '../../../../constants/strings';

import { 
  productAddCategory,
  productCategoriesFetchAll, 
  productRemoveCategory,
} from '../../../../actions/product';

import { formatServerError } from '../../../../utils/formatting';

import useFormValidationCategoryName from '../../../../hooks/form-validation/useFormValidationCategoryName';
import usePrevious from '../../../../hooks/usePrevious';

import AutocompleteField from '../../../Input/AutocompleteField';
import AutocompleteOptionCategory from '../../../Input/autocomplete/AutocompleteOptionCategory';
import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductCategoryAutocompleteNullResults from './ProductCategoryAutocompleteNullResults';

import styles from './_styles/ProductCategoryEntry.module.scss';

export const ProductCategoryEntry = (props) => {
  
  // Constants
  const minInputLength = 3;


  // Dispatch
  const dispatch = useDispatch();


  // Props
  const {
    closeMethod,
    completeSave,
    product,
    productPending,
    productCategory,
    setRequestError,
  } = props;


  // State
  const [editing, setEditing] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [lastAutocomplete, setLastAutocomplete] = useState(null);
  const [options, setOptions] = useState(null);
  const [requestPending, setRequestPending] = useState(false);


  // Previous data
  const prevProductPending = usePrevious(productPending);


  // Form
  const { 
    value: inputCategoryName, 
    onChange: changeCategoryName, 
    setValue: setCategoryName,
  } = useFormValidationCategoryName(productCategory?.name || '');


  // Internationalization
  const { t } = useTranslation();


  // Refs
  const autocompleteControllerRef = useRef(null);
  const controllerRef = useRef(null);


  // Effects
  useEffect(() => {
    if(productCategory === null) {
      setEditing(true);
    }
  }, [productCategory]);

  useEffect(() => {
    if (prevProductPending === true && productPending === false) {
      setRequestPending(false);
    }
  }, [productPending, prevProductPending]);


  // Methods
  const openEditing = () => {
    if(!editing && !deleteConfirm) {
      setEditing(true);
    }
  };

  const closeEditing = () => {
    setEditing(false);
    if(closeMethod) {
      closeMethod();
    }
    if(productCategory) {
      setCategoryName(productCategory.name);
    }
  };

  const handleDelete = async (evt) => {

    setRequestPending(true);
    setRequestError(null);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const productLinePermalink = product.productLine.permalink;
    const productPermalink = product.permalink;

    // Remove category

    const resp = await dispatch(productRemoveCategory(productCategory.publicUuid, productLinePermalink, productPermalink, controller.signal))
      .catch((errResp) => {
        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);

    if(!resp) {
      return null;
    }

    if(completeSave) {
      await completeSave();
    }
    unpromptDelete();
  };

  const promptDelete = (evt) => {
    if(evt) { 
      evt.preventDefault(); 
      evt.stopPropagation();
    }
    setDeleteConfirm(true);
  };

  const unpromptDelete = () => {
    setDeleteConfirm(false);
  };

  const handleSubmit = async (evt) => {
    if(evt) { evt.preventDefault(); }
    // Submit should do exactly nothing since we only submit when an existing category is selected
  };

  const getNullComponent = () => {
    return <ProductCategoryAutocompleteNullResults lastQuery={lastAutocomplete} />;
  };

  const selectCategory = async (idx) => {

    if(!options || !options[idx]) { return null; }

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    setRequestPending(true);
    setRequestError(null);

    const category = options[idx];

    const productLinePermalink = product.productLine.permalink;
    const productPermalink = product.permalink;

    // Add category
    const resp = await dispatch(productAddCategory(category.publicUuid, productLinePermalink, productPermalink, controller.signal))
      .catch((errResp) => {
        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);

    if(!resp) {
      return null;
    }

    setRequestError(null);

    if(completeSave) {
      await completeSave();
    }
    closeEditing();
  };

  const getOptionComponents = () => {
    
    if(!options) { return null; }

    const components = [];
    for(const op of options) {
      components.push(<AutocompleteOptionCategory category={op} lastQuery={lastAutocomplete} />);
    }
    return components;
  };

  const fetchAutocomplete = useCallback(async (val) => {

    // Cancel the previous request if still active
    if(autocompleteControllerRef.current) {
      autocompleteControllerRef.current.abort();
    }

    const controller = new AbortController();
    autocompleteControllerRef.current = controller;

    const searchParams = {
      [API_KEY_QUICK_SEARCH]: val,
      [API_KEY_LIMIT]: 5,
    }
    
    const searchResp = await dispatch(productCategoriesFetchAll(searchParams, controller.signal))
      .catch((errResp) => {
        // Do nothing
      });

    if(!searchResp) { return null; }

    setOptions(searchResp.data);
    setLastAutocomplete(val);

  }, [ dispatch ]);


  // Effects
  useEffect(() => {

    if(inputCategoryName?.trim && inputCategoryName.trim().length >= minInputLength) {
      fetchAutocomplete(inputCategoryName.trim());
    } else {
      setOptions(null);
    }
    
  }, [ inputCategoryName, minInputLength, fetchAutocomplete ]);


  return (
    <div className={`${styles.ProductCategoryEntry} ${editing ? styles.active : ''} ${deleteConfirm || requestPending ? styles.overlaid : ''}`}>
      <div className={`${styles.categoryLiner}`} onClick={openEditing}>
        <div className={styles.primaryWrapper}>
          {editing ?
            <div className={styles.primaryEditing}>
              <form className={styles.editCategoryForm} onSubmit={handleSubmit}>

                <AutocompleteField
                  className={styles.categoryAutocomplete}
                  value={inputCategoryName}
                  onChange={changeCategoryName} 
                  onSelect={selectCategory}
                  options={getOptionComponents()}
                  placeholder={t(TX_PLACEHOLDER_CATEGORY_NAME)}
                  lastQuery={lastAutocomplete}
                  adminTheme={true}
                  nullComponent={getNullComponent()} />

                <button 
                  className={styles.submitCategoryName}
                  type='submit'>
                  <div className={styles.submitIconWrapper}>
                    {requestPending ?
                      <LoadingIcon /> :
                      <Icon value={ICON_CHECK} />
                    }
                  </div>
                </button>

              </form>
            </div> :
            <div className={styles.primaryDisplay}>{productCategory ? productCategory.name : ''}</div>
          }
        </div>
        <div className={styles.controlWrapper}>
          {editing ?
            <div className={styles.controlEditing} onClick={closeEditing}>
              <Icon value={ICON_CLOSE} />
            </div> :
            <div className={styles.controlDisplay} onClick={promptDelete}>
              <Icon value={ICON_TRASH} />
            </div>
          }
        </div>
        {deleteConfirm || requestPending ?
          <div className={styles.rowOverlay}>
            {requestPending ?
              <div className={styles.pendingOverlay}>
                <div className={styles.pendingCopy}>{t(TX_SAVING)}</div>
              </div> :
              <div className={styles.deleteOverlay}>
                <div className={styles.deleteCopy}>{t(TX_INV_CATEGORY_REMOVE_PROMPT)}</div>
                <div className={styles.deleteConfirm} onClick={handleDelete}>{t(TX_REMOVE)}</div>
                <div className={styles.deleteCancel} onClick={unpromptDelete}>{t(TX_CANCEL)}</div>
              </div>
            }
          </div> :
          null
        }
      </div>
    </div>
  );
};

export default ProductCategoryEntry;

