import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  ICON_ADD,
  ICON_CHECK,
  ICON_CLOSE,
  ICON_EDIT_SIMPLE,
  ICON_REFRESH,
} from '../../../../constants/icons';
import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import {
  ORDER_ITEM_GRADE_TYPE_CONFIRM,
  ORDER_ITEM_GRADE_TYPE_MODIFIED,
  ORDER_ITEM_GRADE_TYPE_REMOVED,
} from '../../../../constants/orders';
import * as tx from '../../../../constants/strings';

import { getConditionObjectFromKey } from '../../../../utils/condition';
import { getCurrencyIncrement } from '../../../../utils/currency';
import { 
  isFormValid, 
  getPriceError, 
} from '../../../../utils/form-validation';
import { 
  formatPrice,
  normalizePrice, 
} from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getMediaAlt, 
  getOrderedMedia, 
} from '../../../../utils/product';

import '../../style/_orderlineitems.scss';

import Dropdown from '../../../Input/Dropdown';
import Icon from '../../../Icons/Icon';
import StaticImage from '../../../Image/StaticImage';

import * as orderActionCreators from '../../../../actions/order';
const allActionCreators = Object.assign({}, orderActionCreators);

export class OrderLineItemGradable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      
      inputCondition: this.getCurrentConditionKey(),
      inputPrice: normalizePrice(this.getCurrentBuyPrice()), 

      pendingCondition: null,
      pendingPrice: null, 

      editingPrice: false,

      errorPrice: '', 

      waitForOrderLoad: false,
    }

    this.controller = null;
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.waitForOrderLoad && prevProps.orderPendingSilently && !this.props.orderPendingSilently) {
      this.setState({ 
        waitForOrderLoad: false, 
        pendingCondition: null,
        pendingPrice: null, 
        inputCondition: this.getCurrentConditionKey(),
        inputPrice: normalizePrice(this.getCurrentBuyPrice()), 
        editingPrice: false,
      });
    }
  }

  componentWillUnmount() {
    if(this.controller) {
      this.controller.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getItemImage() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.inventory) { return null; }

    if(this.props.cartItem.product.foreignModel && !this.props.cartItem.product.foreignModel.useProductImages) {
      return this.props.cartItem.product.foreignModel.getThumbnailImage();
    }

    const productMedia = getOrderedMedia(this.props.cartItem.product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: productMedia[0].src, 
                  alt: getMediaAlt(this.props.cartItem.product, productMedia[0]), 
                  noTranslate: true,
                }} />
    }
    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  getItemName() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.inventory) { return null; }

    return this.props.cartItem.product.name;
  }

  getItemProductLine() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.product.productLine) { return null; }

    return this.props.cartItem.product.productLine.name;
  }

  conditionDropdownOptions() {

    const nullOptions = [{
      display: tx.TX_null,
      value: '',
    }];
    if(!this.props.cartItem || !this.props.cartItem.inventory) { return nullOptions; }

    const conditionOptions = [];
    const allConditions = this.props.cartItem.inventory.allConditions();

    for(const cond of allConditions) {
      conditionOptions.push({
        display: cond.short,
        value: cond.key,
      });
    }
    return conditionOptions;
  }

  changeCondition(evt) {
    this.setState({ 
      inputCondition: evt.target.value, 
    }, () => {

      const itemPrice = this.props.cartItem && this.props.cartItem.price ? this.props.cartItem.price : null;
      if(!itemPrice) {
        return null;
      }

      // TODO: this mapping should be sent from server
      const conditionMappings = {
        NM: 1.0,
        LP: 0.85,
        MP: 0.7,
        HP: 0.5,
        D: 0.01,
      }

      if(itemPrice*conditionMappings[this.state.inputCondition]) {
        this.setState({ 
          inputPrice: normalizePrice(itemPrice*conditionMappings[this.state.inputCondition]) 
        }, () => {
          this.submitAction();
        });
      } else {
        this.submitAction();
      }
    });
  }

  changePrice(evt) {
    this.setState({
      inputPrice: evt.target.value,
    }, () => {
      if(this.state.errorPrice) {
        this.validatePrice(false);
      }
    });
  }

  validatePrice(shouldNormalize = true) {
    this.setState({ 
      inputPrice: shouldNormalize ? normalizePrice(this.state.inputPrice) : this.state.inputPrice, 
      errorPrice: getPriceError(this.state.inputPrice), 
    });
  }

  toggleReturnToInventory() {
    this.props.setReturn(this.props.cartItem);
  }

  getLanguageLabel() {
    if(!this.props.cartItem.inventory || !this.props.cartItem.inventory.language) { return tx.TX_null; }
    return this.props.cartItem.inventory.language.nameTranslation || tx.TX_null;
  }

  getFinishLabel() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.inventory || !this.props.cartItem.inventory.finish) { return tx.TX_null; }
    if(!this.props.cartItem.product.hasFinish()) {
      return tx.TX_null;
    }
    return this.props.cartItem.inventory.finish.name || tx.TX_null;
  }

  validateAll() {
    const errorObj = {
      errorPrice: getPriceError(this.state.inputPrice),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  getGradeType() {
    // Only deals with confirmed/modified; removed is set directly
    const itemCondition = this.props.cartItem && this.props.cartItem.inventory && this.props.cartItem.inventory.condition ? this.props.cartItem.inventory.condition.key : null;
    const itemPrice = this.props.cartItem && this.props.cartItem.price ? this.props.cartItem.price : null;

    const gradedCondition = this.getCurrentConditionKey();
    const gradedPrice = this.getCurrentBuyPrice();

    if(itemCondition === gradedCondition && parseFloat(itemPrice) === parseFloat(gradedPrice)) {
      return ORDER_ITEM_GRADE_TYPE_CONFIRM;
    }
    return ORDER_ITEM_GRADE_TYPE_MODIFIED;
  }

  async addAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({ 
        waitForOrderLoad: true,
      });
      
      const apiData = {
        cart_item_id: this.props.cartItem.id,
        grade_note: null, 
        grade_type: this.getGradeType(),
      };

      const gradeResp = await this.props.ordersAdminUpdateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, this.props.itemGrade.publicUuid, controller.signal)
        .catch((errResp) => {
      
          if(controller.signal.aborted) { return null; }

          console.error(errResp);
          this.setState({ 
            waitForOrderLoad: false,
          });
        });

      if(gradeResp === null) {
        return null;
      }

      this.props.makeClean(true);
    }
  }

  async removeAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({ 
        waitForOrderLoad: true,
      });
      
      const apiData = {
        cart_item_id: this.props.cartItem.id,
        grade_note: null, 
        grade_type: ORDER_ITEM_GRADE_TYPE_REMOVED,
      };

      let gradeResp = null;
      if(!this.props.itemGrade) {

        gradeResp = await this.props.ordersAdminCreateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      } else {

        gradeResp = await this.props.ordersAdminUpdateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, this.props.itemGrade.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      }

      if(gradeResp === null) {
        return null;
      }

      this.props.makeClean(true);
    }
  }

  async submitAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      this.setState({ 
        waitForOrderLoad: true,
        pendingCondition: this.state.inputCondition,
        pendingPrice: this.state.inputPrice, 
      });
      
      const apiData = {
        cart_item_id: this.props.cartItem.id,
        grade_note: null, 
        grade_type: ORDER_ITEM_GRADE_TYPE_MODIFIED,
        condition: this.state.inputCondition,
        price: this.state.inputPrice,
      };

      let gradeResp = null;
      if(!this.props.itemGrade) {

        gradeResp = await this.props.ordersAdminCreateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      } else {

        gradeResp = await this.props.ordersAdminUpdateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, this.props.itemGrade.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      }

      if(gradeResp === null) {
        return null;
      }

      this.props.makeClean(true);
    }
  }

  async resetAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(!this.state.waitForOrderLoad) {

      if(this.controller) {
        this.controller.abort();
      }
      const controller = new AbortController();
      this.controller = controller;

      const pendingState = {
        waitForOrderLoad: true,
      }

      const itemCondition = this.props.cartItem && this.props.cartItem.inventory && this.props.cartItem.inventory.condition ? this.props.cartItem.inventory.condition.key : null;
      const itemPrice = this.props.cartItem && this.props.cartItem.price ? this.props.cartItem.price : null;
      
      const apiData = {
        cart_item_id: this.props.cartItem.id,
        grade_note: null, 
        grade_type: ORDER_ITEM_GRADE_TYPE_CONFIRM,
      };

      if(itemCondition) {
        apiData['condition'] = itemCondition;
        pendingState['pendingCondition'] = itemCondition;
        pendingState['inputCondition'] = itemCondition;
      }
      if(itemPrice) {
        apiData['price'] = itemPrice;
        pendingState['pendingPrice'] = itemPrice;
        pendingState['inputPrice'] = itemPrice;
      }

      this.setState(pendingState);

      let gradeResp = null;
      if(!this.props.itemGrade) {

        gradeResp = await this.props.ordersAdminCreateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      } else {

        gradeResp = await this.props.ordersAdminUpdateItemGrade(apiData, this.props.order.publicUuid, this.props.orderGrading.publicUuid, this.props.itemGrade.publicUuid, controller.signal)
          .catch((errResp) => {
        
            if(controller.signal.aborted) { return null; }

            console.error(errResp);
            this.setState({ 
              waitForOrderLoad: false,
            });
          });
      }

      if(gradeResp === null) {
        return null;
      }

      this.props.makeClean(true); 
    }
  }

  getCurrentCondition() {
    if(this.state && this.state.pendingCondition) {
      return getConditionObjectFromKey(this.state.pendingCondition);
    }
    if(this.props.itemGrade && this.props.itemGrade.conditionGraded) {
      return this.props.itemGrade.conditionGraded;
    }
    if(this.props.cartItem && this.props.cartItem.inventory && this.props.cartItem.inventory.condition) { 
      return this.props.cartItem.inventory.condition; 
    }
    return null
  }

  getCurrentConditionKey() {
    const currentCondition = this.getCurrentCondition();
    return currentCondition ? currentCondition.key : '';
  }

  getCurrentConditionValue() {
    const currentCondition = this.getCurrentCondition();
    return currentCondition ? currentCondition.short : tx.TX_null;
  }

  getCurrentBuyPrice() {
    if(this.state && this.state.pendingPrice) {
      return parseFloat(this.state.pendingPrice);
    }
    if(this.props.itemGrade) {
      if(this.props.itemGrade.isRemoved) {
        return 0;
      } else if(this.props.itemGrade.isModified && this.props.itemGrade.priceGraded) {
        return this.props.itemGrade.priceGraded;
      }
    }
    if(!this.props.cartItem || !this.props.cartItem.inventory || !this.props.cartItem.inventory.buyPrice) { return 0; }
    
    return this.props.cartItem.inventory.buyPrice || 0;
  }

  showRemoved() {
    if(this.props.itemGrade && this.props.itemGrade.gradeType && this.props.itemGrade.gradeType === ORDER_ITEM_GRADE_TYPE_REMOVED) {
      return true;
    }
    return false;
  }

  beginEditPrice() {
    this.setState({ editingPrice: !this.state.editingPrice });
  }

  closeEditPrice() {
    this.setState({ errorPrice: getPriceError(this.state.inputPrice) }, () => {
      if(!this.state.errorPrice) {
        this.submitAction();
      }
    });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      this.closeEditPrice();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderLineItem OrderLineItemGradable'}>
      <div className={`oliLiner ${this.showRemoved() ? 'greyed' : ''}`}>

        <div className='visibleSection'>
          
          <div className='visibleBodyWrapper'>

            <div className='itemBlock'>
              <div className='itemWrapper'>
                <div className='itemMediaWrapper'>
                  {this.getItemImage()}
                </div>
                <div className='itemDescriptionWrapper'>
                  <div className='itemDescription'>
                    <div className='nameWrapper'>{this.getItemName()}</div>
                    <div className='productLineWrapper'>{this.getItemProductLine()}</div>
                    <div className='detailsWrapper'>
                      {t(this.getFinishLabel()) ?
                        <div className='detailText'>{t(this.getFinishLabel())}</div> :
                        null
                      }
                      <div className='detailText'>{t(this.getLanguageLabel())}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='editableBlock'>
              <div className='editableBlockLiner'>

                <div className={'conditionBlockWrapper inputBlockWrapper'}>
                  <div className={'conditionBlock inputBlock'}>
                    <div className='blockLabel'>{t(tx.TX_CONDITION)}</div>

                    <div className='conditionDropdownWrapper blockValue'>
                      {this.state.waitForOrderLoad || this.showRemoved() ?
                        <div className='conditionFixed'>{t(this.getCurrentConditionValue())}</div> :
                        <Dropdown 
                          className='conditionDropdown'
                          options={this.conditionDropdownOptions()}
                          name={t(tx.TX_CONDITION)}
                          value={this.state.inputCondition}
                          noTranslate={false}
                          onChange={this.changeCondition.bind(this)} />
                      }
                    </div>

                  </div>
                </div>

                <div className={'priceBlockWrapper inputBlockWrapper'}>
                  <div className={'priceBlock inputBlock'}>
                    <div className='blockLabel'>{t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}</div>
                    {this.state.waitForOrderLoad || this.showRemoved() ?
                      <div 
                        className={`priceDisplayWrapper blockValue ${!this.getCurrentBuyPrice() ? 'noneValue' : ''}`}
                        dangerouslySetInnerHTML={{ 
                          __html: formatPrice(this.getCurrentBuyPrice(), { addTags: true, language: this.getLanguage(), zeroValue: t(tx.TX_NONE) }) 
                        }} /> :
                      <div className='priceInputWrapper'>
                        {this.state.editingPrice ?
                          <div className='editingWrapper'>
                            <input
                              type='number'
                              min={0}
                              max={10000000}
                              step={getCurrencyIncrement()}
                              className={`priceInput ${this.state.errorPrice ? 'error' : ''}`}
                              name={t(tx.TX_INV_ADD_PRODUCT_BUY_PRICE)}
                              value={this.state.inputPrice}
                              onChange={this.changePrice.bind(this)}
                              onBlur={this.validatePrice.bind(this)}
                              onKeyPress={this.handleKeyPress.bind(this)} />
                            <div className='actionIcon'  onClick={this.closeEditPrice.bind(this)}>
                              <div className='actionIconLiner'>
                                <Icon value={ICON_CHECK} />
                              </div>
                            </div>
                          </div> :
                          <div className='fixedWrapper' onClick={this.beginEditPrice.bind(this)}>
                            <div 
                              className='pseudoInput'
                              dangerouslySetInnerHTML={{ 
                                __html: formatPrice(parseFloat(this.state.inputPrice), { addTags: true, language: this.getLanguage() }) 
                              }} />
                            <div className='actionIcon'>
                              <div className='actionIconLiner'>
                                <Icon value={ICON_EDIT_SIMPLE} />
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                </div>

              </div>
            </div>

          </div>

          <div className='actionBlock'>
            <div className='actionBlockLiner'>
              {!this.state.waitForOrderLoad ?
                <div className='actionIconRow'>
                  {this.showRemoved() ?
                    <div className='actionIconWrapper'>
                      <div className={`actionIcon`} onClick={this.addAction.bind(this)}>
                        <div className='actionIconLiner'>
                          <Icon value={ICON_ADD} />
                        </div>
                      </div>
                    </div> :
                    <div className='actionIconWrapper'>
                      <div className={`actionIcon`} onClick={this.removeAction.bind(this)}>
                        <div className='actionIconLiner'>
                          <Icon value={ICON_CLOSE} />
                        </div>
                      </div>
                    </div>
                  }
                  <div className='actionIconWrapper'>
                    <div className={`actionIcon`} onClick={this.resetAction.bind(this)}>
                      <div className='actionIconLiner'>
                        <Icon value={ICON_REFRESH} />
                      </div>
                    </div>
                  </div>
                </div> :
                null
              }
            </div>
          </div>

        </div>
        <div className={`drawerSection`}>

        </div>
        <div className={`loadingMask ${this.state.waitForOrderLoad ? 'active' : ''}`}>

        </div>

      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderLineItemGradable));