import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getPageSizeOptions, 
} from '../../../../utils/table';

let allActionCreators = Object.assign({});

export class AdminTablePagination extends Component {

  static defaultProps = {
    paginate: true,
  };

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getTotalPages() {
    if(!this.props.resultsCount) {
      return 0;
    }
    return Math.ceil(parseInt(this.props.resultsCount) / parseInt(this.props.pageSize));
  }

  changePageSize(evt) {
    this.props.changePage(1, evt.target.value);
  }

  changePageNum(pageNum) {
    this.props.changePage(parseInt(pageNum), parseInt(this.props.pageSize));
  }

  canGoBack() {
    return this.props.pageNum > 1;
  }

  canGoForward() {
    return this.getTotalPages() > this.props.pageNum;
  }

  goBackOne() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(parseInt(this.props.pageNum - 1));
  }

  goBackAll() {
    if(this.canGoBack() === false) {
      return;
    }
    this.changePageNum(1);
  }

  goForwardOne() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.props.pageNum + 1));
  }

  goForwardAll() {
    if(this.canGoForward() === false) {
      return;
    }
    this.changePageNum(parseInt(this.getTotalPages()));
  }

	render() {

		const {t} = this.props;
	
    return <div className={'AdminTablePagination'}>
      <div className='adminTablePagination'>
        <div className='atpControls'>
          <div className={this.canGoBack() ? 'lhsControls' : 'lhsControls disabled'}>
            <div className={'backAll'} onClick={this.goBackAll.bind(this)}>
              <div className='leftArrow'></div>
            </div>
            <div className={'backOne'} onClick={this.goBackOne.bind(this)}>
              <div className='leftArrow'></div>
            </div>
          </div>
          {parseInt(this.getTotalPages()) ?
            <div className='currentPage' dangerouslySetInnerHTML={
              {__html: t(tx.TX_ADMIN_TABLE_PAGE_STATUS, {
                current: parseInt(this.props.pageNum).toLocaleString(this.getLanguage()), 
                total: parseInt(this.getTotalPages()).toLocaleString(this.getLanguage()), 
                interpolation: {escapeValue: false}
              })}} /> :
            <div className={'currentPage loadingPage'}>{t(tx.TX_LOADING)}</div>
          }
          <div className={this.canGoForward() ? 'rhsControls' : 'rhsControls disabled'}>
            <div className={'forwardOne'} onClick={this.goForwardOne.bind(this)}>
              <div className='rightArrow'></div>
            </div>
            <div className={'forwardAll'} onClick={this.goForwardAll.bind(this)}>
              <div className='rightArrow'></div>
            </div>
          </div>
        </div>
        <div className='atpSize'>
          <div className='atpSizeCopy'>{t(tx.TX_ADMIN_TABLE_RESULTS_PER_PAGE)}</div>
          <div className='atpSizeDropdown'>
            <select value={this.props.pageSize} onChange={this.changePageSize.bind(this)}>
              {getPageSizeOptions().map((size, i) => {
                return <option key={i} value={size}>{size}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTablePagination));


