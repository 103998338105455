import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import styles from './_styles/YuGiOhCardImage.module.scss';

const YuGiOhCardImage = (props) => {
    
  // Props
  const {
    yugiohObj, 
    thumbnail, 
    onLoadLow, 
    placeholderImg, 
    loadDelay,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  return (
    <div className={styles.YuGiOhCardImage}>
      <div className={styles.imageWrapper}>
        {thumbnail ? (
          <StaticImage
            imgObj={{
              src: yugiohObj.primaryImageSrc(),
              alt: yugiohObj.localizedName,
              noTranslate: true,
            }}
            onImgLoad={onLoadLow}
          />
        ) : (
          <ProgressiveImage
            alt={yugiohObj.imageAlt(t)}
            className={styles.yugiohObjImageImg}
            src={yugiohObj.primaryImageSrc()}
            thumbnailSrc={yugiohObj.thumbnailImageSrc()}
            loadDelay={loadDelay}
            placeholder={placeholderImg}
            onLoadLow={onLoadLow}
          />
        )}
      </div>
    </div>
  );
};
export default YuGiOhCardImage;