import React, { useState } from 'react';

import styles from './_styles/AdminPageSectionToggle.module.scss';

const AdminPageSectionToggle = (props) => {

  // Props
  const {
    children,
    updateEditCount,
  } = props;

  // Components
  const [ViewComponent, EditComponent] = children;

  // State
  const [editing, setEditing] = useState(false);

  // Methods
  const toggleEdit = () => {
    setEditing(!editing);
    if(updateEditCount) {
      updateEditCount(!editing ? 1 : -1);
    }
  };

  return (
    <div className={styles.AdminPageSectionToggle}>
      {editing
        ? React.cloneElement(EditComponent, { toggleEdit })
        : React.cloneElement(ViewComponent, { toggleEdit })
      }
    </div>
  );
};

export default AdminPageSectionToggle;
