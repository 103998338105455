import { useCallback, useEffect, useState } from 'react';

import {
  ERROR_CATCH_ALL,
  ERROR_PERMALINK_FORMATTING,
  ERROR_PERMALINK_MISSING,
  ERROR_PERMALINK_TAKEN,
  ERROR_STRING_LENGTH_32, 
} from '../../constants/errors';
import { PL_RESERVED_PERMALINKS } from '../../constants/product';

import { stringToPermalink } from '../../utils/formatting';
import { isJsEvent } from '../../utils/general';

const useFormValidationPermalink = (initialValue) => {
  
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  const validate = useCallback((test = null, config = {}) => {
  
    try {

      let respError = '';
      let testVal = test;

      if(isJsEvent(testVal)) {
        testVal = test.target.value;
      }

      // Configs
      const allowEmpty = config.allowEmpty || false;
      const allowedPermalink = config.allowedPermalink || null;
      const additionalReservedPermalinks = config.reservedPermalinks || [];
      const showError = !(config.hideError || false);

      const notAllowed = new RegExp(/[^a-z0-9\-_]+/g);
      const reservedPermalinks = additionalReservedPermalinks.concat(PL_RESERVED_PERMALINKS).filter(item => item !== allowedPermalink);

      if(value === null) {
        testVal = value;
      }

      if(allowEmpty && testVal === '') {
        return respError;
      }

      if(testVal === null || testVal === '' || !testVal) {
        respError = ERROR_PERMALINK_MISSING;
      } else if(testVal.trim().length === 0) {
        respError = ERROR_PERMALINK_MISSING;
      } else if(testVal.trim().length > 32) {
        respError = ERROR_STRING_LENGTH_32;
      } else if(notAllowed.test(testVal)) {
        respError = ERROR_PERMALINK_FORMATTING;
      } else if(reservedPermalinks.includes(testVal)) {
        return ERROR_PERMALINK_TAKEN;
      }

      if(showError) {
        setError(respError);
      }
      return respError;

    } catch(err) {
      console.error(err);
      setError(ERROR_CATCH_ALL);
      return ERROR_CATCH_ALL;
    }
  }, [value]);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setValue(stringToPermalink(value, true));
  };

  // Only validate onChange if there is already an error
  useEffect(() => {
    if(error) {
      validate(value);
    }
  }, [error, validate, value]);

  const changeValue = (val) => {
    setValue(stringToPermalink(val));
  };

  return {
    value,
    error,
    validate,
    onChange: handleChange,
    setValue: changeValue,
  };
};

export default useFormValidationPermalink;
