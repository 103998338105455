import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { TX_SELECT_ALL } from '../../../../constants/strings';
import { 
  TABLE_VALUE_MOBILE_BREAKPOINT,
  TC_HEADER_SORT_DIR_ASC, 
  TC_VIEW_KEY_LIST,
  TR_LABEL, 
  TR_VAL_PRIORITIES, 
} from '../../../../constants/tables';

import {  
  getCurrentTablePriority, 
  getElementPositionStyle, 
  getElementSortKey, 
  getTableNoun,
  getTableDetailViewSelectableWidth,
  isCheckboxElement,
  isElementSortable, 
  isTableDetailViewSelectable,
  orderSchema, 
  shouldDisplayElement, 
} from '../../../../utils/table';

import Checkbox from '../../../Input/Checkbox';

let allActionCreators = Object.assign({});

export class AdminTableHeader extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableWidth: window.innerWidth * 0.7, // Initial assumption based on %age at 1200 width; state set again after mount
      tablePriority: TR_VAL_PRIORITIES[0], // Initial assumption of everything visible
    };

    // Create refs
    this.tableSection = React.createRef();

    // Bind event listeners
    this.updateWidthStateThrottled = _.throttle(this.updateWidthState.bind(this), 250);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidthStateThrottled, false);

    if(this.tableSection && this.tableSection.current) {
      this.setState({ 
        tableWidth: this.tableSection.current.clientWidth, 
        tablePriority: getCurrentTablePriority(this.props.schema, this.tableSection.current.clientWidth, window.innerWidth),
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidthStateThrottled, false);
  }

  updateWidthState() {
    this.setState({ 
      tableWidth: this.tableSection.current.clientWidth,
      tablePriority: getCurrentTablePriority(this.props.schema, this.tableSection.current.clientWidth, window.innerWidth),
    });
  }

  getElementClass(elem) {
    
    let elemClass = 'athElement';

    if(isElementSortable(elem) === false) {
      elemClass += ' noSort';
    }

    return elemClass;
  }

  isColumnSorted(elem) {
    return this.props.sortKey === getElementSortKey(elem);
  }

  getArrowClass(elem) {

    if(this.isColumnSorted(elem) === false) {
      return 'noArrow';
    }

    let elemClass = 'athSortArrow';
    if(this.props.sortDir === TC_HEADER_SORT_DIR_ASC) {
      elemClass += ' upArrow';
    }

    return elemClass;
  }

  shouldShowListView() {
    if(window.innerWidth <= TABLE_VALUE_MOBILE_BREAKPOINT) {
      return true;
    } else if(this.props.viewKey === TC_VIEW_KEY_LIST) {
      return true;
    }
    return false;
  }

	render() {

		const {t} = this.props;
	
    return <div className={this.shouldShowListView() ? 'AdminTableHeader' : 'AdminTableHeader singleValue'} ref={this.tableSection}>
      <div className='athWrapper'>
        {this.shouldShowListView() ?
          <>
            {orderSchema(this.props.schema).map((col, i) => {

              if(shouldDisplayElement(col, this.state.tablePriority, window.innerWidth) === false) {
                return null;
              }

              return <div 
                      key={i} 
                      className={this.getElementClass(col)} 
                      style={getElementPositionStyle(col, this.props.schema, this.state.tablePriority, this.state.tableWidth, window.innerWidth)}
                      onClick={() => this.props.changeSort(col)}>
                <div className='athElementWrapper'>
                  {isCheckboxElement(col) ?
                    <>
                      <div className='athCheckboxWrapper'>
                        <Checkbox 
                          adminTheme={true}
                          name={t(TX_SELECT_ALL)}
                          id={`ath-checkbox-master`}
                          value={t(TX_SELECT_ALL)}
                          checked={this.props.allRowsSelected}
                          ambiguousCheck={!this.props.allRowsSelected && this.props.selectedRows && this.props.selectedRows.length > 0}
                          onChange={this.props.selectAllRows.bind(this)} />
                      </div>
                    </> :
                    <>
                      <div className={'athLabel'}>{t(col[TR_LABEL])}</div>
                      <div className='athSort'>
                        <div className={this.getArrowClass(col)}></div>
                      </div>
                    </>
                  }
                </div>
              </div>;
            })}
          </> :
          <>
            {isTableDetailViewSelectable(this.props.config) ?
              <div 
                className={'athElement noSort'} 
                style={{ 
                  width: `${getTableDetailViewSelectableWidth(this.props.config)}px`, 
                }}>
                <div className='athElementWrapper'>
                  <div className='athCheckboxWrapper athCheckboxSmall'>
                    <Checkbox 
                      adminTheme={true}
                      name={t(TX_SELECT_ALL)}
                      id={`ath-checkbox-master`}
                      value={t(TX_SELECT_ALL)}
                      checked={this.props.allRowsSelected}
                      ambiguousCheck={!this.props.allRowsSelected && this.props.selectedRows && this.props.selectedRows.length > 0}
                      onChange={this.props.selectAllRows.bind(this)} />
                  </div>
                </div>
              </div> :
              null
            }
            <div 
              className={'athElement noSort'} 
              style={isTableDetailViewSelectable(this.props.config) ? 
                { 
                  width: `calc(100% - ${getTableDetailViewSelectableWidth(this.props.config)}px)`,
                  left: `${getTableDetailViewSelectableWidth(this.props.config)}px`,
                } : {}
              }>
              <div className='athElementWrapper'>
                <div className={'athLabel'}>{t(getTableNoun(this.props.config))}</div>
                <div className='athSort'>
                  <div className={'noArrow'}></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableHeader));


