
import { toDate } from 'date-fns-tz';

import { 
	API_FOREIGN_MODEL_YUGIOH_CARD,
	API_FOREIGN_MODEL_YUGIOH_SEALED,
	API_FOREIGN_MODEL_YUGIOH_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_YUGIOH } from '../../constants/finishes';
import { 
	LANG_EN,
	LANG_PRODUCT_YUGIOH, 
} from '../../constants/languages';
import { 
	YUGIOH_CARD_SKU_PREFIX, 
	// YUGIOH_SEALED_SKU_PREFIX, 
} from '../../constants/syncs/yugioh';
import { 
	TX_YUGIOH, 
	TX_null, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import { dynamicSort } from '../../utils/sort';

import YuGiOhCardImage from '../../components/YuGiOh/YuGiOhCardImage';
import YuGiOhCardThumbnail from '../../components/Gallery/blocks/elements/YuGiOhCardThumbnail';
import ProductPageDetailsYuGiOh from '../../components/ProductPage/blocks/ProductPageDetailsYuGiOh';



export class YugiohSet extends ProductSet {

	constructor(props) {

		super(props);
		
		this.id = props.id || null;
		this.code = props.code || props.set_code || this.code || '';
		this.name = props.name || props.set_name || this.name || '';
		this.cardCount = parseInt(props.cardCount) || parseInt(props.card_count) || 0;

		const releaseDateValue = props.releaseDate || props.release_date || null;
		this.releaseDate = null;
		if(releaseDateValue) {
			this.releaseDate = releaseDateValue instanceof Date ? releaseDateValue : toDate(releaseDateValue);
		}	
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_YUGIOH_SET;
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class YugiohCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.name = props.name || '';
		this.bodyText = props.bodyText || props.body_text || '';
		this.languageCode = props.languageCode || props.lang || LANG_EN;
		this.rarity = props.rarity || '';
		this.collectorNumber = props.collectorNumber || props.collector_number || '';
		this.tcgplayerId = props.tcgplayerId || props.tcg_product_id || null;
		this.cost = parseInt(props.cost) || 0;
		this.defense = parseInt(props.defense) || 0;
		this.linkRating = parseInt(props.linkRating) || parseInt(props.link_rating) || 0;
				
		this.yugiohSet = new YugiohSet(props.yugiohSet || props.yugioh_set || {});
		this.images = new YugiohCardImages(props.images || props.card_media || {});

		const cardTypeObj = props.type || props.cardType || props.card_type || null;
		this.type = cardTypeObj ? new YugiohCardType(cardTypeObj) : null;

		const cardAttributeObj = props.attribute || props.cardAttribute || props.card_attribute || null;
		this.attribute = cardAttributeObj ? new YugiohCardAttribute(cardAttributeObj) : null;

		const monsterTypeObj = props.monsterType || props.monster_type || null;
		this.monsterType = monsterTypeObj ? new YugiohMonsterType(monsterTypeObj) : null;

		const linkArrowsArray = props.linkArrows || props.link_arrows || [];
		this.linkArrows = [];
		for(const la of [ ...linkArrowsArray ].sort(dynamicSort('sequence'))) {
			this.linkArrows.push(new YugiohLinkArrow(la));
		}

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isYugiohCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_YUGIOH_CARD;
	}

	get languageObj() {
		for(const lang of LANG_PRODUCT_YUGIOH) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return LANG_PRODUCT_YUGIOH[0];
	}

	get exportInventoryDetails() {
		return true;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		return FINISHES_YUGIOH;
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get setName() {
		return this.yugiohSet?.name || '';
	}

	primaryImageSrc(isBack = false) {
		return this.images?.primarySrc || YugiohCardImages.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.images?.thumbnailSrc || YugiohCardImages.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <YuGiOhCardImage 
		          yugiohObj={this}
		          loadDelay={0}
		          placeholderImg={YugiohCardImages.defaultPrimarySrc()}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <YuGiOhCardImage 
		          yugiohObj={this}
		          loadDelay={0}
		          placeholderImg={YugiohCardImages.defaultThumbnailSrc()}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.name;
		const setName = this.setName;

		if(!cardName) {
			return t ? t(TX_YUGIOH) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_YUGIOH));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return YuGiOhCardThumbnail;
	}

	get componentProductPageDetails() {
		return ProductPageDetailsYuGiOh;
	}

	get productPageLayoutClass() {
		const { PROD_PAGE_LAYOUT_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,			
		});
	}

	get productSku() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');

		const idBase = '000000000';
		const idString = this.id ? this.id.toString() : '';
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${YUGIOH_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');
		
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	get set() {
		return this.yugiohSet || null;
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class YugiohSealed {

	constructor(props) {

		if(!props) { props = {}; }

		this.id = props.id || null;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_YUGIOH_SEALED;
	}
}


export class YugiohLinkArrow {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class YugiohCardAttribute {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class YugiohCardType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class YugiohMonsterType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class YugiohCardImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.default = props.default || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/yugioh/cardback.webp';
	}

	static defaultThumbnailSrc() {
		return '/img/product/yugioh/cardback.webp';
	}

	get primarySrc() {
		return this.default || YugiohCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.default || YugiohCardImages.defaultThumbnailSrc();
	}
}


export class YugiohImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.default = props.default || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/noimage.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/noimage.png';
	}

	get primarySrc() {
		return this.default || YugiohCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.default || YugiohCardImages.defaultThumbnailSrc();
	}
}


























