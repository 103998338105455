import React from 'react';

import SaveProvider from '../../context/SaveProvider';
import ViewCategory from './ViewCategory';


const ViewCategoryWrapper = () => (
  <SaveProvider>
    <ViewCategory />
  </SaveProvider>
);

export default ViewCategoryWrapper;
