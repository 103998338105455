import React from 'react';
import FormProvider from '../../context/FormProvider';
import AdminForm from './AdminForm';

const AdminFormWrapper = (props) => {
  
  const { initialValues, ...rest } = props;

  return (
    <FormProvider initialValues={initialValues}>
      <AdminForm {...rest} />
    </FormProvider>
  );
};

export default AdminFormWrapper;
