import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  TX_COLLECTOR_NUMBER,
  TX_POKEMON_ATTRIBUTE_ATTACK,
  TX_POKEMON_ATTRIBUTE_ATTACKS,
} from '../../../constants/strings';

import parentStyles from './_styles/ProductPageDetails.module.scss';
import styles from './_styles/ProductPageDetailsPokemon.module.scss';

let allActionCreators = Object.assign({});

export class ProductPageDetailsPokemon extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getProductName() {
    return this.props.productObj.foreignModel.name;
  }

  getProductTypeLine() {
    return this.props.productObj.foreignModel.type || '';
  }

  getProductRules() {
    return this.props.productObj.foreignModel.rules;
  }

  getAttacks() {
    if(!this.props.productObj || !this.props.productObj.foreignModel) { return []; }
    return this.props.productObj.foreignModel.attacks || [];
  }

  getProductSecondaryDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');

    const txt = this.props.productObj.foreignModel.flavorText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  hasBoardedCopy() {
    return this.getProductRules().length > 0 || this.getProductSecondaryDescription();
  }

  render() {

    if(!this.props.productObj || !this.props.productObj.foreignModel || !this.props.productObj.foreignModel.isPokemonCard) { return null; }

    const {t} = this.props;

    return <div className={`${styles.ProductPageDetailsPokemon} ${parentStyles.ProductPageDetails}`}>
      <div className={`${styles.detailsWrapper} ${parentStyles.detailsWrapper}`}>
        <div className={styles.pokemonWrapper} lang={this.props.productObj.foreignModel.language}>
          <div className={styles.faceWrapper}>
            <div className={parentStyles.headerWrapper}>
              <div className={styles.titleWrapper} lang={this.props.productObj.foreignModel.language}>
                <span className={styles.titleText}>{this.getProductName()}</span>
              </div>
              <div className={styles.typeLine}>{this.getProductTypeLine()}</div>
            </div>
            {this.getAttacks().length ?
              <div className={styles.attributeBlock}>
                <div className={styles.attrTitleWrapper}>
                  <div className={styles.attrTitleValue}>
                    {t(this.getAttacks().length === 1 ? TX_POKEMON_ATTRIBUTE_ATTACK : TX_POKEMON_ATTRIBUTE_ATTACKS)}
                  </div>
                </div>
                {this.getAttacks().map((att, i) => {
                  return <div key={i} className={styles.attackWrapper}>
                    <div className={styles.attackTitle}>
                      <div className={styles.titleValue}>{att.name}</div>
                      <div className={styles.titleDamage}>{att.damage}</div>
                    </div>
                    {att.text ?
                      <div className={styles.attackText}>{att.text}</div> :
                      null
                    }
                  </div>
                })}
              </div> :
              null
            }
            {this.hasBoardedCopy() ?
              <div className={parentStyles.borderWrapper}>
                <div className={styles.pokemonDetails}>
                  {this.getProductRules().map((rule, j) => {
                    return <div key={j} className={styles.pokemonDesc}>{rule.rule}</div>
                  })}
                  <div 
                    className={styles.pokemonSecondaryDesc}
                    dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
                </div>
              </div> :
              null
            }
          </div>
          {this.props.productObj.foreignModel.collectorNumber ?
            <div className={styles.collectorNumberWrapper}>
              <div className={styles.collectorNumber}>
                <div className={styles.numberLabel}>{t(TX_COLLECTOR_NUMBER)}</div>
                <div className={styles.numberValue}>{this.props.productObj.foreignModel.collectorNumber}</div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsPokemon));