import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import './_styles/_adminform.scss';

let allActionCreators = Object.assign({});

export class AdminFormBreadcrumbs extends Component {

  constructor(props) {
    super(props);

    this.state = {
      maxStepReached: 0,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.currentStep > this.state.maxStepReached) {
      this.setState({ maxStepReached: this.props.currentStep });
    }

    // Listen for reset signal
    if(prevProps.resetSignal !== this.props.resetSignal) {
      this.setState({ maxStepReached: 0 });
    }
  }

  isStepDisabled(stepIndex) {
    return stepIndex > this.state.maxStepReached ? ' atfStepDisabled' : '';
  }

  handleClick(stepIndex) {
    if(this.isStepDisabled(stepIndex) === '') {
      this.props.changeStep(stepIndex);
    }
  }

	render() {

    const {t} = this.props;

    return <div className={'AdminFormBreadcrumbs'}>
      <div className='afbWrapper'>
        {this.props.steps.map((step, i) => {
          return <div 
                  key={i}
                  className={this.props.currentStep === i ? 'afbStepWrapper currentStep' : 'afbStepWrapper' + this.isStepDisabled(i)}
                  onClick={() => this.handleClick(i)}>
            <div className='afbStepBody'>{t(step.name)}</div>
            <div className='afbStepUnderline'></div>
          </div>;
        })}
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminFormBreadcrumbs));