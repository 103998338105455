
import { v4 as uuidv4 } from 'uuid';

import { 
	AUTH_TOKEN_DURATION, 
	REAUTH_TOKEN_DURATION, 
} from '../constants/api';
import { 
	STORAGE_BROWSER_SESSION,
	STORAGE_REAUTH_SESSION,
	STORAGE_USER_SESSION,
} from '../constants/storage';
import { URL_HOME } from '../constants/urls';

import * as storage from '../persistance/storage';



export function isLoggedIn() {

	const authObj = storage.get(STORAGE_USER_SESSION);

	if(authObj === null) {
		return false;
	}

	if(authObj && authObj.auth_token && authObj.master_token) {
		return true;
	}
	return false;
}

export function getAuthToken() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(authObj && authObj.auth_token) {
		return authObj.auth_token;
	}
	return '';
}

export function getNeedsPasswordReset() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(authObj && authObj.need_password_reset) {
		return authObj.need_password_reset;
	}
	return '';
}

export function getNeedsReAuth() {
	const reAuthObj = storage.get(STORAGE_REAUTH_SESSION);
	const authObj = storage.get(STORAGE_USER_SESSION);

	if(!reAuthObj || !reAuthObj.sid || !reAuthObj.reAuth) {
		storage.remove(STORAGE_REAUTH_SESSION);
		return true;
	}

	if(!authObj || !authObj.auth_token) {
		storage.remove(STORAGE_REAUTH_SESSION);
		return true;
	}

	const last = Date(reAuthObj.reAuth);
	const now = Date.now();
	if(now < last + REAUTH_TOKEN_DURATION) {
		storage.remove(STORAGE_REAUTH_SESSION);
		return true;
	}
	
	if(needAuthReset() === false && authObj.auth_token !== reAuthObj.sid) {
		storage.remove(STORAGE_REAUTH_SESSION);
		return true;
	}

	return false;
}

export function setReAuth() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(!authObj || !authObj.auth_token) {
		return null;
	}

	storage.put(STORAGE_REAUTH_SESSION, {
		sid: authObj.auth_token,
		reAuth: Date.now(),
	});
	return '';
}

export function getMyId() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(authObj && authObj.id) {
		return authObj.id;
	}
	return null;
}

export function getAuthObj() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(!authObj) {
		return {};
	}
	return authObj;
}

export function getResetObj() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	if(!authObj) {
		storage.clear();
		window.location.href = URL_HOME;
	}
	const resetObj = {
		email_address: authObj.email_address,
		master_token: authObj.master_token,
		store_id: process.env.REACT_APP_STORE_UUID,
	};
	return resetObj;
}

export function needAuthReset() {
	const authObj = storage.get(STORAGE_USER_SESSION);
	const refreshThreshold = Date.now() + 10*60*1000; // Plus 10 minutes
	if(authObj && authObj.master_token && refreshThreshold > authObj.token_exp) {
		return true;
	}
	return false;
}

export function saveAuth(tokenObj) {
	let authObj = storage.get(STORAGE_USER_SESSION);
	if(!authObj) {
		authObj = {};
	}
	storage.put(STORAGE_USER_SESSION, Object.assign({}, authObj, tokenObj, {
		token_exp: Date.now() + AUTH_TOKEN_DURATION,
	}));
}

export function logout() {
	storage.clear();
}

export function getSessionId() {
	let sessionObj = storage.get(STORAGE_BROWSER_SESSION);

	if(!sessionObj || !sessionObj.id) {
		sessionObj = createNewSession();
	}
	return sessionObj.id;
}

// Sessions should be created only when needing a fresh cart;
// Currently logout and checkout success
export function createNewSession() {
	const sessionObj = {
		id: uuidv4(),
	};
	storage.put(STORAGE_BROWSER_SESSION, sessionObj);
	return sessionObj;
}
