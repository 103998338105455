

import { STORE_CODE } from '../constants/store';


export const isFeatureAvailable = (feature) => {
	if(feature?.disabledStores?.includes(STORE_CODE)) {
      return false;
    }
    if(feature?.enabledStores) {
      return feature.enabledStores.includes(STORE_CODE);
    }
    return true;
}