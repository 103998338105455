import React from 'react';
import { useTranslation } from 'react-i18next';

import { TX_PRODUCT_COUNT } from '../../../constants/strings';

import { addHighlightTags } from '../../../utils/formatting';

import parentStyles from './_styles/AutocompleteOption.module.scss';
// import styles from './_styles/AutocompleteOptionCategory.module.scss';

const AutocompleteOptionCategory = (props) => {

  // Props
  const {
    category,
    lastQuery,
  } = props;


  // Internationalization
  const { t } = useTranslation();


  // Methods
  const getHighlightedAutocomplete = (suggestString) => {
    if (!lastQuery) {
      return suggestString;
    }
    return addHighlightTags(suggestString, lastQuery, parentStyles.suggestStrong);
  };
  

  // Render
  return (
    <div className={`${parentStyles.AutocompleteOptionCategory} ${parentStyles.AutocompleteOption}`}>
      <div className={parentStyles.optionLiner}>
        <div
          className={parentStyles.optionLinePrimary}
          dangerouslySetInnerHTML={{
            __html: getHighlightedAutocomplete(category ? category.name : ''),
          }}
        />
        <div className={parentStyles.optionLineSecondary}>
          {category ? (
            <>
              <span className={parentStyles.secondaryLabel}>{t(TX_PRODUCT_COUNT)}</span>
              <span className={parentStyles.secondaryValue}>{category.productCount || 0}</span>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
export default AutocompleteOptionCategory;

