import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import { PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT } from '../../constants/product';
import * as tx from '../../constants/strings';
import { 
  URL_ADMIN_INVENTORY,
  URL_ADMIN_INVENTORY_BULK_UPLOAD, 
} from '../../constants/urls';

import { formatServerError } from '../../utils/formatting';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';

import AdminTitle from '../Admin/AdminTitle';
import BulkUploadProcessing from './blocks/BulkUploadProcessing';
import BulkUploadResults from './blocks/BulkUploadResults';

import './_styles/_bulkupload.scss';

import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class BulkUploadView extends Component {

  constructor(props) {
    super(props);

    this.state = {

      requestError: null,
      requestPending: false,
      requestUpload: null,
      requestResults: null,
      requestCount: null,

      debugMode: false,

      filters: {},
      pageNum: 1,
      pageSize: PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT,
      pageSort: null,
    };

    this.pollInterval = null;
    this.pollDuration = 15000;

    this.controller = null;
  }

  componentDidMount() {
    this.fetchBulkUpload(this.state.pageNum, this.state.pageSize, this.state.pageSort, this.state.filters, false);
  }

  componentDidUpdate(prevProps, prevState) {
    // Initiate polling
    if(prevState.requestUpload === null && this.state.requestUpload !== null && !this.state.requestUpload.isComplete() && this.state.requestUpload.isFresh()) {
      this.pollInterval = setInterval(() => {
        this.fetchBulkUpload(this.state.pageNum, this.state.pageSize, this.state.pageSort, this.state.filters);
      }, this.pollDuration);
    }
  }

  componentWillUnmount() {
    if(this.pollInterval) {
      clearInterval(this.pollInterval);
    }
  }

  async fetchBulkUpload(pageNum = 1, pageSize = PROD_BULK_UPLOAD_RESULT_PAGE_SIZE_DEFAULT, sort = null, filters = {}, updateSilently = true) {

    if(this.controller) {
      this.controller.abort();
    }
    const controller = new AbortController();
    this.controller = controller;

    this.setState({
      filters: filters,
      pageNum: pageNum,
      pageSize: pageSize,
      pageSort: sort,
    });

    if(updateSilently === false) {
      this.setState({
        requestError: null,
        requestPending: true,
      });
    }

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
    const uploadResp = await this.props.productFetchBulkUpload(getParams, this.props.match.params.uploadUUID, controller.signal)
      .catch((errResp) => {
        if(controller.signal.aborted) {
          // Request aborted; do nothing
        } else if(errResp) {
          console.error(errResp);
          this.setState({
            requestError: formatServerError(errResp),
            requestPending: false,
          });
        }
      });

    if(!uploadResp) {
      return null;
    }

    this.setState({
      requestError: null,
      requestPending: false,
      requestUpload: uploadResp.upload,
      requestResults: uploadResp.results_data,
      requestCount: uploadResp.results_count,
    });
  }

  toggleDebugMode() {
    this.setState({ debugMode: !this.state.debugMode });
  }

  render() {

    const {t} = this.props;

    return <div className={'BulkUploadView AdminPage'}>
      <AdminTitle
        title={tx.TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: tx.TX_CATALOG,
          },
          {
            url: URL_ADMIN_INVENTORY_BULK_UPLOAD,
            title: tx.TX_INV_BULK_UPLOAD_BULK_UPLOADS,
          },
        ]} />
      <div className='adminBody'>
        <div className='adminSection'>

          <div className='adminSectionTitle'>
            <div className='asTitleWrapper'>{t(tx.TX_INV_BULK_UPLOAD_PRODUCT)}</div>
          </div>

          <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>
          
          <div className='buProgressWrapper'>
            <BulkUploadProcessing
              requestPending={this.state.requestPending}
              requestCount={this.state.requestCount}
              requestUpload={this.state.requestUpload}
              debugMode={this.state.debugMode}
              toggleDebugMode={this.toggleDebugMode.bind(this)} />
          </div>

          <div className='buComponentWrapper'>
            <BulkUploadResults
              requestUpload={this.state.requestUpload}
              count={this.state.requestCount}
              requestPending={this.state.requestPending}
              results={this.state.requestResults}
              callApi={this.fetchBulkUpload.bind(this)}
              filters={this.state.filters}
              pageNum={this.state.pageNum}
              pageSize={this.state.pageSize}
              pageSort={this.state.pageSort}
              debugMode={this.state.debugMode} />
          </div>
          
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BulkUploadView));