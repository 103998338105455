import React from 'react';

import styles from './_styles/WarningButton.module.scss';

export const WarningButton = (props) => {
  
  // Constants
  const SIZE_MEDIUM = 'medium';

  // Props
  const {
    adminTheme = false,
    children,
    className,
    disabled,
    onClick,
    size = SIZE_MEDIUM,
    style = {},
    type = 'button',
  } = props;

  // Methods
  const getClass = () => {

    const rootClass = styles.WarningButton;
    const sizeClass = size && size !== SIZE_MEDIUM ? styles[size] : '';
    const adminClass = adminTheme ? styles.adminTheme : '';
    const customClass = className || '';

    return `${rootClass} ${sizeClass} ${adminClass} ${customClass}`;
  };

  const handleClick = (evt) => {
    if(onClick) {
      onClick(evt);
    }
  };

  // Render
  return (
    <button 
      type={type} 
      className={getClass()}
      disabled={!!disabled}
      onClick={handleClick}
      style={style}
    >
      {children}
    </button>
  );
};

export default WarningButton;




