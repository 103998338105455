
// index.js

import { Suspense } from 'react';
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux';
import { Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import * as p from './js/constants/permissions';
import { PM_VALUE_KEY_SQUARE_CC } from './js/constants/payment-methods';
import * as u from './js/constants/urls';

import { stringFormat } from './js/utils/formatting';

// Common components
import App from './js/components/App/App';

// Route components
import AccountRoute from './js/routes/AccountRoute';
import AdminRoute from './js/routes/AdminRoute';
import ExternalRoute from './js/routes/ExternalRoute';
import MaintenanceRoute from './js/routes/MaintenanceRoute';
import ReAuthRoute from './js/routes/ReAuthRoute';
import SetPasswordRoute from './js/routes/SetPasswordRoute';
import SimpleRoute from './js/routes/SimpleRoute';
import ScrollToTop from './js/routes/ScrollToTop';

// Universal components
import {Home} from './js/components/Home/Home';

// Admin components
import Dashboard from './js/components/Dashboard/Dashboard';

// Admin sales components
import Orders from './js/components/Orders/Orders';
import OrdersBuylist from './js/components/Orders/OrdersBuylist';
import OrdersCarts from './js/components/Orders/OrdersCarts';
import OrdersOpen from './js/components/Orders/OrdersOpen';
import OrdersPreorder from './js/components/Orders/OrdersPreorder';
import OrdersPurchase from './js/components/Orders/OrdersPurchase';
import ViewCart from './js/components/Orders/ViewCart';
import ViewOrder from './js/components/Orders/ViewOrder';

// Admin inventory Pages
import Inventory from './js/components/Inventory/Inventory';
import AddProduct from './js/components/Inventory/AddProduct';
import ViewProduct from './js/components/Inventory/ViewProduct';
import AddProductLine from './js/components/Inventory/AddProductLine';
import ViewProductLine from './js/components/Inventory/ViewProductLine';
import BulkUploads from './js/components/Inventory/BulkUploads';
import BulkUploadCreate from './js/components/Inventory/BulkUploadCreate';
import BulkUploadView from './js/components/Inventory/BulkUploadView';
import ProductLines from './js/components/Inventory/ProductLines';
import Categories from './js/components/Inventory/Categories';
import AddCategory from './js/components/Inventory/AddCategory';
import ViewCategoryWrapper from './js/components/Inventory/ViewCategoryWrapper';

// Admin customer pages
import Customers from './js/components/Customer/Customers';
import AddCustomer from './js/components/Customer/AddCustomer';
import ViewCustomer from './js/components/Customer/ViewCustomer';
import CustomerBulkUploads from './js/components/Customer/CustomerBulkUploads';
import CustomerBulkUploadCreate from './js/components/Customer/CustomerBulkUploadCreate';
import CustomerBulkUploadView from './js/components/Customer/CustomerBulkUploadView';

// Admin events pages
import ManageEvents from './js/components/Events/ManageEvents';
import CreateEvent from './js/components/Events/CreateEvent';
import ViewEvent from './js/components/Events/ViewEvent';
import ViewEventSchedule from './js/components/Events/ViewEventSchedule';

// Admin marketing pages
import Marketing from './js/components/Marketing/Marketing';
import Coupons from './js/components/Marketing/Coupons';
import AddCoupon from './js/components/Marketing/AddCoupon';
import ViewCoupon from './js/components/Marketing/ViewCoupon';
import Notices from './js/components/Marketing/Notices';

// Admin settings pages
import Settings from './js/components/Settings/Settings';
import AdminUsers from './js/components/Settings/AdminUsers';
import AdminUsersAdd from './js/components/Settings/AdminUsersAdd';
import AdminUsersEdit from './js/components/Settings/AdminUsersEdit';
import Pages from './js/components/Settings/Pages';
import PageConfig from './js/components/Settings/PageConfig';
import PaymentMethods from './js/components/Settings/PaymentMethods';
import PaymentMethodOauthRedirect from './js/components/Settings/PaymentMethodOauthRedirect';
import AddPaymentMethod from './js/components/Settings/AddPaymentMethod';
import ViewPaymentMethod from './js/components/Settings/ViewPaymentMethod';
import StoreSettings from './js/components/Settings/StoreSettings';
import ShippingMethods from './js/components/Settings/ShippingMethods';
import AddShippingMethod from './js/components/Settings/AddShippingMethod';
import ViewShippingMethod from './js/components/Settings/ViewShippingMethod';
import Integrations from './js/components/Settings/Integrations';
import AddIntegration from './js/components/Settings/AddIntegration';
import ViewIntegration from './js/components/Settings/ViewIntegration';
import IntegrationConfigOauthStampsRedirect from './js/components/Settings/blocks/custom-configs/IntegrationConfigOauthStampsRedirect';

// Private components
import Account from './js/components/Account/Account';
import AccountOrders from './js/components/Account/AccountOrders';
import AccountOrderView from './js/components/Account/AccountOrderView';
import AccountStoreCredit from './js/components/Account/AccountStoreCredit';

// External components

// Auth pages
import ForgotPassword from './js/components/Auth/ForgotPassword';
import Login from './js/components/Auth/Login';
import ReAuth from './js/components/Auth/ReAuth';
import Register from './js/components/Auth/Register';
import ResetPassword from './js/components/Auth/ResetPassword';
import SetPassword from './js/components/Auth/SetPassword';

// Checkout pages
import Cart from './js/components/Cart/Cart';
import Checkout from './js/components/Checkout/Checkout';
import OrderComplete from './js/components/Checkout/OrderComplete';
import TransbankPaymentComplete from './js/components/Checkout/blocks/payment-methods/transbank/TransbankPaymentComplete';

// Event pages
import Events from './js/components/Events/Events';

// General pages
import ContactUs from './js/components/Contact/ContactUs';
import Privacy from './js/components/Legal/Privacy';
import ReturnPolicy from './js/components/Legal/ReturnPolicy';
import Terms from './js/components/Legal/Terms';

// Shop pages
import ProductPage from './js/components/ProductPage/ProductPage';
import ProductSearch from './js/components/ProductSearch/ProductSearch';

// Error components
import Error404 from './js/components/ErrorPage/Error404';
import Maintenance from './js/components/Maintenance/Maintenance';

import configureStore, { history } from './js/store';

import 'normalize.css';
import './style/_base.scss';

import './i18nextConf';

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="...">
      <ConnectedRouter history={history}>
        <ScrollToTop />
        <App>
          <Switch>

            <SimpleRoute exact path={u.URL_HOME} component={Home} />

            <AdminRoute exact path={u.URL_ADMIN_DASHBOARD} component={Dashboard} />

            <AdminRoute exact path={u.URL_ADMIN_ORDERS} component={Orders} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_BUYLIST} component={OrdersBuylist} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_CARTS} component={(props) => <OrdersCarts {...props} isBuylist={false} />} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_BUYLIST_CARTS} component={(props) => <OrdersCarts {...props} isBuylist={true} />} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_OPEN} component={OrdersOpen} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_PREORDER} component={OrdersPreorder} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_ORDERS_PURCHASE} component={OrdersPurchase} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_ORDERS_CARTS_VIEW, { publicUuid: ':cartUUID' })} component={(props) => <ViewCart {...props} isBuylist={false} />} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW, { publicUuid: ':cartUUID' })} component={(props) => <ViewCart {...props} isBuylist={true} />} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_ORDERS_ORDER_VIEW, { publicUuid: ':orderUUID' })} component={ViewOrder} requiredPermission={p.P_MANAGE_SALES} />

            <AdminRoute exact path={u.URL_ADMIN_INVENTORY} component={Inventory} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={u.URL_ADMIN_INVENTORY_ADD} component={AddProduct} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={u.URL_ADMIN_INVENTORY_BULK_UPLOAD} component={BulkUploads} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_INVENTORY_BULK_UPLOAD_CREATE} component={BulkUploadCreate} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_INVENTORY_BULK_UPLOAD_VIEW, { publicUuid: ':uploadUUID' })} component={BulkUploadView} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_INVENTORY_PRODUCT_LINES} component={ProductLines} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD} component={AddProductLine} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW, { permalink: ':productLinePermalink' })} component={ViewProductLine} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_INVENTORY_VIEW, { productLine: ':productLinePermalink', permalink: ':productPermalink' })} component={ViewProduct} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES} component={Categories} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_ADD} component={AddCategory} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_VIEW, { publicUuid: ':categoryUUID' })} component={ViewCategoryWrapper} requiredPermission={p.P_MANAGE_PRODUCTS} />

            <AdminRoute exact path={u.URL_ADMIN_CUSTOMERS} component={Customers} requiredPermission={p.P_MANAGE_USERS} />
            <AdminRoute exact path={u.URL_ADMIN_CUSTOMERS_ADD} component={AddCustomer} requiredPermission={p.P_MANAGE_USERS} />
            <AdminRoute path={u.URL_ADMIN_CUSTOMERS_BULK_UPLOAD} component={CustomerBulkUploads} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute exact path={u.URL_ADMIN_CUSTOMERS_BULK_UPLOAD_CREATE} component={CustomerBulkUploadCreate} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW, { publicUuid: ':uploadUUID' })} component={CustomerBulkUploadView} requiredPermission={p.P_MANAGE_PRODUCTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_CUSTOMERS_VIEW, { publicUuid: ':customerUUID' })} component={ViewCustomer} requiredPermission={p.P_MANAGE_USERS} />

            <AdminRoute exact path={u.URL_ADMIN_EVENTS} component={ManageEvents} requiredPermission={p.P_MANAGE_EVENTS} />
            <AdminRoute exact path={u.URL_ADMIN_EVENTS_CREATE} component={CreateEvent} requiredPermission={p.P_MANAGE_EVENTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_EVENTS_SCHEDULE_VIEW, { date: ':eventDate', publicUuid: ':scheduleUUID' })} component={ViewEventSchedule} requiredPermission={p.P_MANAGE_EVENTS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_EVENTS_VIEW, { publicUuid: ':eventUUID' })} component={ViewEvent} requiredPermission={p.P_MANAGE_EVENTS} />

            <AdminRoute exact path={u.URL_ADMIN_MARKETING} component={Marketing} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_MARKETING_COUPONS} component={Coupons} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_MARKETING_COUPONS_ADD} component={AddCoupon} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_MARKETING_COUPONS_VIEW, { code: ':couponCode' })} component={ViewCoupon} requiredPermission={p.P_MANAGE_SALES} />
            <AdminRoute exact path={u.URL_ADMIN_MARKETING_NOTICES} component={Notices} requiredPermission={p.P_MANAGE_SALES} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS} component={Settings} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_ADMIN_USERS} component={AdminUsers} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_ADMIN_USERS_ADD} component={AdminUsersAdd} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_ADMIN_USERS_EDIT, { public_uuid: ':customerUUID' })} component={AdminUsersEdit} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_PAGES} component={Pages} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_PAGES_VIEW, { key: ':pageKey' })} component={PageConfig} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_PAYMENT_METHODS} component={PaymentMethods} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_PAYMENT_METHODS_ADD} component={AddPaymentMethod} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={u.URL_ADMIN_SETTINGS_PAYMENT_METHODS_SQUARE_OAUTH} component={(props) => <PaymentMethodOauthRedirect {...props} paymentMethodKey={PM_VALUE_KEY_SQUARE_CC} />} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW, { public_uuid: ':paymentMethodUUID' })} component={ViewPaymentMethod} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_SHIPPING_METHODS} component={ShippingMethods} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_SHIPPING_METHODS_ADD} component={AddShippingMethod} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_SHIPPING_METHODS_VIEW, { publicUuid: ':shippingMethodUUID' })} component={ViewShippingMethod} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_INTEGRATIONS} component={Integrations} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute exact path={u.URL_ADMIN_SETTINGS_INTEGRATIONS_ADD} component={AddIntegration} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_INTEGRATIONS_OAUTH_REDIRECT, { key: ':providerKey' })} component={IntegrationConfigOauthStampsRedirect} requiredPermission={p.P_MANAGE_PERMISSIONS} />
            <AdminRoute path={stringFormat(u.URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW, { key: ':providerKey' })} component={ViewIntegration} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AdminRoute path={u.URL_ADMIN_SETTINGS_STORE_SETTINGS} component={StoreSettings} requiredPermission={p.P_MANAGE_PERMISSIONS} />

            <AccountRoute exact path={u.URL_ACCOUNT} component={Account} reAuth={true} />
            <AccountRoute exact path={u.URL_ACCOUNT_ORDERS} component={AccountOrders} reAuth={true} />
            <AccountRoute exact path={u.URL_ACCOUNT_ORDERS_BUYLIST} component={AccountOrders} reAuth={true} isBuylist={true} />
            <AccountRoute exact path={stringFormat(u.URL_ACCOUNT_ORDERS_SINGLE, { publicUuid: ':orderUUID' })} component={AccountOrderView} reAuth={true} />
            <AccountRoute exact path={stringFormat(u.URL_ACCOUNT_ORDERS_BUYLIST_SINGLE, { publicUuid: ':orderUUID' })} component={AccountOrderView} reAuth={true} isBuylist={true} />
            <AccountRoute exact path={u.URL_ACCOUNT_STORE_CREDIT} component={AccountStoreCredit} reAuth={true} />

            <ReAuthRoute path={u.URL_LOGIN_REAUTH} component={ReAuth} />
            <ExternalRoute path={u.URL_LOGIN} component={Login} />
            <ExternalRoute path={u.URL_REGISTER} component={Register} />
            <SimpleRoute path={u.URL_FORGOT_PASSWORD} component={ForgotPassword} />
            <SimpleRoute path={stringFormat(u.URL_RESET_PASSWORD, { token: ':forgotToken'})} component={ResetPassword} />
            <SetPasswordRoute path={u.URL_SET_PASSWORD} component={SetPassword} />

            <SimpleRoute exact path={u.URL_BUY_GENERAL} component={ProductSearch} />
            <SimpleRoute exact path={stringFormat(u.URL_BUY_PRODUCT_LINE, { productLine: ':productLinePermalink' })} component={ProductSearch} />
            <SimpleRoute path={stringFormat(u.URL_BUY_PRODUCT_PAGE, { productLine: ':productLinePermalink', permalink: ':productPermalink' })} component={ProductPage} />

            <SimpleRoute exact path={u.URL_SELL_GENERAL} component={(props) => <ProductSearch {...props} isBuylist={true} />} />
            <SimpleRoute exact path={stringFormat(u.URL_SELL_PRODUCT_LINE, { productLine: ':productLinePermalink' })} component={(props) => <ProductSearch {...props} isBuylist={true} />} />
            <SimpleRoute path={stringFormat(u.URL_SELL_PRODUCT_PAGE, { productLine: ':productLinePermalink', permalink: ':productPermalink' })} component={(props) => <ProductPage {...props} isBuylist={true} />} />

            <SimpleRoute exact path={u.URL_PLAY_GENERAL} component={Events} />
            <SimpleRoute path={stringFormat(u.URL_PLAY_PRODUCT_LINE, { productLine: ':productLinePermalink' })} component={Events} />

            <SimpleRoute path={u.URL_CONTACT_US} component={ContactUs} />
            <SimpleRoute path={u.URL_PRIVACY_POLICY} component={Privacy} />
            <SimpleRoute path={u.URL_RETURN_POLICY} component={ReturnPolicy} />
            <SimpleRoute path={u.URL_TERMS} component={Terms} />

            <SimpleRoute path={u.URL_CART} component={Cart} />
            <SimpleRoute path={u.URL_BUYLIST_CART} component={(props) => <Cart {...props} isBuylist={true} />} />
            <SimpleRoute path={u.URL_SUCCESS} component={OrderComplete} />

            <SimpleRoute path={stringFormat(u.URL_CHECKOUT_TRANSBANK_COMPLETE, { publicUuid: ':paymentRecordUUID' })} component={TransbankPaymentComplete} />
            <SimpleRoute path={u.URL_CHECKOUT} component={Checkout} reAuth={true} />
            <SimpleRoute path={u.URL_BUYLIST_CHECKOUT} component={(props) => <Checkout {...props} isBuylist={true} />} reAuth={true} />

            <MaintenanceRoute path={u.URL_MAINTENANCE_LOGIN} component={Login} checkLogin={true} />
            <MaintenanceRoute path={u.URL_MAINTENANCE} component={Maintenance} />

            <SimpleRoute component={Error404} />

          </Switch>
        </App>
      </ConnectedRouter>
    </Suspense>
  </Provider>,
  document.getElementById('titan')
)