import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { ICON_EMPTY } from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';
import { 
  TABLE_VALUE_MOBILE_BREAKPOINT, 
  TC_VIEW_KEY_LIST,
  TR_VAL_CALC_TYPE_PRICE_RANGE, 
  TR_VAL_CALC_TYPE_TOTAL_INVENTORY, 
  TR_VAL_PRIORITIES, 
  TR_VAL_TYPE_ADDRESS, 
  TR_VAL_TYPE_BINARY,  
  TR_VAL_TYPE_CHECKBOX,
  TR_VAL_TYPE_COMBINED_IDENTITY, 
  TR_VAL_TYPE_COMPOUND_GENERIC, 
  TR_VAL_TYPE_CURRENCY,
  TR_VAL_TYPE_DATE, 
  TR_VAL_TYPE_GENERIC_CALCULATED, 
  TR_VAL_TYPE_LINK, 
  TR_VAL_TYPE_NUMERIC, 
} from '../../../../constants/tables';

import { getStoreLanguage } from '../../../../utils/language';
import {
  getPriceRangeFromInventory, 
  getTotalQuantityFromInventory, 
} from '../../../../utils/product';
import { 
  getAlternateTableViewOptionComponent, 
  getBinaryDisplay, 
  getCalcType, 
  getCurrentTablePriority, 
  getDataValue, 
  getElementCustomAttr, 
  getElementType, 
  getElementPositionStyle, 
  getMobileAction,
  getTableAddLink, 
  getTableDetailViewSelectableWidth, 
  getTableNoun, 
  getTableSelectionKey,
  getTableViewByKey, 
  getValueArray, 
  isMultivaluedElement, 
  isTableDetailViewSelectable, 
  orderSchema, 
  shouldDisplayElement, 
  shouldUseBinaryIcon, 
} from '../../../../utils/table';

import AddressElement from './elements/AddressElement';
import BinaryElement from './elements/BinaryElement';
import CheckboxElement from './elements/CheckboxElement';
import CombinedIdentityElement from './elements/CombinedIdentityElement';
import CompoundGenericElement from './elements/CompoundGenericElement';
import DateElement from './elements/DateElement';
import GenericElement from './elements/GenericElement';
import LinkElement from './elements/LinkElement';
import NumericElement from './elements/NumericElement';
import SimpleCurrencyElement from './elements/SimpleCurrencyElement';

import { LoadingIcon } from '../../../Icons/LoadingIcon';
import { Icon } from '../../../Icons/Icon';

let allActionCreators = Object.assign({});

export class AdminTableBody extends Component {

  constructor(props) {
    super(props);

    this.state = {
      tableWidth: window.innerWidth * 0.7, // Initial assumption based on %age at 1200 width; state set again after mount
      tablePriority: TR_VAL_PRIORITIES[0], // Initial assumption of everything visible
    };

    // Create refs
    this.tableSection = React.createRef();

    // Bind event listeners
    this.updateWidthStateThrottled = _.throttle(this.updateWidthState.bind(this), 250);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidthStateThrottled, false);

    if(this.tableSection && this.tableSection.current) {
      this.setState({ 
        tableWidth: this.tableSection.current.clientWidth, 
        tablePriority: getCurrentTablePriority(this.props.schema, this.tableSection.current.clientWidth, window.innerWidth), 
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidthStateThrottled, false);
  }

  updateWidthState() {
    this.setState({ 
      tableWidth: this.tableSection.current.clientWidth, 
      tablePriority: getCurrentTablePriority(this.props.schema, this.tableSection.current.clientWidth, window.innerWidth), 
    });
  }

  getData() {
    return this.props.data ? this.props.data : [];
  }

  getNoun() {
    return this.props.t(getTableNoun(this.props.config));
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getCalcValue(invArray, calcType) {
    switch(calcType) {
      case TR_VAL_CALC_TYPE_PRICE_RANGE:
        return getPriceRangeFromInventory(invArray, { language: this.getLanguage() });
      case TR_VAL_CALC_TYPE_TOTAL_INVENTORY:
        return getTotalQuantityFromInventory(invArray, this.getLanguage());
      default:
        return '';      
    }
  }

  getElement(schemaElement, dataRow) {

    const dataValue = isMultivaluedElement(schemaElement) ? getValueArray(schemaElement, dataRow) : getDataValue(schemaElement, dataRow, this.props.t);
    const customAttrObj = getElementCustomAttr(schemaElement, dataRow);

    switch(getElementType(schemaElement)) {
      case TR_VAL_TYPE_ADDRESS:
        return <AddressElement 
                  dataValue={getDataValue(schemaElement, dataRow, this.props.t)} />;
      case TR_VAL_TYPE_BINARY:
        return <BinaryElement 
                  customAttr={customAttrObj}
                  useIcon={shouldUseBinaryIcon(dataValue, schemaElement)}
                  dataValue={getBinaryDisplay(dataValue, schemaElement)} />;
      case TR_VAL_TYPE_CHECKBOX:
        return <CheckboxElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  dataValue={dataValue}
                  selectedRows={this.props.selectedRows}
                  toggleSelectedRow={this.props.toggleSelectedRow} />;
      case TR_VAL_TYPE_COMBINED_IDENTITY:
        return <CombinedIdentityElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  firstName={dataValue[0] || ''}
                  lastName={dataValue[1] || ''}
                  email={dataValue[2] || ''} />;
      case TR_VAL_TYPE_COMPOUND_GENERIC:
        return <CompoundGenericElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  dataValue={dataValue[0] || ''}
                  secondaryDataValue={dataValue[1] || ''} />;
      case TR_VAL_TYPE_CURRENCY:
        return <SimpleCurrencyElement 
                  customAttr={customAttrObj}
                  dataValue={dataValue} />;
      case TR_VAL_TYPE_DATE:
        return <DateElement 
                  customAttr={customAttrObj}
                  dataValue={dataValue} />;
      case TR_VAL_TYPE_LINK:
        return <LinkElement 
                  customAttr={customAttrObj}
                  dataRow={dataRow}
                  schema={schemaElement}
                  linkFrom={this.props.linkFrom} />;
      case TR_VAL_TYPE_GENERIC_CALCULATED:
        return <GenericElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  dataValue={this.getCalcValue(dataValue, getCalcType(schemaElement))} />;
      case TR_VAL_TYPE_NUMERIC:
        return <NumericElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  dataValue={dataValue} />;
      default:
        return <GenericElement 
                  schema={schemaElement}
                  customAttr={customAttrObj}
                  dataValue={dataValue} />;
    }
  }

  shouldShowListView() {
    if(window.innerWidth <= TABLE_VALUE_MOBILE_BREAKPOINT) {
      return true;
    } else if(this.props.viewKey === TC_VIEW_KEY_LIST) {
      return true;
    }
    return false;
  }

  getAlternateRowView(dataRow, idx) {
    const RowComponent = getAlternateTableViewOptionComponent(getTableViewByKey(this.props.config, this.props.viewKey));

    if(!RowComponent) {
      return null;
    }

    return <RowComponent
              config={this.props.config}
              dataRow={dataRow}
              filters={this.props.filters}
              hotSwap={this.props.hotSwap}
              idx={idx}
              pageSize={this.props.pageSize}
              tabStart={100}
              refreshData={this.props.refreshData} />;
  }

  isRowSelected(line) {

    const selectionKey = getTableSelectionKey(this.props.config);

    for(const row of this.props.selectedRows) {
      if(row[selectionKey] === line[selectionKey]) {
        return true;
      }
    }
    return false;
  }

	render() {

    const {t} = this.props;

    return <div className={'AdminTableBody'} ref={this.tableSection}>
      <div className='adminTableBody'>
        {this.props.dataLoading ?
          <div className='atBodyWrapper'>
            <div className='atbLoadingWrapper'>
              <div className='atbLoadingIconWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </div> :
          <div>
            {this.getData().length === 0 ?
              <div className='atBodyWrapper'>
                <div className='atbNoResultsWrapper'>
                  <div className='atbNoResultsIconWrapper'>
                    <Icon 
                      value={ICON_EMPTY} />
                  </div>
                  <div className='atbNoResultsLabel'>{t(tx.TX_NO_RESULTS)}</div>
                  {getTableAddLink(this.props.config) ?
                    <div>
                      <Link className={'atbAddButton addButtonDesktop'} to={{ pathname: getTableAddLink(this.props.config), state: { from: this.props.linkFrom }}}>{t(tx.TX_ADD_NOUN, {noun: this.getNoun()})}</Link>
                      <Link className={'atbAddButton addButtonMobile'} to={{ pathname: getTableAddLink(this.props.config), state: { from: this.props.linkFrom }}}>{t(tx.TX_ADD)}</Link>
                    </div> :
                    null
                  }
                </div>
              </div> :
              <div className='atBodyWrapper'>
                {this.shouldShowListView() ?
                  <>
                    {this.getData().map((line, i) => {

                      return <div className={`atLine ${this.isRowSelected(line) ? 'selected' : ''}`} key={i}>
                        <div className='atLineWrapper'>
                          {orderSchema(this.props.schema).map((col, j) => {

                            if(shouldDisplayElement(col, this.state.tablePriority, window.innerWidth) === false) {
                              return null;
                            }

                            return <div 
                                      key={j}
                                      className='atbElement'
                                      style={getElementPositionStyle(col, this.props.schema, this.state.tablePriority, this.state.tableWidth, window.innerWidth)}>
                              <div className='atbElementWrapper'>
                                <div className='atbElementContentWrapper'>
                                  {this.getElement(col, line)}
                                </div>
                              </div>
                            </div>;
                          })}
                          {getMobileAction(this.props.schema, line) !== false && window.innerWidth <= TABLE_VALUE_MOBILE_BREAKPOINT ?
                            <Link to={{ pathname: getMobileAction(this.props.schema, line), state: { from: this.props.linkFrom }}} className='atbOverlayLink'></Link> :
                            null
                          }
                        </div>
                      </div>;
                    })}
                  </> :
                  <>
                    {this.getData().map((line, j) => {
                      return <div className={`atCustomLine ${this.isRowSelected(line) ? 'selected' : ''}`} key={`${j}`}>
                        {isTableDetailViewSelectable(this.props.config) ?
                          <div className='atActionWrapper' style={{ width: `${getTableDetailViewSelectableWidth(this.props.config)}px` }}>
                            <CheckboxElement 
                              schema={this.props.schema[0]}
                              customAttr={getElementCustomAttr(this.props.schema[0], line)}
                              dataValue={getDataValue(this.props.schema[0], line, t)}
                              selectedRows={this.props.selectedRows}
                              toggleSelectedRow={this.props.toggleSelectedRow} />
                          </div> :
                          null
                        }
                        <div 
                          className='atCustomLineLiner'
                          style={isTableDetailViewSelectable(this.props.config) ? {
                            marginLeft: `${getTableDetailViewSelectableWidth(this.props.config)}px`,
                          } : {}}>
                          {this.getAlternateRowView(line, j)}
                        </div>
                      </div>;
                    })}
                  </>
                }
              </div>
            }
          </div>
        }
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableBody));


