import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { GV_ELEMENT_ACTION_TYPE_SELECT_ONE } from '../../../../constants/gallery';

import {
  getGallerySelectLabel, 
  getGalleryActionType, 
} from '../../../../utils/gallery';

import WarhammerImage from '../../../Warhammer/WarhammerImage';

let allActionCreators = Object.assign({});

export class WarhammerThumbnail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      thumbnailLoaded: false, 
    };
  }

  thumbLoaded() {
    this.setState({ thumbnailLoaded: true });
  }

  handleClick(evt) {
    const actionType = getGalleryActionType(this.props.config);

    switch(actionType) {
      case GV_ELEMENT_ACTION_TYPE_SELECT_ONE:
        this.props.selectAction(this.props.modelObj);
        break;
      default:
        return null;
    }
  }

  getCardLanguageCode() {
    if(!this.props.modelObj || !this.props.modelObj.lang) {
      return '';
    }
    return this.props.modelObj.lang;
  }

  render() {

    const {t} = this.props;

    return <div 
            className={`WarhammerThumbnail TcgThumbnail ${this.state.thumbnailLoaded ? '' : 'unloaded'}`} 
            onClick={() => this.props.selectAction(this.props.modelObj)}>
      <div className='mtImageWrapper'>
        <WarhammerImage 
          warhammerObj={this.props.modelObj}
          loadDelay={this.props.loadDelay}
          onLoadLow={this.thumbLoaded.bind(this)} />
      </div>
      <div className='mtTitleSection'>
        <div className={'mtTitleWrapper'} lang={this.getCardLanguageCode()}>
          <div className='titlePrimary'>{this.props.modelObj.nameWithTags(t)}</div>
          <div className='titleSecondary' style={{ display: 'none' }}>{''}</div>
        </div>
        <div className={'mtSetWrapper EllipsisElement'}>
          {this.props.modelObj.set && this.props.modelObj.set.name ? this.props.modelObj.set.name : ''}
        </div>
      </div>
      <div className='mtActionWrapper'>
        <button type='button' className='mtActionButton' onClick={this.handleClick.bind(this)}>{t(getGallerySelectLabel(this.props.config))}</button>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(WarhammerThumbnail));