


export const ICON_ADD = 'ICON_ADD';
export const ICON_BUY = 'ICON_BUY';
export const ICON_BUYLIST_CART = 'ICON_BUYLIST_CART';
export const ICON_CALENDAR = 'ICON_CALENDAR';
export const ICON_CALENDAR_MONTHLY = 'ICON_CALENDAR_MONTHLY';
export const ICON_CALENDAR_WEEKLY = 'ICON_CALENDAR_WEEKLY';
export const ICON_CART = 'ICON_CART';
export const ICON_CATEGORY = 'ICON_CATEGORY';
export const ICON_CHECK = 'ICON_CHECK';
export const ICON_CHEVRON_DOWN = 'ICON_CHEVRON_DOWN';
export const ICON_CHEVRON_DOWN_DOUBLE = 'ICON_CHEVRON_DOWN_DOUBLE';
export const ICON_CHEVRON_LEFT_DOUBLE = 'ICON_CHEVRON_LEFT_DOUBLE';
export const ICON_CHEVRON_RIGHT_DOUBLE = 'ICON_CHEVRON_RIGHT_DOUBLE';
export const ICON_CLOSE = 'ICON_CLOSE';
export const ICON_COUPON = 'ICON_COUPON';
export const ICON_CREDIT_CARD = 'ICON_CREDIT_CARD';
export const ICON_CURRENCY = 'ICON_CURRENCY';
export const ICON_DETAIL = 'ICON_DETAIL';
export const ICON_DOWNLOAD = 'ICON_DOWNLOAD';
export const ICON_EDIT = 'ICON_EDIT';
export const ICON_EDIT_SIMPLE = 'ICON_EDIT_SIMPLE';
export const ICON_EMAIL = 'ICON_EMAIL';
export const ICON_EMPTY = 'ICON_EMPTY';
export const ICON_ERROR = 'ICON_ERROR';
export const ICON_FILE = 'ICON_FILE';
export const ICON_FILTER = 'ICON_FILTER';
export const ICON_FLIP = 'ICON_FLIP';
export const ICON_FORMAT = 'ICON_FORMAT';
export const ICON_GUEST = 'ICON_GUEST';
export const ICON_HASHTAG = 'ICON_HASHTAG';
export const ICON_HOME = 'ICON_HOME';
export const ICON_HOURS = 'ICON_HOURS';
export const ICON_INTEGRATION = 'ICON_INTEGRATION';
export const ICON_INVENTORY = 'ICON_INVENTORY';
export const ICON_LIST = 'ICON_LIST';
export const ICON_LOCATION = 'ICON_LOCATION';
export const ICON_MARKETING = 'ICON_MARKETING';
export const ICON_MENU = 'ICON_MENU';
export const ICON_MENU_DOTS = 'ICON_MENU_DOTS';
export const ICON_MESSAGE = 'ICON_MESSAGE';
export const ICON_NOTICE = 'ICON_NOTICE';
export const ICON_OPEN_EXTERNAL = 'ICON_OPEN_EXTERNAL';
export const ICON_PAGES = 'ICON_PAGES';
export const ICON_PAYMENT_METHOD = 'ICON_PAYMENT_METHOD';
export const ICON_PHONE = 'ICON_PHONE';
export const ICON_PICKUP = 'ICON_PICKUP';
export const ICON_PLAY = 'ICON_PLAY';
export const ICON_PRODUCT_LINE = 'ICON_PRODUCT_LINE';
export const ICON_REFRESH = 'ICON_REFRESH';
export const ICON_SEARCH = 'ICON_SEARCH';
export const ICON_SELL = 'ICON_SELL';
export const ICON_SETTINGS = 'ICON_SETTINGS';
export const ICON_SHIPPING = 'ICON_SHIPPING';
export const ICON_STORE_CREDIT = 'ICON_STORE_CREDIT';
export const ICON_TRASH = 'ICON_TRASH';
export const ICON_UPLOAD = 'ICON_UPLOAD';
export const ICON_USER = 'ICON_USER';
export const ICON_VIEW = 'ICON_VIEW';
