import React, { useState } from 'react';
import {
  closestCenter, 
  DndContext,
  DragOverlay, 
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove, 
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import { useTranslation } from 'react-i18next';

import {
  MEDIA_TYPE_IMAGE, 
  MEDIA_DATA_TYPE_FILE, 
  MEDIA_DATA_TYPE_OBJECT, 
} from '../../../constants/media';
import * as tx from '../../../constants/strings';

import { ProductSortableItem } from './ProductSortableItem';
import { ProductSortableItemWrapper } from './ProductSortableItemWrapper';

import '../_styles/_mediasortable.scss';

export function ProductMediaSortableGrid(props) {

  const [activeId, setActiveId] = useState(null);
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );
  const {t} = useTranslation();

  function getGridType() {
    if(props.fileArray) {
      return MEDIA_DATA_TYPE_FILE;
    }
    if(props.objArray) {
      return MEDIA_DATA_TYPE_OBJECT;
    }
  }

  function getMediaArray() {
    if(props.fileArray) {
      return props.fileArray;
    } else if(props.objArray) {
      return props.objArray;
    }
    return [];
  }

  function handleDragStart(event) {
    const {active} = event;
    setActiveId(active.id);
  }
  
  function handleDragEnd(event) {

    const {active, over} = event;
    const currentArray = getMediaArray();
    
    if(active && over && active.id !== over.id) {

      let oldIndex = -1;
      let newIndex = -1;
      for(let j = 0; j < currentArray.length; j++) {
        if(active.id === currentArray[j].id) {
          oldIndex = j;
        }
        if(over.id === currentArray[j].id) {
          newIndex = j;
        }
      }
      props.setMedia(arrayMove(currentArray, oldIndex, newIndex));
    } 
    setActiveId(null);
  }

  function removeObject(objId) {
    let newArray = [];
    const currentArray = getMediaArray();

    for(const md of currentArray) {
      if(md.id !== objId) {
        newArray.push(md);
      }
    }
    props.setMedia(newArray);
  }

  function getActiveObj() {
    for(const md of getMediaArray()) {
      if(md.id === activeId) {
        return md.file;
      }
    }
    return null;
  }
  
  return <div className='ProductMediaSortableGrid'>
    {getMediaArray().length === 0 ?
      <div className='noImagesWrapper'>{t(tx.TX_INV_ADD_PRODUCT_NO_IMAGES_YET)}</div> :
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}>
        <SortableContext 
          items={getMediaArray()}
          removable>
          {getMediaArray().map((imgItem, i) => {
            return <ProductSortableItem 
                    key={imgItem.id} 
                    orderIndex={i}
                    id={imgItem.id} 
                    isActive={imgItem.id === activeId} 
                    itemObj={getGridType() === MEDIA_DATA_TYPE_FILE ? imgItem.file : imgItem.obj}
                    itemType={MEDIA_TYPE_IMAGE}
                    objectType={getGridType()}
                    removeObj={removeObject} />;
          })}
        </SortableContext>
        <DragOverlay>
          {activeId ? 
            <ProductSortableItemWrapper 
              id={activeId} 
              itemObj={getActiveObj()}
              itemType={MEDIA_TYPE_IMAGE}
              objectType={getGridType()} /> : 
            null
          }
        </DragOverlay>
      </DndContext>
    }
  </div>;
}