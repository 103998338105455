



import { 
	API_FOREIGN_MODEL_POKEMON_CARD,
	API_FOREIGN_MODEL_POKEMON_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_POKEMON } from '../../constants/finishes';
import { 
	LANG_EN,
	LANG_PRODUCT_POKEMON, 
} from '../../constants/languages';
import { POKEMON_CARD_SKU_PREFIX } from '../../constants/syncs/pokemon';
import { 
	PRINTING_1ST_EDITION,
	PRINTING_UNLIMITED,
} from '../../constants/printings';
// import { 
// 	PROD_PAGE_LAYOUT_CLASS_TCG,
// 	PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG,
// 	PROD_SKU_MANAGED_DEMARKATION, 
// } from '../../constants/product';
import { 
	TX_POKEMON, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import PokemonImage from '../../components/Pokemon/PokemonImage';
import PokemonCardThumbnail from '../../components/Gallery/blocks/elements/PokemonCardThumbnail';
import ProductPageDetailsPokemon from '../../components/ProductPage/blocks/ProductPageDetailsPokemon';



export class PokemonSet extends ProductSet {

	constructor(props = {}) {

		super(props);
		
		this.id = props.id || null;
		this.name = props.name || this.name || props.set_name || '';		
		this.ptcgoCode = props.ptcgoCode || props.ptcgo_code || '';
		this.extId = props.extId || props.ext_id || this.code || '';
		this.hasFirstEdition = !!props.hasFirstEdition || !!props.has_first_edition || false;

		this.syncLookupKey();
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_POKEMON_SET;
	}

	get lookupKey() {
		return this.extId;
	}

	set lookupKey(val) {
		this.extId = val;
	}

	syncLookupKey() {
		if(this.code) {
			this.lookupKey = this.code;
		}
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.extId;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class PokemonCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.pokemonId = props.pokemonId || props.pokemon_id || null;

		this.name = props.name || '';
		this.nationalPokedexNumbers = props.nationalPokedexNumbers || props.national_pokedex_numbers || '';
		this.supertype = props.supertype || '';
		this.evolvesFrom = props.evolvesFrom || props.evolves_from || '';
		this.hp = props.hp || '';
		this.convertedRetreatCost = parseInt(props.convertedRetreatCost) || parseInt(props.converted_retreat_cost) || 0;
		this.number = props.number || '';
		this.artist = props.artist || '';
		this.rarity = props.rarity || '';
		this.series = props.series || '';
		this.text = props.text || '';
		this.level = props.level || '';
		this.flavorText = props.flavorText || props.flavor_text || '';
		this.languageCode = props.languageCode || props.lang || LANG_EN;
		

		const subtypeArray = props.subtypes || [];
		const typesArray = props.types || [];
		const retreatCostArray = props.retreatCost || props.retreat_cost || [];
		const legalitiesArray = props.legalities || [];
		const abilitiesArray = props.abilities || [];
		const attacksArray = props.attacks || [];
		const weaknessesArray = props.weaknesses || [];
		const resistancesArray = props.resistances || [];
		const evolvesToArray = props.evolvesTo || props.evolves_to || [];
		const rulesArray = props.rules || [];

		this.subtypes = [];
		for(const st of subtypeArray) {
			this.subtypes.push(new PokemonCardSubtype(st));
		}

		this.types = [];
		for(const ty of typesArray) {
			this.types.push(new PokemonCardType(ty));
		}

		this.retreatCost = [];
		for(const rc of retreatCostArray) {
			this.retreatCost.push(new PokemonCardType(rc));
		}

		this.legalities = [];
		for(const lg of legalitiesArray) {
			this.legalities.push(new PokemonLegality(lg));
		}

		this.abilities = [];
		for(const ab of abilitiesArray) {
			this.abilities.push(new PokemonCardAbility(ab));
		}

		this.attacks = [];
		for(const at of attacksArray) {
			this.attacks.push(new PokemonCardAttack(at));
		}

		this.weaknesses = [];
		for(const wk of weaknessesArray) {
			this.weaknesses.push(new PokemonCardWeakness(wk));
		}

		this.resistances = [];
		for(const rs of resistancesArray) {
			this.resistances.push(new PokemonCardResistance(rs));
		}

		this.evolvesTo = [];
		for(const et of evolvesToArray) {
			this.evolvesTo.push(new PokemonCardEvolvesTo(et));
		}

		this.rules = [];
		for(const ru of rulesArray) {
			this.rules.push(new PokemonCardRule(ru));
		}

		const ancientTraitObj = props.ancientTrait || props.ancient_trait || null;
		const pokemonSetObj = props.pokemonSet || props.pokemon_set || null;

		this.pokemonSet = pokemonSetObj ? new PokemonSet(pokemonSetObj) : null;
		this.ancientTrait = ancientTraitObj ? new PokemonCardAncientTrait(ancientTraitObj) : null;
		this.images = new PokemonCardImage(props.images || {});

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isPokemonCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_POKEMON_CARD;
	}

	get languageObj() {
		for(const lang of LANG_PRODUCT_POKEMON) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return LANG_PRODUCT_POKEMON[0];
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get setName() {
		return this.pokemonSet && this.pokemonSet.name ? this.pokemonSet.name : '';
	}

	get collectorNumber() {
		return this.number;
	}

	get exportInventoryDetails() {
		return true;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		return FINISHES_POKEMON;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	hasPrinting() {
		return true;
	}

	allPrintings() {
		const cardPrintings = [ PRINTING_UNLIMITED ];
		if(this.pokemonSet && this.pokemonSet.hasFirstEdition) {
			cardPrintings.push(PRINTING_1ST_EDITION);
		}
		return cardPrintings;
	}

	primaryImageSrc(isBack = false) {
		return this.images && this.images.large ? this.images.large : PokemonCardImage.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.images && this.images.small ? this.images.small : PokemonCardImage.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <PokemonImage 
		          pokemonObj={this}
		          loadDelay={0}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <PokemonImage 
		          pokemonObj={this}
		          loadDelay={0}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.localizedName;

		if(!cardName) {
			return t ? t(TX_POKEMON) : '';
		}

		const respArray = [ cardName ];
		if(this.setName) {
			respArray.push(this.setName);
		}
		if(t) {
			respArray.push(t(TX_POKEMON));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return PokemonCardThumbnail;
	}

	get componentProductPageDetails() {
		return ProductPageDetailsPokemon;
	}

	get productPageLayoutClass() {
		const { PROD_PAGE_LAYOUT_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,			
		});
	}

	get productSku() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');

		const idBase = '000000000';
		const idString = this.id.toString();
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${POKEMON_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {
		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class PokemonSealed {

	constructor(props = {}) {
		this.id = props.id || null;
	}
}


export class PokemonCardAncientTrait {

	constructor(props = {}) {
		this.name = props.name || '';	
		this.text = props.text || '';		
	}
}


export class PokemonCardSubtype {

	constructor(props = {}) {
		this.name = props.name || '';		
	}
}


export class PokemonCardType {

	constructor(props = {}) {
		this.name = props.name || '';		
	}
}


export class PokemonCardImage {

	constructor(props = {}) {
		this.small = props.small || '';
		this.large = props.large || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/pokemon/cardback.png';
	}

	static defaultThumbnailSrc() {
		return '/img/product/pokemon/cardback.png';
	}
}


export class PokemonCardAbility {

	constructor(props = {}) {
		this.name = props.name || '';
		this.text = props.text || '';
		this.type = props.type || '';		
	}
}


export class PokemonCardAttack {

	constructor(props = {}) {

		this.cost = [];
		this.name = props.name || '';	
		this.text = props.text || '';	
		this.damage = props.damage || '';
		this.convertedEnergyCost = parseInt(props.convertedEnergyCost) || parseInt(props.converted_energy_cost) || 0;

		const costArray = props.cost || [];
		for(const ty of costArray) {
			this.cost.push(new PokemonCardType(ty));
		}
	}
}


export class PokemonCardWeakness {

	constructor(props = {}) {
		this.type = new PokemonCardType(props.type || {});
		this.value = props.value || '';
	}
}


export class PokemonCardResistance {

	constructor(props = {}) {
		this.type = new PokemonCardType(props.type || {});
		this.value = props.value || '';
	}
}


export class PokemonCardEvolvesTo {

	constructor(props = {}) {
		this.name = props.name || '';		
	}
}


export class PokemonCardRule {

	constructor(props = {}) {
		this.rule = props.rule || '';		
	}
}


export class PokemonLegality {

	constructor(props = {}) {
		this.name = props.name || '';
		this.legal = props.legal || false;		
	}
}






































