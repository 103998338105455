import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductTagEntry from './ProductTagEntry';

import '../../_styles/_tagmanager.scss';

export const ProductTagManager = (props) => {
  
  // Props
  const {
    makeClean, 
    product, 
    productPending, 
    setRequestError, 
  } = props;


  // State
  const [adding, setAdding] = useState(false);


  // Internationalization
  const { t } = useTranslation();


  // Effects
  // None yet


  // Methods

  const getTags = () => {
    if(product) {
      return product.tags || [];
    }
    return [];
  };

  const tagSaved = () => {
    if(makeClean) {
      makeClean();
    }
  };

  const openAdd = () => {
    setAdding(true);
  };

  const closeAdd = () => {
    setAdding(false);
  };

  return (
    <div className='ProductTagManager'>
      <div className='tagManagerWrapper'>
        {!product && productPending ? 
          <div className='loadingWrapper'>
            <div className='loadingIconWrapper'>
              <LoadingIcon />
            </div>
          </div> :
          <div className='tagListWrapper'>
            {getTags().length > 0 ?
              <div className='tagList'>
                {getTags().map((tag, i) => {
                  return <ProductTagEntry 
                            key={tag.publicUuid} 
                            completeSave={tagSaved}
                            product={product}
                            productPending={productPending}
                            productTag={tag}
                            setRequestError={setRequestError} />
                })}
              </div> :
              <>
                {!adding ?
                  <div className='tagListNoResults'>
                    <div className='tagListNoResultsCopy'>{t(tx.TX_INV_TAGS_NO_TAGS)}</div>
                  </div> :
                  null
                }
              </>
            }
            <div className='addWrapper'>
              {adding ?
                <div className='addOpen'>
                  <ProductTagEntry 
                    closeMethod={closeAdd}
                    completeSave={tagSaved}
                    product={product}
                    productPending={productPending}
                    productTag={null}
                    setRequestError={setRequestError} />
                </div> :
                <div className='addClosed'>
                  <div className='addLink' onClick={openAdd}>{t(tx.TX_INV_TAGS_ADD_TAG)}</div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ProductTagManager;

