

import { enUS, es, ro, zhCN } from 'date-fns/locale';

import {
	TX_LANG_ANCIENT_GREEK, 
	TX_LANG_ARABIC, 
	TX_LANG_CHINESE,
	TX_LANG_CHINESE_SIMPLIFIED, 
	TX_LANG_CHINESE_TRADITIONAL, 
	TX_LANG_ENGLISH, 
	TX_LANG_FRENCH, 
	TX_LANG_GERMAN, 
	TX_LANG_HEBREW, 
	TX_LANG_ITALIAN, 
	TX_LANG_JAPANESE, 
	TX_LANG_KOREAN, 
	TX_LANG_LATIN, 
	TX_LANG_PHYREXIAN, 
	TX_LANG_PORTUGUESE, 
	TX_LANG_ROMANIAN, 
	TX_LANG_RUSSIAN, 
	TX_LANG_SANSKRIT, 
	TX_LANG_SPANISH, 
} from './strings';

export const LANG_DEFAULT_CODE = process.env.REACT_APP_DEFAULT_LANGUAGE;

// Translated languages
export const LANG_EN = 'en';
export const LANG_ES = 'es';
export const LANG_RO = 'ro';
export const LANG_ZH = 'zh';

// Other languages
export const LANG_AR = 'ar';
export const LANG_DE = 'de';
export const LANG_FR = 'fr';
export const LANG_GRC = 'grc';
export const LANG_HE = 'he';
export const LANG_IT = 'it';
export const LANG_JA = 'ja';
export const LANG_KO = 'ko';
export const LANG_LA = 'la';
export const LANG_PH = 'ph';
export const LANG_PT = 'pt';
export const LANG_RU = 'ru';
export const LANG_SA = 'sa';
export const LANG_ZHS = 'zh-CN';
export const LANG_ZHT = 'zh-CHT';

// Language Objects

export const EN_OBJ = {
	code: LANG_EN,
	locale: enUS,
	name: 'English',
	nameDefault: 'English',
	nameTranslation: TX_LANG_ENGLISH,
}

export const ES_OBJ = {
	code: LANG_ES,
	locale: es,
	name: 'Español',
	nameDefault: 'Spanish',
	nameTranslation: TX_LANG_SPANISH,
}

export const RO_OBJ = {
	code: LANG_RO,
	locale: ro,
	name: 'Română',
	nameDefault: 'Romanian',
	nameTranslation: TX_LANG_ROMANIAN,
}

export const ZH_OBJ = {
	code: LANG_ZH,
	locale: zhCN,
	name: '中文',
	nameDefault: 'Chinese',
	nameTranslation: TX_LANG_CHINESE,
}

const FR_OBJ = {
	code: LANG_FR,
	name: 'Français',
	nameDefault: 'French',
	nameTranslation: TX_LANG_FRENCH,
}

const DE_OBJ = {
	code: LANG_DE,
	name: 'Deutsch',
	nameDefault: 'German',
	nameTranslation: TX_LANG_GERMAN,
}

const IT_OBJ = {
	code: LANG_IT,
	name: 'Italiano',
	nameDefault: 'Italian',
	nameTranslation: TX_LANG_ITALIAN,
}

const PT_OBJ = {
	code: LANG_PT,
	name: 'Português',
	nameDefault: 'Portuguese',
	nameTranslation: TX_LANG_PORTUGUESE,
}

const JA_OBJ = {
	code: LANG_JA,
	name: '日本語',
	nameDefault: 'Japanese',
	nameTranslation: TX_LANG_JAPANESE,
}

const KO_OBJ = {
	code: LANG_KO,
	name: '한국어',
	nameDefault: 'Korean',
	nameTranslation: TX_LANG_KOREAN,
}

const RU_OBJ = {
	code: LANG_RU,
	name: 'русский',
	nameDefault: 'Russian',
	nameTranslation: TX_LANG_RUSSIAN,
}

const ZHS_OBJ = {
	parent: ZH_OBJ,
	code: LANG_ZHS,
	scryfallCode: 'zhs',
	name: '中文',
	nameDefault: 'Chinese (Simplified)',
	nameTranslation: TX_LANG_CHINESE_SIMPLIFIED,
}

const ZHT_OBJ = {
	parent: ZH_OBJ,
	code: LANG_ZHT,
	scryfallCode: 'zht',
	name: '中文',
	nameDefault: 'Chinese (Traditional)',
	nameTranslation: TX_LANG_CHINESE_TRADITIONAL,
}

const HE_OBJ = {
	code: LANG_HE,
	name: 'עברית',
	nameDefault: 'Hebrew',
	nameTranslation: TX_LANG_HEBREW,
	rightToLeft: true,
}

const LA_OBJ = {
	code: LANG_LA,
	name: 'latine',
	nameDefault: 'Latin',
	nameTranslation: TX_LANG_LATIN,
}

const GRC_OBJ = {
	code: LANG_GRC,
	name: 'ελληνικά',
	nameDefault: 'Ancient Greek',
	nameTranslation: TX_LANG_ANCIENT_GREEK,
}

const AR_OBJ = {
	code: LANG_AR,
	name: 'العربية',
	nameDefault: 'Arabic',
	nameTranslation: TX_LANG_ARABIC,
}

const SA_OBJ = {
	code: LANG_SA,
	name: 'संस्कृतम्',
	nameDefault: 'Sanskrit',
	nameTranslation: TX_LANG_SANSKRIT,
}

const PH_OBJ = {
	code: LANG_PH,
	name: 'Phyrexian',
	nameDefault: 'Phyrexian',
	nameTranslation: TX_LANG_PHYREXIAN,
}


// Funcational Arrays
export const LANG_ALL = [ 
	EN_OBJ, 
	ES_OBJ, 
	RO_OBJ, 
	ZH_OBJ, 
	FR_OBJ, 
	JA_OBJ, 
	IT_OBJ, 
	DE_OBJ, 
	PT_OBJ, 
	ZHS_OBJ, 
	ZHT_OBJ, 
	RU_OBJ, 
	KO_OBJ, 
	PH_OBJ, 
	HE_OBJ, 
	LA_OBJ, 
	GRC_OBJ, 
	AR_OBJ, 
	SA_OBJ, 
];

export const LANG_PRODUCT_LOCALIZATIONS = [ 
	EN_OBJ, 
	ES_OBJ, 
	RO_OBJ, 
	ZH_OBJ, 
];

export const LANG_PRODUCT_ALL = [ 
	EN_OBJ, 
	ES_OBJ, 
	RO_OBJ, 
	ZH_OBJ, 
	FR_OBJ, 
];

export const LANG_PRODUCT_GENERIC = [ 
	EN_OBJ, 
	ES_OBJ, 
	RO_OBJ, 
	ZH_OBJ, 
	FR_OBJ, 
];

export const LANG_PRODUCT_MAGIC = [
	EN_OBJ, 
	JA_OBJ, 
	FR_OBJ, 
	IT_OBJ, 
	DE_OBJ, 
	ES_OBJ, 
	PT_OBJ, 
	ZHS_OBJ, 
	ZHT_OBJ, 
	RU_OBJ, 
	KO_OBJ, 
	PH_OBJ, 
	HE_OBJ, 
	LA_OBJ, 
	GRC_OBJ, 
	AR_OBJ, 
	SA_OBJ, 
];

export const LANG_PRODUCT_POKEMON = [
	EN_OBJ, 
];

export const LANG_PRODUCT_LORCANA = [
	EN_OBJ,
	DE_OBJ,
	FR_OBJ,
];

export const LANG_PRODUCT_STARWARS = [
	EN_OBJ,
];

export const LANG_PRODUCT_ONEPIECE = [
	EN_OBJ,
];

export const LANG_PRODUCT_YUGIOH = [
	EN_OBJ,
];

export const LANG_PRODUCT_WARHAMMER = [
	EN_OBJ,
];














