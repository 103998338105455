import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TX_DONE,
  TX_OVERVIEW_CATEGORIES_SUMMARY_COPY_0,
  TX_OVERVIEW_CATEGORIES_SUMMARY_COPY_1,
  TX_OVERVIEW_CATEGORIES_SUMMARY_TITLE,
} from '../../../../constants/strings';

import ActionButton from '../../../Buttons/ActionButton';

import overviewStyles from '../../../Admin/_styles/AdminOverview.module.scss';
import styles from './_styles/OverviewCategories.module.scss';

export const OverviewCategories = (props) => {
  
  // Props
  const {
    closeMethod,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  // None yet

  // Render
  return (
    <div className={`${styles.OverviewCategories} ${overviewStyles.AdminOverview}`}>
      <div className={overviewStyles.sectionHeader}>{t(TX_OVERVIEW_CATEGORIES_SUMMARY_TITLE)}</div>
      <div className={overviewStyles.sectionCopy}>{t(TX_OVERVIEW_CATEGORIES_SUMMARY_COPY_0)}</div>
      <div className={overviewStyles.sectionCopy}>{t(TX_OVERVIEW_CATEGORIES_SUMMARY_COPY_1)}</div>
      <div className={overviewStyles.actionWrapper}>
        <ActionButton 
          adminTheme={true}
          type='button'
          size='small'
          onClick={closeMethod}>
          {t(TX_DONE)}
        </ActionButton>
      </div>
    </div>
  );
};

export default OverviewCategories;




