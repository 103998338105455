import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { history } from '../../../store';

import {
  PL_PERMALINK_LORCANA,
  PL_PERMALINK_MAGIC,
  PL_PERMALINK_ONEPIECE,
  PL_PERMALINK_POKEMON,
  PL_PERMALINK_STARWARS,
  PL_PERMALINK_WARHAMMER,
  PL_PERMALINK_YUGIOH,
} from '../../../constants/product';
import * as tx from '../../../constants/strings';
import { URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD } from '../../../constants/urls';

import { getOrderedProductLines } from '../../../utils/product';

import AddProductDetailsGeneric from './AddProductDetailsGeneric';
import AddProductDetailsSyncedSearch from './AddProductDetailsSyncedSearch';
import { LoadingIcon } from '../../Icons/LoadingIcon';

import * as productLineActionCreators from '../../../actions/product-line';
let allActionCreators = Object.assign({}, productLineActionCreators);

export class AddProductStep1 extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputProductLine: this.props.productObj ? this.props.productObj.productLine : '',
      errorProductLine: '',
    }
  }

  componentDidMount() {

    // Product line data
    if(this.props.productLine && this.props.productLine.productLines === null) {
      
      this.props.productLinesFetchAll()
      .then((resp) => {
        this.props.productLinesSetAll(resp);
      })
      .catch((errResp) => {
        if(errResp) {
          console.error(errResp);
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.checkPosThrottled, false);
    window.removeEventListener('resize', this.checkPosThrottled, false);
  }

  leaveWithoutSave() {
    this.unblock();
    history.push(this.state.lastBlock);
  }

  changeProductLine(evt) {

    if(!this.props.productLine.productLines) {
      return;
    }

    for(const pl of this.props.productLine.productLines) {

      if(evt.target.value === '') {
        this.setState({ inputProductLine: evt.target.value });
        this.props.setProduct(null);
        break;
      }

      if(pl.id === parseInt(evt.target.value)) {
        this.setState({ inputProductLine: pl });
        break;
      }
    }
  }

  getSelectNoticeStyle() {
    try {
      if(this.props.productLine.productLinesPending === false && this.props.productLine.productLines.length > 0 && this.state.inputProductLine === '') {
        return {
          display: 'block',
        }
      }
    } catch(err) {
      return {};  
    }
    return {};
  }

  getEmptyNoticeStyle() {
    try {
      if(this.props.productLine.productLinesPending === false && this.props.productLine.productLines.length === 0) {
        return {
          display: 'block',
        }
      }
    } catch(err) {
      return {};  
    }
    return {};
  }

  getProductForm() {
    
    if(!this.state.inputProductLine) {
      return null;
    }

    if(this.state.inputProductLine.isManaged === false || this.props.genericOverride) {
      return <AddProductDetailsGeneric
               productLine={this.state.inputProductLine} 
               makeDirty={this.props.makeDirty.bind(this)}
               moveNext={this.props.moveNext}
               productObj={this.props.productObj}
               setProduct={this.props.setProduct}
               genericOverride={this.props.genericOverride}
               toggleGenericOverride={this.props.toggleGenericOverride}
               validationSignal={this.props.validationSignal} />;
    }

    const SYNCED_PRODUCT_LINES = [
      PL_PERMALINK_LORCANA,
      PL_PERMALINK_MAGIC,
      PL_PERMALINK_ONEPIECE,
      PL_PERMALINK_POKEMON,
      PL_PERMALINK_STARWARS,
      PL_PERMALINK_WARHAMMER,
      PL_PERMALINK_YUGIOH,
    ];

    if(SYNCED_PRODUCT_LINES.includes(this.state.inputProductLine.permalink)) {
      return <AddProductDetailsSyncedSearch
               productLine={this.state.inputProductLine} 
               makeDirty={this.props.makeDirty.bind(this)}
               moveNext={this.props.moveNext}
               productObj={this.props.productObj}
               setProduct={this.props.setProduct}
               setInventory={this.props.setInventory}
               genericOverride={this.props.genericOverride}
               toggleGenericOverride={this.props.toggleGenericOverride}
               validationSignal={this.props.validationSignal} />
    }

    return null;
  }

  render() {

    const {t} = this.props;
    const orderedProductLines = getOrderedProductLines(this.props.productLine.productLines);

    return <div className={'AddProductStep1'}>
      <div className='adminForm'>
        <div className='adminFormTitle'>
          <div className='afTitleWrapper'>{t(tx.TX_INV_ADD_PRODUCT_STEP_1_TITLE)}</div>
          {this.state.inputProductLine.isManaged && this.props.genericOverride ?
            <div 
              className='afTitleAction'
              onClick={this.props.toggleGenericOverride}>
              {t(tx.TX_INV_ADD_PRODUCT_BACK_TO_SEARCH)}
            </div> :
            null
          }
        </div>
        <div className={this.props.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.props.requestError)}</div>
        <div className='adminFieldWrapper'>
          <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_PRODUCT_LINE)}</div>
          <div className='adminInputWrapper'>
            <div className='adminInputProductLineWrapper'>
              {this.props.productLine.productLinesPending ?
                <div className='aiProductLineLoading'>
                  <div className='aiProductLineLoadingIcon'>
                    <LoadingIcon />
                  </div>
                  <div className='aiProductLoadingLabel'>{t(tx.TX_LOADING)}</div>
                </div> :
                <div>
                  {orderedProductLines.length === 0 ?
                    <div className='aiProductLineEmpty'>
                      <select 
                        value={''}
                        required={false}
                        disabled={true}>
                        <option className='placeholderOption' value=''>{t(tx.TX_INV_ADD_NO_PL)}</option>
                      </select>
                      <div className='aiDropdownArrowWrapper'>
                        <div className='aiArrow'></div>
                      </div>
                    </div> :
                    <div className='aiProductLineDropdown'>
                      <select 
                        value={this.state.inputProductLine ? this.state.inputProductLine.id : ''}
                        onChange={this.changeProductLine.bind(this)}
                        required={true}>
                        <option className='placeholderOption' value=''>{t(tx.TX_PLACEHOLDER_PL_DROPDOWN)}</option>
                        {orderedProductLines.map((pl, i) => {
                          return <option key={i} value={pl.id}>{pl.name}</option>;
                        })}
                      </select>
                      <div className='aiDropdownArrowWrapper'>
                        <div className='aiArrow'></div>
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
          </div>
        </div>
        <div className='aiFormExtender'>
          {this.getProductForm()}
        </div>
        <div className='adminFormPending' style={{display: this.props.requestPending ? 'block' : 'none'}}>
          <div className='adminFormPendingScreen'></div>
          <div className='adminFormPendingWrapper'>
            <LoadingIcon />
          </div>
        </div>
      </div>
      <div className='aiNotices'>
        <div className={'aiNotice aiNoticeSelect'} style={this.getSelectNoticeStyle()}>{t(tx.TX_INV_ADD_SELECT_PL)}</div>
        <Link className={'aiNotice aiNoticeEmpty'} style={this.getEmptyNoticeStyle()} to={URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD}>
          <div className='aiNotice' style={this.getEmptyNoticeStyle()}>{t(tx.TX_INV_ADD_NO_PL_NOTICE)}</div>
          <button className='aiNoticeButton'>{t(tx.TX_ADD_NOUN, {noun: t(tx.TX_PRODUCT_LINE)})}</button>
        </Link>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AddProductStep1));