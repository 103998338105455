import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { getElementTranslate } from '../../../../../utils/table';

let allActionCreators = Object.assign({});

export class CompoundGenericElement extends Component {

  render() {  

    const {t} = this.props;

    return <div className={'CompoundGenericElement'} {...this.props.customAttr}>
      <div className='genericElementWrapper'>{getElementTranslate(this.props.schema) ? t(this.props.dataValue) : this.props.dataValue}</div>
      {this.props.secondaryDataValue ?
        <div className='subElementWrapper'>{this.props.secondaryDataValue}</div> :
        null
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CompoundGenericElement));


