
import { 
	API_FOREIGN_MODEL_WARHAMMER,
} from '../../constants/api';
import { 
	LANG_PRODUCT_WARHAMMER, 
} from '../../constants/languages';
import { WARHAMMER_SKU_PREFIX } from '../../constants/syncs/warhammer';
import { 
	TX_WARHAMMER, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
} from '../products';

import { getCurrencyMinorCount } from '../../utils/currency';
import { dynamicSort } from '../../utils/sort';

import WarhammerImage from '../../components/Warhammer/WarhammerImage';
import WarhammerThumbnail from '../../components/Gallery/blocks/elements/WarhammerThumbnail';
// import ProductPageDetailsStarWars from '../../components/ProductPage/blocks/ProductPageDetailsStarWars';


export class WarhammerProduct extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.name = props.name || '';
		this.description = props.description || '';
		this.languageCode = props.languageCode || props.lang || null;

		this.system = props.system || '';
		this.race = props.race || '';
		this.shortCode = props.shortCode || props.short_code || '';
		this.productId = props.productId || props.product_id || null;
		this.barcode = props.barcode || '';
		this.module = props.module || '';
		this.packQuantity = parseInt(props.packQuantity) || parseInt(props.pack_quantity) || 1;

		this.retailUsd = parseFloat(props.retailUsd) || parseFloat(props.retail_usd / getCurrencyMinorCount()) || 0;
		this.tradeUsd = parseFloat(props.tradeUsd) || parseFloat(props.trade_usd / getCurrencyMinorCount()) || 0;

		const mediaArray = props.media || [];
		this.media = [];
		for(const md of [ ...mediaArray ].sort(dynamicSort('sequence'))) {
			this.media.push(new WarhammerMedia(md));
		}
				
		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isWarhammerProduct() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_WARHAMMER;
	}

	get languageObj() {
		if(!this.languageCode) { return null; }
		for(const lang of LANG_PRODUCT_WARHAMMER) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return null;
	}

	get exportInventoryDetails() {
		return false;
	}

	get useProductImages() {
		return true;
	}

	allConditions() {
		return [];
	}

	hasFinish() {
		return false;
	}

	allFinishes() {
		return [];
	}

	get allowSealed() {
		return true;
	}

	get alwaysDisplayLanguage() {
		return false;
	}

	primaryImageSrc(isBack = false) {
		return this.media && this.media.length ? this.media[0].primarySrc : WarhammerMedia.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.media && this.media.length ? this.media[0].thumbnailSrc : WarhammerMedia.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <WarhammerImage 
		          warhammerObj={this}
		          loadDelay={0}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <WarhammerImage 
		          warhammerObj={this}
		          loadDelay={0}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.localizedName;
		const setName = this.set && this.set.name ? this.set.name : '';

		if(!cardName) {
			return t ? t(TX_WARHAMMER) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_WARHAMMER));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return WarhammerThumbnail;
	}

	// get componentProductPageDetails() {
	// 	return ProductPageDetailsStarWars;
	// }

// 	get productPageLayoutClass() {
// 		const { PROD_PAGE_LAYOUT_CLASS_TCG } = require('../../constants/product');
// 		return PROD_PAGE_LAYOUT_CLASS_TCG;
// 	}

// 	get productPageDetailLayoutClass() {
// 		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG } = require('../../constants/product');
// 		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
// 	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,
		});
	}

	get productSku() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');

		const idBase = '000000000';
		const idString = this.id ? this.id.toString() : '';
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${WARHAMMER_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');
		
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class WarhammerMedia {

	constructor(props) {

		if(!props) { props = {}; }

		this.mediaType = props.mediaType || props.media_type || '';
		this.mediaKey = props.mediaKey || props.media_key || '';
		this.mediaUrl = props.mediaUrl || props.media_url || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	static defaultPrimarySrc() {
		return '/img/product/noimage.jpg';
	}

	static defaultThumbnailSrc() {
		return '/img/product/noimage.jpg';
	}

	get primarySrc() {
		return this.mediaUrl;
	}

	get thumbnailSrc() {
		return this.mediaUrl;
	}
}


























