import React, { createContext, useCallback, useContext, useState } from 'react';

const SaveContext = createContext();

export const useSaveContext = () => useContext(SaveContext);

const SaveProvider = ({ children }) => {
  
  // State
  const [isSaving, setIsSaving] = useState(false);
  const [saveCallbacks, setSaveCallbacks] = useState([]);

  const triggerSave = async () => {
    setIsSaving(true);
    await Promise.all(saveCallbacks.map(callback => callback()));
    setIsSaving(false);
  };

  const registerSaveCallback = useCallback((callback) => {
    setSaveCallbacks((prev) => [...prev, callback]);
    return () => {
      // Unregister function to remove the callback
      setSaveCallbacks((prev) => prev.filter((cb) => cb !== callback));
    };
  }, []);

  return (
    <SaveContext.Provider value={{ 
      isSaving, 
      triggerSave, 
      registerSaveCallback, 
    }}>
      {children}
    </SaveContext.Provider>
  );
};

export default SaveProvider;