import { useCallback, useState } from 'react';

import {
  ERROR_PRICE_GENERAL, 
  ERROR_PRICE_MAX,
  ERROR_PRICE_MIN,
  ERROR_PRICE_MISSING,
  ERROR_PRICE_NUMERIC,
} from '../../constants/errors';

import { getCurrencyMinorCount } from '../../utils/currency';
import { 
  isJsEvent,
  safeParseFloat, 
} from '../../utils/general';
import { normalizePrice } from '../../utils/formatting';

const useFormValidationPrice = (initialValue) => {
  
  const [value, setValue] = useState(initialValue || '');
  const [error, setError] = useState('');

  const validate = useCallback((test = null, config = {}) => {
  
    try {

      let respError = '';
      let testVal = test;

      if(isJsEvent(testVal)) {
        testVal = test.target.value;
      }

      // Configs
      const allowEmpty = config.allowEmpty || false;
      const shouldNormalize = config.normalize || false;
      const showError = !(config.hideError || false);

      if(value === null) {
        testVal = value;
      }

      if(allowEmpty && testVal === '') {
        return '';
      }

      if(testVal === null || testVal === '' || !testVal) {
        respError = ERROR_PRICE_MISSING;
      } else if(testVal.trim && testVal.trim().length === 0) {
        respError = ERROR_PRICE_MISSING;
      } 

      try {
        testVal = safeParseFloat(test);
      } catch(numericErr) {
        respError = respError || ERROR_PRICE_NUMERIC;
      }

      if(!respError) {

        const globalMin = 0;
        const globalMax = 1000000000*getCurrencyMinorCount();  // 1B minor currency units

        const dynamicMin = parseFloat(config.min) || globalMin;
        const dynamicMax = parseFloat(config.max) || globalMax;

        if(testVal < dynamicMin) {
          respError = ERROR_PRICE_MIN;
        } else if(testVal > dynamicMax) {
          respError = ERROR_PRICE_MAX;
        }
      }

      if(showError || !respError) {
        setError(respError);
      }

      if(shouldNormalize) {
        setValue(normalizePrice(value));
      }

      return respError;

    } catch(err) {
      console.error(err);
      setError(ERROR_PRICE_GENERAL);
      return ERROR_PRICE_GENERAL;
    }
  }, [value, setError]);

  const isValid = (test = null, config = {}) => {
    return validate(test, config) === '';
  };

  const handleChange = (evt, config = {}) => {
    setValue(evt.target.value);
    if(error) {
      validate(evt.target.value, Object.assign({}, config, { hideError: true, normalize: false }));
    }
  };

  const changeValue = (val, config = {}) => {
    setValue(val);
    if(error) {
      validate(val, Object.assign({}, config, { hideError: true, normalize: false }));
    }
  };

  return {
    value,
    error,
    validate,
    isValid,
    onChange: handleChange,
    setValue: changeValue,
  };
};

export default useFormValidationPrice;
