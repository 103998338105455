import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  API_KEY_LIMIT,
  API_KEY_OFFSET,
  API_KEY_SORT,
} from '../../constants/api';
import {
  TX_CATALOG,
  TX_CATEGORIES,
} from '../../constants/strings';
import { 
  TABLE_CONFIG_PRODUCT_CATEGORIES, 
  TABLE_SCHEMA_PRODUCT_CATEGORIES, 
  TC_PAGINATION_CONFIGS,
  TC_DEFAULT_PAGE_SIZE,
} from '../../constants/tables';
import { URL_ADMIN_INVENTORY } from '../../constants/urls';

import { productCategoriesFetchAll } from '../../actions/product';

import { formatServerError } from '../../utils/formatting';
import { 
  getPageLimit, 
  getPageOffset, 
} from '../../utils/request';

import AdminTable from '../Admin/AdminTable';
import AdminTitle from '../Admin/AdminTitle';
import OverviewCategories from './blocks/overview-content/OverviewCategories';

import styles from './_styles/Categories.module.scss';

export const Categories = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // State
  const [data, setData] = useState(null);
  const [dataCount, setDataCount] = useState(null);
  const [dataError, setDataError] = useState(null);
  const [dataLoading, setDataLoading] = useState(false);

  // Refs
  const controllerRef = useRef(null);

  // Methods
  const fetchCategories = useCallback(async (pageNum = 1, pageSize = TABLE_CONFIG_PRODUCT_CATEGORIES[TC_PAGINATION_CONFIGS][TC_DEFAULT_PAGE_SIZE], sort = null, filters = {}, silentLoading = false) => {
  
    setDataError(null);
    setDataLoading(true);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const controlParams = {
      [API_KEY_LIMIT]: getPageLimit(pageNum, pageSize),
      [API_KEY_OFFSET]: getPageOffset(pageNum, pageSize),
    };

    if(sort) {
      controlParams[API_KEY_SORT] = sort;
    }

    const getParams = Object.assign({}, filters, controlParams);
  
    // Fetch data
    const resp = await dispatch(productCategoriesFetchAll(getParams, controller.signal))
      .catch((errResp) => {
        
        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setDataError(formatServerError(errResp));
        setDataLoading(false);
      });

    if(!resp) {
      return null;
    }

    setData(resp.data);
    setDataCount(resp.count);
    setDataLoading(false);

  }, [ dispatch ]);

  // Effects
  useEffect(() => {
    if(data === null) {
      fetchCategories({});
    }
  }, [ data, fetchCategories ]);

  // Render
  return (
    <div className={`${styles.Categories} AdminPage`}>
      <AdminTitle
        title={TX_CATEGORIES}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: TX_CATALOG,
          },
        ]}
        overview={OverviewCategories} />
      <div className='adminBody'>
        {dataError && <div className='dataError'>{dataError}</div>}
        <AdminTable 
          config={TABLE_CONFIG_PRODUCT_CATEGORIES}
          schema={TABLE_SCHEMA_PRODUCT_CATEGORIES}
          data={data}
          dataCount={dataCount} 
          dataLoading={dataLoading}
          callApi={fetchCategories} />
      </div>
    </div>
  );
};

export default Categories;




