import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TX_ADD_NOUN, 
  TX_CATEGORY, 
  TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING,
  TX_NO_RESULTS,
} from '../../../../constants/strings';
import { URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_ADD } from '../../../../constants/urls';

import ActionButton from '../../../Buttons/ActionButton';

import styles from './_styles/ProductCategoryAutocompleteNullResults.module.scss';

const ProductCategoryAutocompleteNullResults = (props) => {

  // Props
  const {
    lastQuery,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  // None yet

  return (
    <div className={styles.ProductCategoryAutocompleteNullResults}>
      <div className={styles.nullLiner}>
        {lastQuery ? (
          <>
            <div className={styles.nullCopyLine}>
              <div className={styles.noResultsCopy}>{t(TX_INV_ADD_PRODUCT_NO_RESULTS_MATCHING)}</div>
              <div className={styles.noResultsNeedle}>{lastQuery}</div>
            </div>
            <div className={styles.nullActionWrapper}>
              <ActionButton
                adminTheme={true}
                linkTo={URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_ADD}>
                {t(TX_ADD_NOUN, { noun: t(TX_CATEGORY) })}
              </ActionButton>
            </div>
          </>
        ) : (
          <div className={styles.noResultsCopy}>{t(TX_NO_RESULTS)}</div>
        )}
      </div>
    </div>
  );
};
export default ProductCategoryAutocompleteNullResults;




