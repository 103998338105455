import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import {
  ICON_LIST,
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';
import {
  TC_HEADER_SORT_DIR_ASC,
  TC_HEADER_SORT_DIR_DESC,
  TC_VIEW_KEY_LIST,  
} from '../../../../constants/tables';

import { getStoreLanguage } from '../../../../utils/language';
import {
  getAlternateTableViewOptionIcon,
  getAlternateTableViewOptionKey,
  getAlternateTableViewOptionName,
  getAlternateTableViewOptionSort,
  getAlternateTableViewOptionSortOptions,
  getAlternateTableViewOptions,
  getBulkActionsObject,
  getBulkActionsOptions,
  getTableViewByKey,
  hasBulkActions,
  hasMultipleViews,
} from '../../../../utils/table';

import AdminTableBulkActionModal from './AdminTableBulkActionModal';
import { Icon } from '../../../Icons/Icon';

let allActionCreators = Object.assign({});

export class AdminTableResults extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bulkActionModalOpen: false,
      bulkActionObj: null,
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getResultsValue() {
    const t = this.props.t;
    try {
      if(this.props.dataLoading) {
        return t(tx.TX_ADMIN_TABLE_SEARCHING);
      } else if(this.props.resultsCount === 0) {
        return t(tx.TX_GALLERY_NO_RESULTS);
      } else if(this.props.resultsCount === 1) {
        return t(tx.TX_RESULTS_ONE);
      } else if(this.props.resultsCount > 0) {
        return t(tx.TX_RESULTS_NUM, { num: this.props.resultsCount.toLocaleString(this.getLanguage()) });
      }
    } catch(err) {
      console.error(err);
    }
    return '';
  }

  changeSortKey(evt) {

    let sortDir = this.props.sortDir;

    if(this.props.sortKey !== evt.target.value) {
      
      // Should be most "alphabetical", names, product line names, customer names, etc
      const defaultAscKeys = [
        'name',
        'product_line__name',
      ];

      if(defaultAscKeys.includes(evt.target.value)) {
        sortDir = TC_HEADER_SORT_DIR_ASC;
      }
    }
    this.props.changeSort(evt.target.value, sortDir);
  }

  changeSortDirection() {
    this.props.changeSort(this.props.sortKey, this.props.sortDir === TC_HEADER_SORT_DIR_ASC ? TC_HEADER_SORT_DIR_DESC : TC_HEADER_SORT_DIR_ASC);
  }

  triggerBulkActionModal(evt) {
    const bulkAction = getBulkActionsObject(evt.target.value, this.props.config);

    this.setState({
      bulkActionModalOpen: true,
      bulkActionObj: bulkAction,
    })
  }

  closeBulkActionModal() {
    this.setState({ 
      bulkActionModalOpen: false,
    }, () => {
      if(this.props.refreshSelections) {
        this.props.refreshSelections(); 
      }
    });
  }

  clearModalData() {
    this.setState({ bulkActionObj: null });
  }

  clearFilterKeys(filterElement) {
    if(!filterElement) { return null; }
    try {
      this.props.removeFilters(filterElement.apiKeys);
    } catch(err) {
      console.error(err);
    }
  }

  getFilterComponent(filterElement) {
    if(!filterElement || !filterElement.component) { return null; }
    try {
      const FilterComponent = filterElement.component;

      if(!FilterComponent) {
        return null;
      }

      return <FilterComponent
                config={this.props.config}
                clearFilter={() => this.clearFilterKeys(filterElement)}
                filters={this.props.filters}
                filterElement={filterElement}
                removeFilters={this.props.removeFilters.bind(this)}
                setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)}
                setFilters={this.props.setFilters.bind(this)} />;
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  render() {

		const {t} = this.props;
    const currentViewObj = getTableViewByKey(this.props.config, this.props.viewKey);
	
    return <div className={'AdminTableResults'}>
      <div className='atrLiner'>
        <div className='atrResultsValue'>
          {hasBulkActions(this.props.config) ?
            <div className='viewActionsWrapper'>
              <div className='viewActionsDropdownWrapper'>
                <select 
                  value={''} 
                  disabled={this.props.dataLoading}
                  onChange={this.triggerBulkActionModal.bind(this)}>
                  {getBulkActionsOptions(this.props.config).map((actionObj, i) => {
                    return <option key={i} value={actionObj.value}>{t(actionObj.display)}</option>;
                  })}
                </select>
              </div>
              <div className='viewSortVr'></div>
            </div> :
            null
          }
          <div className='atrResultsCount'>{this.getResultsValue()}</div>
        </div>
        {hasMultipleViews(this.props.config) ?
          <div className='atrViewWrapper'>
            {getAlternateTableViewOptionSort(currentViewObj) ?
              <div className='viewSortWrapper'>
                <div className='viewSortLabel'>{t(tx.TX_GALLERY_SORTED_BY)}</div>
                <div className='viewSortDropdownWrapper'>
                  <select 
                    value={this.props.sortKey} 
                    onChange={this.changeSortKey.bind(this)}>
                    {getAlternateTableViewOptionSortOptions(currentViewObj, this.props.schema).map((sortObj, i) => {
                      return <option key={i} value={sortObj.value}>{t(sortObj.display)}</option>;
                    })}
                  </select>
                </div>
                <div className={'viewSortVr light'}></div>
                <div className='viewSortArrowWrapper' onClick={this.changeSortDirection.bind(this)}>
                  <div className={this.props.sortKey ? 'sortArrow' : 'sortArrow disabled'}>
                    <div className={this.props.sortDir === TC_HEADER_SORT_DIR_ASC && this.props.sortKey !== '' ? 'arrowWrapper upArrow' : 'arrowWrapper'}></div>
                  </div>
                </div>
                <div className='viewSortVr'></div>
              </div> :
              null  
            }
            <div className='viewLabelWrapper'>{t(getAlternateTableViewOptionName(currentViewObj))}</div>
            {getAlternateTableViewOptions(this.props.config).map((opt, i) => {
              return <div className='viewIconWrapper' key={i} onClick={() => this.props.setViewKey(getAlternateTableViewOptionKey(opt))}>
                <Icon 
                  iconClass={`viewIcon${this.props.viewKey === getAlternateTableViewOptionKey(opt) ? ' selected' : ''}`}
                  value={getAlternateTableViewOptionIcon(opt)}  />
              </div>
            })}
            <div className='viewIconWrapper' onClick={() => this.props.setViewKey(TC_VIEW_KEY_LIST)}>
              <Icon 
                iconClass={`viewIcon${this.props.viewKey === TC_VIEW_KEY_LIST ? ' selected' : ''}`}
                value={ICON_LIST} />
            </div>
          </div> :
          null
        }
      </div>
      <AdminTableBulkActionModal
        open={this.state.bulkActionModalOpen}
        closeMethod={this.closeBulkActionModal.bind(this)}
        clearModalData={this.clearModalData.bind(this)}
        bulkActionObj={this.state.bulkActionObj}
        selectedRows={this.props.selectedRows}
        filters={this.props.filters}
        staticFilters={this.props.staticFilters}
        sortDir={this.props.sortDir}
        sortKey={this.props.sortKey}
        resultsCount={this.props.resultsCount}
        refreshData={this.props.refreshData} />
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableResults));


