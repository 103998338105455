import { useCallback, useEffect, useState } from 'react';

import {
  ERROR_CATCH_ALL,
  ERROR_PRODUCT_SET_INVALID,
  ERROR_PRODUCT_SET_MISSING,
} from '../../constants/errors';

import { ProductSet } from '../../models/products';

import { isJsEvent } from '../../utils/general';

const useFormValidationProductSet = (initialValue) => {
  
  const [value, setValue] = useState(initialValue);
  const [error, setError] = useState('');

  const validate = useCallback((test = null, config = {}) => {
  
    try {

      let respError = '';
      let testVal = test;

      if(isJsEvent(testVal)) {
        testVal = test.target.value;
      }

      // Configs
      const allowEmpty = config.allowEmpty || false;
      const showError = !(config.hideError || false);

      if(test === null) {
        testVal = value;
      }

      if(allowEmpty && testVal === '') {
        return '';
      }

      if(!testVal) {
        respError = ERROR_PRODUCT_SET_MISSING;
      } else if(!testVal instanceof ProductSet) {
        respError = ERROR_PRODUCT_SET_INVALID;
      }

      if(showError) {
        setError(respError);
      }
      return respError;

    } catch(err) {
      console.error(err);
      setError(ERROR_CATCH_ALL);
      return ERROR_CATCH_ALL;
    }
  }, [setError, value]);

  const handleChange = (val) => {
    setValue(val);
  };

  // Only validate onChange if there is already an error
  useEffect(() => {
    if(error) {
      validate(value);
    }
  }, [error, validate, value]);

  const changeValue = (val) => {
    setValue(val);
  };

  return {
    value,
    error,
    validate,
    onChange: handleChange,
    setValue: changeValue,
  };
};

export default useFormValidationProductSet;
