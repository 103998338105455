import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { 
  TX_CANCEL, 
  TX_SAVE, 
} from '../../constants/strings';

import { useFormContext } from '../../context/FormProvider';

import ActionButton from '../Buttons/ActionButton';
import LoadingIcon from '../Icons/LoadingIcon';
import SavePrompt from '../Popups/SavePrompt';
import TextButton from '../Buttons/TextButton';

import styles from './_styles/AdminForm.module.scss';

export const AdminForm = (props) => {
  
  // Context
  const { 
    formState, 
    isFormValid, 
    isFormDirty, 
  } = useFormContext();

  // History
  const history = useHistory();

  // Props
  const {
    blockDirtyNav,
    cancelLabel = TX_CANCEL,
    cancelUrl,
    children,
    error,
    hideActions = false,
    inlay = false,
    onCancel,
    onSubmit,
    requestPending,
    submitLabel = TX_SAVE,
    title,
  } = props;

  // State
  const [allowNav, setAllowNav] = useState(false);
  const [lastBlock, setLastBlock] = useState(null);
  const [saveOpen, setSaveOpen] = useState(false);
  const [saveRedirect, setSaveRedirect] = useState(false);

  // Refs
  const unblockRef = React.useRef(null);

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const handleSubmit = async (evt) => {

    if(evt) { evt.preventDefault(); }

    if(onSubmit && isFormValid()) { 

      setAllowNav(true);

      const success = await onSubmit(formState, { saveRedirect: saveRedirect });

      if(success) {
        if(saveRedirect && lastBlock?.location) {
          history.push(lastBlock.location.pathname + lastBlock.location.search);
        } else if(cancelUrl) {
          history.push(cancelUrl);
        } else {
          history.goBack();
        }
      } else {
        setAllowNav(false);
      }
    }
  };

  const togglePrompt = useCallback(() => {
    setSaveOpen(!saveOpen);
  }, [ saveOpen ]);

  const saveAndRedirect = (evt) => {
    setSaveRedirect(true);
    handleSubmit(evt);
  };

  const leaveWithoutSave = () => {

    // Unblock nav
    unblockRef.current();

    // Navigate
    if(lastBlock?.location) {
      history.push(lastBlock.location.pathname + lastBlock.location.search);
    } else {
      history.goBack();
    }
  };

  // Effects
  useEffect(() => {

    // Block navigation
    unblockRef.current = history.block((location, action) => {
      
      if(!blockDirtyNav || allowNav) {
        return true;
      }

      if(!isFormDirty()) {
        return true;
      }

      setLastBlock({ location, action });
      togglePrompt();
      return false;
    });

    return () => {
      // Clean up on component unmount
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [allowNav, blockDirtyNav, history, isFormDirty, togglePrompt]);

  // Render
  return (
    <div className={`${styles.AdminForm} ${inlay ? styles.inlaid : ''}`}>
      <div className={styles.formLiner}>
        <form onSubmit={handleSubmit}>

          {title ?
            <div className={styles.titleWrapper}>
              <div className={styles.titleValue}>{t(title)}</div>
            </div> :
            null
          }
            
          <div className={`${styles.formError} ${error ? styles.errorPresent : ''}`}>{error ? t(error) : ''}</div>
     
          <div className={styles.formBody}>
            <div className={styles.formInput}>
              {children}
            </div>
          </div>
          
          {!hideActions ?
            <div className={styles.formActions}>
              
              {onCancel || cancelUrl ?
                <TextButton 
                  className={styles.actionRight} 
                  onClick={onCancel}
                  linkTo={cancelUrl}>
                  {t(cancelLabel)}
                </TextButton> :
                null
              }
                
              <ActionButton 
                adminTheme={true}
                className={styles.actionRight}
                type='submit'
                disabled={requestPending}>
                {t(submitLabel)}
              </ActionButton>

            </div> :
            null
          }

          <div className={`${styles.formPending} ${requestPending ? styles.pendingActive : ''}`}>
            <div className={styles.pendingScreen}></div>
            <div className={styles.pendingWrapper}>
              <LoadingIcon />
            </div>
          </div>
          <SavePrompt
            open={saveOpen}
            closeMethod={togglePrompt}
            onConfirm={saveAndRedirect}
            onCancel={leaveWithoutSave} />
        </form>
      </div>
    </div>
  );
};

export default AdminForm;




