import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../constants/images';
import { LANG_EN } from '../../../constants/languages';
import * as tx from '../../../constants/strings';

import { getStoreLanguage } from '../../../utils/language';
import { 
  getMediaAlt,
  getOrderedMedia, 
  getPriceRangeFromInventory, 
} from '../../../utils/product';

import StaticImage from '../../Image/StaticImage';

import styles from '../style/BlockElementVerticalProduct.module.scss';

const allActionCreators = Object.assign({});

export class BlockElementVerticalProduct extends Component {

  getItemImage(product) {
    if(!product || !product.inventory) { return null; }

    if(product.foreignModel && !product.foreignModel.useProductImages) {
      return product.foreignModel.getPrimaryImage({ noFlip: true });
    }

    const productMedia = getOrderedMedia(product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: productMedia[0].src, 
                  alt: getMediaAlt(product, productMedia[0]), 
                  noTranslate: true, 
                }} />
    }
    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  getUserLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getPriceString(product) {
    if(!product) { return null; }

    const priceConfig = {
      addTags: true, 
      available: true,
      language: this.getUserLanguage(),
    };

    if(this.props.isBuylist) {
      priceConfig['buylist'] = true;
      priceConfig['available'] = false;
    }

    return getPriceRangeFromInventory(product.inventory, priceConfig);
  }

  render() {

    if(!this.props.product) { return null; }

    const {t} = this.props;

    return <div className={styles.BlockElementVerticalProduct}>
      <Link 
        className={styles.productWrapper}
        to={{
          pathname: this.props.product.getProductPageUrl(this.props.isBuylist),
          state: { allowBack: true },
        }}>
        <div className={styles.productLiner}>
          <div className={styles.productImgWrapper}>
            <div className={styles.productImg}>{this.getItemImage(this.props.product)}</div>
          </div>
          <div className={styles.productTitle} lang={this.props.product.lang ? this.props.product.lang : LANG_EN}>{this.props.product.name}</div>
          <div className={styles.productLineWrapper}>{this.props.product.productLine.name}</div>
          {this.props.product.productSet ?
            <div className={styles.productTitleSupplement}>{this.props.product.productSet.name}</div> :
            null
          }
          <div className={styles.productTagWrapper}>
            {!this.props.product.isReleased ?
              <div className={styles.productTag}>
                <div className={styles.productTagValue}>{t(tx.TX_PREORDER)}</div>
              </div> :
              null
            }
            {this.props.isBuylist ?
              <div className={styles.productTag}>
                <div className={styles.productTagValue}>{t(tx.TX_BUYLIST)}</div>
              </div> :
              null
            }
          </div>
          <div className={styles.priceWrapper} dangerouslySetInnerHTML={{ __html: this.getPriceString(this.props.product) }} />
        </div>
      </Link>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(BlockElementVerticalProduct));