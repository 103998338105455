

import { CD_NM } from './conditions';
import { GEO_COUNTRY_CL } from './geographies';
import {
	EN_OBJ,
	ES_OBJ,
} from './languages';
import { 
	TX_BALANCE, 
	TX_INTEGRATION_CONFIG_LABEL_BALANCE_REFILL, 
	TX_INTEGRATION_CONFIG_LABEL_PARCEL_SIZES,
	TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_ENABLED,
	TX_MARKUP, 
	TX_null, 
} from './strings';
import { URL_ADMIN_SETTINGS_INTEGRATIONS_OAUTH_REDIRECT } from './urls';

import { getMarkupPercentError } from '../utils/form-validation';

import HeaderCenterLogo from '../components/Header/HeaderCenterLogo';

import IntegrationConfigParcelSize from '../components/Settings/blocks/custom-configs/IntegrationConfigParcelSize';
import ReturnPolicyOasisChile from '../components/Legal/blocks/oasis-chile/ReturnPolicyOasisChile';
import ShippingMethodChilexpress from '../components/Settings/blocks/integration-components/chilexpress/ShippingMethodChilexpress';
import CreateShipmentStamps from '../components/Settings/blocks/integration-components/stamps/CreateShipmentStamps';

import IntegrationConfigOauthStampsAuthorize from '../components/Settings/blocks/custom-configs/IntegrationConfigOauthStampsAuthorize';
import IntegrationConfigOauthStampsRedirect from '../components/Settings/blocks/custom-configs/IntegrationConfigOauthStampsRedirect';
import IntegrationConfigStampsBalanceRefill from '../components/Settings/blocks/custom-configs/IntegrationConfigStampsBalanceRefill';
import IntegrationDisplayStampsParcelGuardModal from '../components/Settings/blocks/custom-display/IntegrationDisplayStampsParcelGuardModal';
import IntegrationDisplayStampsTestPrint from '../components/Settings/blocks/custom-display/IntegrationDisplayStampsTestPrint';


export const STORE_CODE_DEFAULT = 'default';
export const STORE_CODE_FLYINGBEAR = 'flyingbear';
export const STORE_CODE_OASIS = 'oasis';
export const STORE_CODE_OASISCHILE = 'oasischile';
export const STORE_CODE_PIXELATOMS = 'pixelatoms';


export const STORE_NEGATIVE_STYLE_PARENTHESIS = 'parenthesis';
export const STORE_NEGATIVE_STYLE_SIGNED = 'signed';


// ENV configs

export const STORE_CODE = process.env.REACT_APP_STORE_CODE;
export const STORE_LOGO_URL = `/store/${process.env.REACT_APP_STORE_CODE}/logo/logo.png`;
export const STORE_LOGO_PRINT_URL = `/store/${process.env.REACT_APP_STORE_CODE}/logo/logo-print.png`;
export const STORE_NAME = process.env.REACT_APP_WEBSITE_NAME || '';
export const STORE_NEGATIVE_STYLE = process.env.REACT_APP_NEGATIVE_STYLE || STORE_NEGATIVE_STYLE_PARENTHESIS;
export const STORE_MAINTENANCE_MODE = process.env.REACT_APP_STORE_MAINTENANCE_MODE === 'true' || process.env.REACT_APP_STORE_MAINTENANCE_MODE === true || false;
export const STORE_MEDIA_ORIGIN = process.env.REACT_APP_BUILD_MEDIA_ORIGIN;

// Google Analytics
const gaTrackingIds = [];
if(process.env.REACT_APP_STORE_GA_TRACKING_ID) {
	gaTrackingIds.push(process.env.REACT_APP_STORE_GA_TRACKING_ID);
}
if(process.env.REACT_APP_TALARIA_GA_TRACKING_ID) {
	gaTrackingIds.push(process.env.REACT_APP_TALARIA_GA_TRACKING_ID);
}
export const STORE_GA_TRACKING_IDS = gaTrackingIds;

// Google Tag Manager
const gtmIds = [];
if(process.env.REACT_APP_STORE_GTM_ID) {
	gtmIds.push(process.env.REACT_APP_STORE_GTM_ID);
}
if(process.env.REACT_APP_TALARIA_GTM_ID) {
	gtmIds.push(process.env.REACT_APP_TALARIA_GTM_ID);
}
export const STORE_GTM_IDS = gtmIds;


// This should be thought of as a to-do list; everthing here should be migrated to backend or exceptions handled in a more scalable way

export const STORE_RETURN_POLICIES = {
	[STORE_CODE_OASISCHILE]: ReturnPolicyOasisChile,
}

export const STORE_LANGUAGE_OPTIONS = {
	[STORE_CODE_OASIS]: [
		EN_OBJ,
		ES_OBJ,
	],
	[STORE_CODE_OASISCHILE]: [
		ES_OBJ,
		EN_OBJ,
	],
};

export const STORE_BUYLIST_CONFIGS = {
	[STORE_CODE_OASIS]: {
		language: [ 
			EN_OBJ,
		],
		condition: [
			CD_NM,
		],
	},
	[STORE_CODE_FLYINGBEAR]: {
		language: [ 
			EN_OBJ,
		],
		condition: [
			CD_NM,
		],
	},
};

export const STORE_WEBSITE_CONFIGS = {
	[STORE_CODE_PIXELATOMS]: {
		header: HeaderCenterLogo,
	},
};

// End store config to dos


export const STORE_INTEGRATION_TYPE_COMMON_KEY = 'common';
export const STORE_INTEGRATION_TYPE_SHIPPING_KEY = 'shipping';

export const STORE_INTEGRATION_CHILEXPRESS_KEY = 'chilexpress';
export const STORE_INTEGRATION_STAMPS_KEY = 'stamps';

export const STORE_INTEGRATION_CONFIG_TYPE_BOOL = 'bool';
export const STORE_INTEGRATION_CONFIG_TYPE_TEXT = 'text';

export const STORE_INTEGRATION_CONFIG_KEY_CHILEXPRESS_PARCELS = 'parcels';
export const STORE_INTEGRATION_CONFIG_KEY_STAMPS_BALANCE_REFILL = 'balance_refill';
export const STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED = 'parcelguard_enabled';

export const STORE_INTEGRATION_DISPLAY_KEY_STAMPS_BALANCE = 'balance';
export const STORE_INTEGRATION_DISPLAY_KEY_STAMPS_TEST_PRINT = 'test_print';
export const STORE_INTEGRATION_DISPLAY_KEY_STAMPS_PARCELGUARD_MODAL = 'parcelguard_modal';

export const STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP = 'markup';


export const STORE_INTEGRATION_CHILEXPRESS = {
	key: STORE_INTEGRATION_CHILEXPRESS_KEY,
	name: 'Chilexpress',
	countriesAllowed: [ GEO_COUNTRY_CL ],
	countriesExcluded: [],
	config: {
		[STORE_INTEGRATION_TYPE_SHIPPING_KEY]: [
			{
				key: STORE_INTEGRATION_CONFIG_KEY_CHILEXPRESS_PARCELS,
				isEncrypted: false,
				isPrivate: false,
				isRequired: true,
				label: TX_INTEGRATION_CONFIG_LABEL_PARCEL_SIZES,
				placeholder: TX_null,
				validationMethod: null,
				component: IntegrationConfigParcelSize,
			},
		],
	},
	localConfig: {
		[STORE_INTEGRATION_TYPE_SHIPPING_KEY]: [
			{
				key: STORE_INTEGRATION_LOCAL_CONFIG_KEY_CHILEXPRESS_MARKUP,
				label: TX_MARKUP,
				isRequired: false,
				validationMethod: getMarkupPercentError,
			},
		],
	},
	hideCosts: true,
	hidePickup: true,
	components: {
		createShipment: null,
		createShipmentMultiple: null,
		saveShippingMethod: ShippingMethodChilexpress,
	},
};

export const STORE_INTEGRATION_STAMPS = {
	key: STORE_INTEGRATION_STAMPS_KEY,
	name: 'Stamps.com',
	oauth: {
		clientId: process.env.REACT_APP_BUILD_EXTERNAL_STAMPS_CLIENT_ID || '',
		componentAuthorize: IntegrationConfigOauthStampsAuthorize,
		componentAuthorizeRedirect: IntegrationConfigOauthStampsRedirect,
		redirectUri: URL_ADMIN_SETTINGS_INTEGRATIONS_OAUTH_REDIRECT,
		redirectUriData: {
			'key': STORE_INTEGRATION_STAMPS_KEY,
		},
	},
	adminDisplay: [
		{
			key: STORE_INTEGRATION_DISPLAY_KEY_STAMPS_BALANCE,
			label: TX_BALANCE,
			requireEnabled: true,
			remoteKey: STORE_INTEGRATION_DISPLAY_KEY_STAMPS_BALANCE,
			formatPrice: true,
			component: null,
		},
		{
			key: STORE_INTEGRATION_DISPLAY_KEY_STAMPS_TEST_PRINT,
			label: null,
			requireEnabled: true,
			remoteKey: null,
			component: IntegrationDisplayStampsTestPrint,
		},
		{
			key: STORE_INTEGRATION_DISPLAY_KEY_STAMPS_PARCELGUARD_MODAL,
			label: null,
			requireEnabled: true,
			remoteKey: null,
			component: IntegrationDisplayStampsParcelGuardModal,
			isHidden: true,
		},
	],
	config: {
		[STORE_INTEGRATION_TYPE_SHIPPING_KEY]: [
			{
				key: STORE_INTEGRATION_CONFIG_KEY_STAMPS_BALANCE_REFILL,
				isEncrypted: false,
				isPrivate: false,
				isRequired: false,
				label: TX_INTEGRATION_CONFIG_LABEL_BALANCE_REFILL,
				placeholder: TX_null,
				validationMethod: null,
				component: IntegrationConfigStampsBalanceRefill,
				hideWhenDisabled: true,
			},
			{
				key: STORE_INTEGRATION_CONFIG_KEY_STAMPS_PARCELGUARD_ENABLED,
				isEncrypted: false,
				isPrivate: false,
				isRequired: false,
				label: TX_INTEGRATION_CONFIG_LABEL_PARCELGUARD_ENABLED,
				placeholder: TX_null,
				validationMethod: null,
				component: null,
				dataType: STORE_INTEGRATION_CONFIG_TYPE_BOOL,
				hideWhenDisabled: true,
			},
		],
	},
	localConfig: {
		[STORE_INTEGRATION_TYPE_SHIPPING_KEY]: [],
	},
	hideCosts: false,
	hidePickup: true,
	components: {
		createShipment: CreateShipmentStamps,
		createShipmentMultiple: null,
		saveShippingMethod: null,
	},
};

export const STORE_INTEGRATIONS_ALL = [
	STORE_INTEGRATION_CHILEXPRESS, 
	STORE_INTEGRATION_STAMPS, 
];
