import React from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from '../../context/FormProvider';

import styles from './_styles/Form.module.scss';

export const Form = (props) => {
  
  // Context
  const { 
    formState, 
    isFormValid, 
  } = useFormContext();

  // Props
  const {
    children,
    error,
    formRef,
    onSubmit,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const handleSubmit = async (evt) => {

    if(evt) { evt.preventDefault(); }

    if(onSubmit && isFormValid()) { 
      await onSubmit(formState);
    }
  };

  // Effects
  // None yet

  // Render
  return (
    <div className={`${styles.Form}`}>
      <div className={styles.formLiner}>
        <form onSubmit={handleSubmit} ref={formRef}>
            
          <div className={`${styles.formError} ${error ? styles.errorPresent : ''}`}>{error ? t(error) : ''}</div>
     
          <div className={styles.formBody}>
            <div className={styles.formInputWrapper}>
              {children}
            </div>
          </div>

        </form>
      </div>
    </div>
  );
};

export default Form;




