import React, { createContext, useCallback, useContext, useState, useRef } from 'react';

const FormContext = createContext();

export const useFormContext = () => useContext(FormContext);

const FormProvider = (props) => {
  
  // Props
  const {
    children, 
    initialValues = {},
  } = props;

  // State
  const [formState, setFormState] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(false);
  const [errors, setErrors] = useState({});
  
  // Refs
  const fieldValidators = useRef({});

  // Methods
  const registerField = useCallback((name, validateFn) => {
    fieldValidators.current[name] = validateFn;
  }, []);

  const unregisterField = useCallback((name) => {
    delete fieldValidators.current[name];
    setErrors((prev) => {
      const { [name]: _, ...rest } = prev;
      return rest;
    });
  }, []);

  const setFieldValue = (name, value) => {
    if(formState[name] !== value) {

      // Set state
      setFormState((prev) => ({ ...prev, [name]: value }));

      // Validate if there's an error
      if(errors[name]) {
        validateField(name, value);
      }

      // Make form dirty
      if(!isDirty) {
        setIsDirty(true);
      }
    } 
  };

  const setFieldError = (name, error) => {
    setErrors((prev) => ({ ...prev, [name]: error }));
  };

  const validateField = (name, validationValue = null) => {
    const validateFn = fieldValidators.current[name];
    if (!validateFn) return true;
    const error = validateFn(validationValue || formState[name]);
    setFieldError(name, error);
    return !error;
  };

  const isFormValid = () => {
    let formValid = true;    
    for(const key of Object.keys(fieldValidators.current)) {
      if(!validateField(key)) {
        formValid = false;
      }
    }
    return formValid;
  };

  const isFormDirty = () => {
    return isDirty;
  };

  return (
    <FormContext.Provider
      value={{
        formState,
        setFieldValue,
        errors,
        registerField,
        unregisterField,
        validateField,
        isFormValid,
        isFormDirty,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
export default FormProvider;