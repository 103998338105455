import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { API_KEY_QUICK_SEARCH } from '../../../../constants/api';
import * as tx from '../../../../constants/strings';

import { AdminFilterElement } from '../../../../models/admin-table';

import {
  getTableFiltersDefault,
  getTableFiltersExtended,
} from '../../../../utils/table';

let allActionCreators = Object.assign({});

export class AdminTableFilters extends Component {

  getFilterTitle(filterKey) {

    // Check universal filters
    if(filterKey === API_KEY_QUICK_SEARCH) {
      return tx.TX_SEARCH;
    }

    const filterElem = this.getFilterElementModel(filterKey);
    if(filterElem) {
      return filterElem.name;
    }

    return tx.TX_null;
  }

  getFilterElementModel(filterKey) {

    // Check default config filters
    for(const filter of getTableFiltersDefault(this.props.config)) {
      if(filterKey === filter.key) {
        return filter;
      } else if(filter.minKey && filterKey === filter.minKey) {
        return filter;
      } else if(filter.maxKey && filterKey === filter.maxKey) {
        return filter;
      }
    }

    // Check for conditional filters
    for(const filter of getTableFiltersExtended(this.props.config, this.props.filters)) {
      if(filterKey === filter.key) {
        return filter;
      } else if(filter.minKey && filterKey === filter.minKey) {
        return filter;
      } else if(filter.maxKey && filterKey === filter.maxKey) {
        return filter;
      }
    }

    return null;
  }

  getCustomOptions(filterKey) {
    try {
      return this.props.filtersCustomOptions[filterKey] || [];
    } catch(err) {
      console.error(err);
    }
    return [];
  }

  getFilterValue(filterKey) {

    const rawValue = this.props.filters[filterKey];

    try {

      const filterElem = this.getFilterElementModel(filterKey);

      if(filterElem && rawValue) {
        return filterElem.getFilterDisplayValue(rawValue, this.getCustomOptions(filterKey));
      }
    } catch(err) {
      console.error(err);
    }
    return rawValue;
  }

  getCurrentFilters() {
    const filterArray = [ AdminFilterElement.quickSearch() ];

    for(const df of getTableFiltersDefault(this.props.config)) {
      filterArray.push(df);
    }

    for(const df of getTableFiltersExtended(this.props.config, this.props.filters)) {
      filterArray.push(df);
    }

    return filterArray;
  }

  getTagLabel(filterKey) {
    try {
      if(filterKey.slice(-4) === '_min') {
        return tx.TX_MIN;
      }
      if(filterKey.slice(-4) === '_max') {
        return tx.TX_MAX; 
      }
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  removeFilterResp(filter, respKey) {

    if(!filter || !respKey) { return null; }

    try {

      const currentValue = this.props.filters[filter.key].split(',');
      
      if(currentValue.includes(respKey)) {
        if(currentValue.length === 1) {
          this.props.removeFilters([filter.key]);
        } else {

          currentValue.splice(currentValue.indexOf(respKey), 1);

          this.props.setFilters({
            [filter.key]: currentValue.join(','),
          });
        }
      }
    } catch(err) {
      console.error(err)
      this.props.removeFilters([filter.key]);
    }
  }

  getListElementValue(elem) {
    try {
      return elem.split(',');
    } catch(err) {
      console.error(err);
    }
    return [];
  }

  clearAllFilters() {
    this.props.removeFilters(Object.keys(this.props.filters));
  }

  render() {

		const {t} = this.props;
	
    return <div className={'AdminTableFilters'}>
      <div className='atfLiner'>
        <div className='atfTitle'>
          <div className='atfTitleValue'>{t(tx.TX_FILTER_APPLIED_FILTERS)}</div>
          <div className='atfTitleClear' onClick={this.clearAllFilters.bind(this)}>{t(tx.TX_CLEAR_ALL)}</div>
        </div>
        <div className='atfBody'>
          <div className='atfBodyLiner'>
            {this.props.filters ? 
              <>
                {Object.keys(this.props.filters).length > 0 ?
                  <>
                    {this.getCurrentFilters().map((filter, i) => {

                      if(!filter.isActive(this.props.filters)) { return null; }

                      return <div className='atfItemWrapper' key={i}>
                        <div className='atfItem'>
                          <div className='atfItemTitle'>{t(filter.name)}</div>
                          <div className='atfItemBody'>
                            {filter.isListElement() ?
                              <>
                                {this.getListElementValue(this.props.filters[filter.key]).map((respKey, k) => {
                                  return <div className='atfItemTag' key={k}>
                                    <div className='atfItemTagBody'>{filter.optionsTranslate ? t(filter.getElementName(respKey, this.getCustomOptions(filter.key))) : filter.getElementName(respKey, this.getCustomOptions(filter.key))}</div>
                                    <div className='atfItemTagAction' onClick={() => this.removeFilterResp(filter, respKey)}>
                                      <div className='atfItemTagActionX'>&times;</div>
                                    </div>
                                  </div>;
                                })}
                              </> :
                              <>
                                {filter.getActiveKeys(this.props.filters).map((filterKey, j) => {
                                  return <div className='atfItemTag' key={j}>
                                    {this.getTagLabel(filterKey) ?
                                      <div className='atfItemTagLabel'>{t(this.getTagLabel(filterKey))}</div> :
                                      null
                                    }
                                    <div className='atfItemTagBody'>{this.getFilterValue(filterKey)}</div>
                                    <div className='atfItemTagAction' onClick={() => this.props.removeFilters([filterKey])}>
                                      <div className='atfItemTagActionX'>&times;</div>
                                    </div>
                                  </div>;
                                })}
                              </>
                            }
                          </div>
                        </div>
                      </div>;
                    })}
                  </> :
                  <div className='atfNoFiltersLabel'>{t(tx.TX_FILTER_APPLIED_FILTERS_ZERO)}</div>
                }
              </> :
              null
            }
          </div>
        </div>
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableFilters));


