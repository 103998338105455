import React from 'react';
import { useTranslation } from 'react-i18next';

import { TX_NONE } from '../../../../constants/strings';

import styles from './_styles/AdminPageSectionViewElement.module.scss';

export const AdminPageSectionViewElement = (props) => {
  
  // Props
  const {
    label,
    loading,
    nullValue,
    value,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const isNull = () => {
    if(value === 0) {
      return false;
    }
    return !value;
  };

  const getNullValue = () => {
    return t(nullValue || TX_NONE);
  };

  const formatValue = () => {
    if(loading) {
      return '';
    }
    if(isNull()) {
      return getNullValue();
    }
    return value;
  };

  // Render
  return (
    <div className={styles.AdminPageSectionViewElement}>
      <div className={styles.viewWrapper}>
        <div className={styles.label}>{t(label)}</div>
        <div className={`${styles.value} ${isNull() ? styles.null : ''}`}>
          {formatValue()}
        </div>
      </div>
    </div>
  );
};

export default AdminPageSectionViewElement;


