import React, { Component } from 'react';
import { connect } from 'react-redux';

import AdminTableFilters from './blocks/table/AdminTableFilters';
import AdminTableControls from './blocks/table/AdminTableControls';
import AdminTableResults from './blocks/table/AdminTableResults';
import AdminTableHeader from './blocks/table/AdminTableHeader';
import AdminTableBody from './blocks/table/AdminTableBody';
import AdminTablePagination from './blocks/table/AdminTablePagination';

import { API_KEY_QUICK_SEARCH } from '../../constants/api';
import { 
  TC_HEADER_SORT_DIR_ASC, 
  TC_HEADER_SORT_DIR_DESC,
} from '../../constants/tables';

import { 
  getDataRowBySelectionValue,
  getDefaultPageSize, 
  getDefaultSortDir, 
  getDefaultSortKey, 
  getDefaultViewKey, 
  getElementSortKey, 
  getNonPageinatedData, 
  getPageinatedData, 
  getTableFilterStorageKey,
  getTableFilterKeysExtendedByValue, 
  isElementSortable, 
  isRowSelectedByKey, 
  isTablePaginated, 
  getTableSelectionKey, 
} from '../../utils/table';

import './_styles/_admintable.scss';
import './_styles/_customrows.scss';

import * as productActionCreators from '../../actions/product';
let allActionCreators = Object.assign({}, productActionCreators);

export class AdminTable extends Component {

  constructor(props) {
    super(props);

    this.primaryFilterKey = getTableFilterStorageKey(this.props.config);

    this.state = {
      sortKey: getDefaultSortKey(this.props.config, this.props.schema),
      sortDir: getDefaultSortDir(this.props.config, this.props.schema),
      quickSearch: '',
      quickSearchInput: '',
      filters: this.primaryFilterKey && this.props.product && this.props.product.filters[this.primaryFilterKey] ? this.props.product.filters[this.primaryFilterKey] : {},
      filtersCustomOptions: this.primaryFilterKey && this.props.product && this.props.product.filtersCustomOptions[this.primaryFilterKey] ? this.props.product.filtersCustomOptions[this.primaryFilterKey] : {},
      viewKey: getDefaultViewKey(this.props.config),

      selectedRows: [],

      pageNum: 1,
      pageSize: getDefaultPageSize(this.props.config), 
    };
  }

  changeView(viewKey) {
    if(!viewKey) {
      return null;
    }
    if(viewKey !== this.state.viewKey) {
      this.setState({ viewKey: viewKey });
    }
  }

  changeSort(schemaElem) {

    if(isElementSortable(schemaElem) === false) {
      return;
    }

    if(this.isColumnSorted(schemaElem) === false) {
      this.setState({
        sortKey: getElementSortKey(schemaElem),
        sortDir: getDefaultSortDir(this.props.config, this.props.schema),
      }, () => {
        this.refreshApiData();
      });
    } else {
      if(this.state.sortDir === TC_HEADER_SORT_DIR_ASC) {
        this.setState({ sortDir: TC_HEADER_SORT_DIR_DESC }, () => {
          this.refreshApiData();
        });
      } else {
        this.setState({ sortDir: TC_HEADER_SORT_DIR_ASC }, () => {
          this.refreshApiData();
        });
      }
    }
  }

  changeSortByKey(sortKey, sortDir) {
    this.setState({
      sortKey: sortKey || getDefaultSortKey(this.props.config, this.props.schema),
      sortDir: sortDir || getDefaultSortDir(this.props.config, this.props.schema),
    }, () => {
      this.refreshApiData();
    });
  }

  getSortApiKey() {
    if(!this.state.sortKey) {
      return '';
    } else if(this.state.sortDir === TC_HEADER_SORT_DIR_ASC) {
      return this.state.sortKey;
    } else {
      return `-${this.state.sortKey}`;
    }
  }

  async refreshApiData(resetPage = true, scrollToTop = false, keepSelections = false, refreshSilently = false) {
    if(isTablePaginated(this.props.config)) {
      // Call API
      // Function should all conform to: callApi(pageNum, pageSize, sort, filters, refreshSilently = false)

      const page = resetPage ? 1 : this.state.pageNum;
      if(resetPage && this.state.pageNum !== 1) {
        this.setState({ pageNum: 1 });
      }

      if(this.primaryFilterKey) {
        this.props.productSetProductFilters({
          key: this.primaryFilterKey,
          value: this.state.filters,
        });
      }

      this.props.callApi(page, this.state.pageSize, this.getSortApiKey(), this.state.filters, refreshSilently);

      if(!keepSelections) {
        this.setState({ selectedRows: [] });
      }

      if(scrollToTop) {
        window.scrollTo({ 
          top: '200px', 
          left: 0, 
          behavior: 'smooth',
        });
      }
    }
  }

  changeQuickSearch(textVal) {
    this.setState({
      quickSearchInput: textVal.target.value,
    });
  }

  submitQuickSearch(evt) {
    if(evt) { evt.preventDefault(); }

    if(this.state.quickSearchInput) {
      this.setState({
        filters: Object.assign({}, this.state.filters, {
          [API_KEY_QUICK_SEARCH]: this.state.quickSearchInput,
        }),
      }, () => {
        this.refreshApiData();
      });
    } else {
      const filtersClone = Object.assign({}, this.state.filters);
      delete filtersClone[API_KEY_QUICK_SEARCH];
      this.setState({ filters: filtersClone }, () => {
        this.refreshApiData();
      });
    }
  }

  changeFilters(filters = {}) {

    // List of keys to persist; 
    // CANNOT be overwriten
    const KEYS_TO_PERSIST = [
      API_KEY_QUICK_SEARCH,
    ];

    const persistFilters = {};
    const filterKeys = Object.keys(this.state.filters);

    for(const key in KEYS_TO_PERSIST) {
      if(filterKeys.includes(key)) {
        persistFilters[key] = this.state.filters[key];
      }
    }

    this.setState({
      filters: Object.assign({}, filters, persistFilters),
    }, () => {
      this.refreshApiData();
    });
  }

  changePage(pageNum, pageSize) {
    if(!pageNum || !pageSize) {
      return;
    }

    this.setState({
      pageNum: pageNum,
      pageSize: pageSize,
    }, () => {
      this.refreshApiData(false, true);
    });
  }

  isColumnSorted(schemaElem) {
    return this.state.sortKey === getElementSortKey(schemaElem);
  }

  getData() {
    if(!this.props.data) {
      return [];
    }

    if(isTablePaginated(this.props.config)) {
      return getPageinatedData(this.props.data);
    } else {
      return getNonPageinatedData(this.props.data, this.props.schema, this.state.sortKey, this.state.sortDir, this.state.quickSearchInput);
    }
  }

  getDataCount() {
    if(!this.props.data) { return 0; }

    if(isTablePaginated(this.props.config)) {
      return this.props.dataCount;
    } else {
      return getNonPageinatedData(this.props.data, this.props.schema, this.state.sortKey, this.state.sortDir, this.state.quickSearchInput).length;
    }
  }

  removeFilters(keys) {

    try {

      const filtersClone = Object.assign({}, this.state.filters);
      const initCount = Object.keys(filtersClone).length;

      for(const key of keys) {

        if(Object.keys(this.state.filters).includes(key)) {
          if(key === API_KEY_QUICK_SEARCH) {
            this.setState({
              quickSearchInput: '',
            }, () => {
              this.submitQuickSearch();
            });
          } else {
            const currentValue = this.state.filters[key];
            delete filtersClone[key];
            for(const eKey of getTableFilterKeysExtendedByValue(this.props.config, key, currentValue)) {
              if(filtersClone[eKey]) {
                delete filtersClone[eKey];
              }
            }
          }
        }
      }

      const postCount = Object.keys(filtersClone).length;
      if(initCount !== postCount) {
        this.changeFilters(filtersClone);
      }
    } catch(err) {
      console.error(err);
    }
  }

  changeFilterElements(elems) {
    try {
      const filtersClone = Object.assign({}, this.state.filters);
      this.changeFilters(Object.assign({}, filtersClone, elems));
    } catch(err) {
      console.error(err);
    }
  }

  setCustomOptions(optionObj) {
    try {
      const filtersOptionsClone = Object.assign({}, this.state.filtersCustomOptions);
      this.setState({
        filtersCustomOptions: Object.assign({}, filtersOptionsClone, optionObj),
      }, () => {
        if(this.primaryFilterKey) {
          this.props.productSetProductFilterCustomOptions({
            key: this.primaryFilterKey,
            value: this.state.filtersCustomOptions,
          });
        }
      });
    } catch(err) {
      console.error(err);
    }
  }

  hotSwapData(swapObj, lookupKey, lookupValue) {
    if(this.props.hotSwap) {
      this.props.hotSwap(swapObj, lookupKey, lookupValue);
    }
  }

  allRowsSelected() {
    return this.getData().length > 0 && this.getData().length === this.state.selectedRows.length;
  }

  toggleSelectedRow(dataValue) {
    const dataRows = this.getData();
    const selectionKey = getTableSelectionKey(this.props.config);
    const rowInQuestion = getDataRowBySelectionValue(dataValue, this.props.config, dataRows);

    let newSelections = [];
    if(isRowSelectedByKey(dataValue, selectionKey, this.state.selectedRows)) {
      for(const sel of this.state.selectedRows) {
        if(sel[selectionKey] !== rowInQuestion[selectionKey]) {
          newSelections.push(sel);
        }
      }
    } else {
      newSelections = [...this.state.selectedRows];
      newSelections.push(rowInQuestion);
    }

    this.setState({
      selectedRows: newSelections,
    })
  }

  refreshSelectedData() {
    const dataRows = this.getData();
    const selectionKey = getTableSelectionKey(this.props.config);

    const updatedSelections = [];
    for(const selection of this.state.selectedRows) {

      const rowInQuestion = getDataRowBySelectionValue(selection[selectionKey], this.props.config, dataRows);
      if(rowInQuestion) {
        updatedSelections.push(rowInQuestion);
      }
    }

    this.setState({
      selectedRows: updatedSelections,
    });
  }

  selectAllRowToggle() {
    if(this.allRowsSelected()) {
      this.setState({ selectedRows: [] });
    } else {
      this.setState({ selectedRows: [...this.getData()] });
    }
  }

	render() {	
    return <div className={'AdminTable'}>
      <div 
        className={isTablePaginated(this.props.config) && Object.keys(this.state.filters).length > 0 ? 'atFilterWrapper open' : 'atFilterWrapper'}>
        <AdminTableFilters 
          config={this.props.config}
          filters={this.state.filters}
          filtersCustomOptions={this.state.filtersCustomOptions}
          removeFilters={this.removeFilters.bind(this)}
          setFilters={this.changeFilterElements.bind(this)} />
      </div>
      <AdminTableControls 
        config={this.props.config}
        changeQuickSearch={this.changeQuickSearch.bind(this)}
        filters={this.state.filters}
        removeFilters={this.removeFilters.bind(this)}
        setFilters={this.changeFilterElements.bind(this)}
        setCustomFilterOptions={this.setCustomOptions.bind(this)}
        submitQuickSearch={this.submitQuickSearch.bind(this)} 
        quickSearchValue={this.state.quickSearchInput} />
      <AdminTableResults 
        config={this.props.config}
        schema={this.props.schema} 
        filters={this.state.filters}
        removeFilters={this.removeFilters.bind(this)}
        setFilters={this.changeFilterElements.bind(this)}
        setCustomFilterOptions={this.setCustomOptions.bind(this)}
        staticFilters={this.props.staticFilters}
        dataLoading={this.props.dataLoading} 
        resultsCount={this.getDataCount()}
        setViewKey={this.changeView.bind(this)} 
        viewKey={this.state.viewKey}
        sortDir={this.state.sortDir}
        sortKey={this.state.sortKey}
        changeSort={this.changeSortByKey.bind(this)}
        selectedRows={this.state.selectedRows}
        refreshData={this.refreshApiData.bind(this)}
        refreshSelections={this.refreshSelectedData.bind(this)} />
      <AdminTableHeader 
        config={this.props.config} 
        schema={this.props.schema}
        sortDir={this.state.sortDir}
        sortKey={this.state.sortKey}
        changeSort={this.changeSort.bind(this)} 
        viewKey={this.state.viewKey}
        allRowsSelected={this.allRowsSelected()}
        selectedRows={this.state.selectedRows} 
        selectAllRows={this.selectAllRowToggle.bind(this)} />
      <AdminTableBody 
        config={this.props.config} 
        schema={this.props.schema} 
        data={this.getData()}
        dataLoading={this.props.dataLoading} 
        filters={this.state.filters}
        hotSwap={this.hotSwapData.bind(this)}
        sortDir={this.state.sortDir}
        sortKey={this.state.sortKey}
        viewKey={this.state.viewKey}
        linkFrom={this.props.linkFrom}
        pageSize={this.state.pageSize}
        selectedRows={this.state.selectedRows}
        toggleSelectedRow={this.toggleSelectedRow.bind(this)}
        refreshData={this.refreshApiData.bind(this)} />
      {isTablePaginated(this.props.config) ?
        <AdminTablePagination 
          config={this.props.config}
          changePage={this.changePage.bind(this)}
          pageNum={this.state.pageNum}
          pageSize={this.state.pageSize}
          resultsCount={this.props.dataCount} /> :
        null
      }
    </div>;
	}
}

function mapStateToProps(state) {
  return {
    product: state.product,
  };
}

export default connect(mapStateToProps, allActionCreators)(AdminTable);