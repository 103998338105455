import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import * as tx from '../../../../constants/strings';

import { formatPrice } from '../../../../utils/formatting';
import { getStoreLanguage } from '../../../../utils/language';
import { 
  getMediaAlt, 
  getOrderedMedia, 
  getProductInventoryLabel, 
} from '../../../../utils/product';

import '../../style/_orderlineitems.scss';

import Checkbox from '../../../Input/Checkbox';
import Dropdown from '../../../Input/Dropdown';
import StaticImage from '../../../Image/StaticImage';

const allActionCreators = Object.assign({});

export class OrderLineItemRefundReturn extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getItemImage() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.inventory) { return null; }

    if(this.props.cartItem.product.foreignModel && !this.props.cartItem.product.foreignModel.useProductImages) {
      return this.props.cartItem.product.foreignModel.getThumbnailImage();
    }

    const productMedia = getOrderedMedia(this.props.cartItem.product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: productMedia[0].src, 
                  alt: getMediaAlt(this.props.cartItem.product, productMedia[0]), 
                  noTranslate: true,
                }} />
    }
    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  getItemName() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.inventory) { return null; }

    return this.props.cartItem.product.name;
  }

  getItemProductLine() {
    if(!this.props.cartItem || !this.props.cartItem.product || !this.props.cartItem.product.productLine) { return null; }

    return this.props.cartItem.product.productLine.name;
  }

  toggleSelected() {
    this.props.toggleRow(this.props.cartItem);
  }

  quantityDropdownOptions() {

    const qtyOptions = [{
      display: 0,
      value: 0,
    }];
    if(!this.props.cartItem || !this.props.cartItem.quantity) { return qtyOptions; }

    for(let i = 1; i <= this.props.cartItem.quantity; i++) {
      qtyOptions.push({
        display: i,
        value: i,
      });
    }
    return qtyOptions;
  }

  changeQuantity(evt) {
    this.props.setQuantity(this.props.cartItem, evt.target.value);
  }

  toggleReturnToInventory() {
    this.props.setReturn(this.props.cartItem);
  }

  render() {

    const {t} = this.props;

    return <div className={'OrderLineItem OrderLineItemRefundReturn'}>
      <div className='oliLiner'>
        <div className='checkboxBlock'>
          <div className='checkboxElement'>
            <Checkbox 
              adminTheme={true}
              name={`select-${this.props.cartItem ? this.props.cartItem.id : 'x'}`}
              id={`ci-return-select-toggle-${this.props.cartItem ? this.props.cartItem.id : 'x'}`}
              value={t(tx.TX_SELECT)}
              checked={this.props.isSelected}
              ambiguousCheck={false}
              onChange={this.toggleSelected.bind(this)} />
          </div>
        </div>
        <div className='itemBlock'>
          <div className='itemWrapper'>
            <div className='itemMediaWrapper'>
              {this.getItemImage()}
            </div>
            <div className='itemDescriptionWrapper'>
              <div className='itemDescription'>
                <div className='nameWrapper'>{this.getItemName()}</div>
                <div className='productLineWrapper'>{this.getItemProductLine()}</div>
                <div className='detailsWrapper'>
                  <div className='detailText'>
                    {getProductInventoryLabel(this.props.cartItem.product, this.props.cartItem.inventory, { translate: t })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='quantityBlock'>
          {this.props.isSelected ?
            <div className='quantityInput'>
              <div className='quantityInputDropdownWrapper'>
                <Dropdown 
                  className='oliQuantityDropdownSelect'
                  options={this.quantityDropdownOptions()}
                  name={t(tx.TX_QUANTITY)}
                  value={this.props.valueQuantity}
                  noTranslate={true}
                  onChange={this.changeQuantity.bind(this)} />
              </div>
            </div> :
            <div className='quantityReadOnly'>
              <div className='quantityReadOnlyValue'>{this.props.valueQuantity}</div>
            </div>
          }
        </div>
        <div className='priceBlock'>
          <div className='priceReadOnly'>
            <div 
              className='priceReadOnlyValue'
              dangerouslySetInnerHTML={{ 
                __html: formatPrice(this.props.cartItem ? this.props.cartItem.price : 0, { addTags: true, language: this.getLanguage() }) 
              }} />
          </div>
        </div>
        <div className='returnBlock'>
          {this.props.isSelected ?
            <div className='returnCheckboxElement'>
              <Checkbox 
                adminTheme={true}
                name={`return-inv-${this.props.cartItem ? this.props.cartItem.id : 'x'}`}
                id={`ci-return-inventory-toggle-${this.props.cartItem ? this.props.cartItem.id : 'x'}`}
                value={t(tx.TX_RETURN)}
                checked={this.props.valueReturn}
                ambiguousCheck={false}
                onChange={this.toggleReturnToInventory.bind(this)} />
            </div> :
            <div className='returnCheckboxElement'></div>
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(OrderLineItemRefundReturn));