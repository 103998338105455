import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import parentStyles from './_styles/ProductPageDetails.module.scss';
import styles from './_styles/ProductPageDetailsLorcana.module.scss';

let allActionCreators = Object.assign({});

export class ProductPageDetailsLorcana extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getProductName() {
    return this.props.productObj.foreignModel.name;
  }

  getProductTypeLine() {
    return this.props.productObj.foreignModel.subtitle || '';
  }

  getProductDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');
    
    const txt = this.props.productObj.foreignModel.bodyText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  getProductSecondaryDescription() {

    const { breaksToBrs } = require('../../../utils/formatting');
    
    const txt = this.props.productObj.foreignModel.flavorText;
    return breaksToBrs(txt, this.descriptionBreakStyle);
  }

  render() {

    if(!this.props.productObj || !this.props.productObj.foreignModel || !this.props.productObj.foreignModel.isLorcanaCard) { return null; }

    return <div className={`${styles.ProductPageDetailsLorcana} ${parentStyles.ProductPageDetails}`}>
      <div className={`${styles.detailsWrapper} ${parentStyles.detailsWrapper}`}>
        <div className={styles.lorcanaWrapper} lang={this.props.productObj.foreignModel.language}>
          <div className={styles.faceWrapper}>
            <div className={parentStyles.headerWrapper}>
              <div className={styles.titleWrapper} lang={this.props.productObj.foreignModel.language}>
                <span className={styles.titleText}>{this.getProductName()}</span>
              </div>
              <div className={styles.typeLine}>{this.getProductTypeLine()}</div>
            </div>
            <div className={parentStyles.borderWrapper}>
              <div className={styles.lorcanaDetails}>
                <div 
                  className={styles.lorcanaDesc}
                  dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
                <div 
                  className={styles.lorcanaSecondaryDesc}
                  dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsLorcana));