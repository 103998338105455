import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../constants/images';
import { 
  PROD_BUYLIST_QTY_LIMIT, 
  PROD_QTY_LIMIT, 
} from '../../../constants/product';
import * as tx from '../../../constants/strings';

import { 
  addToCartEvent,
  removeFromCartEvent, 
} from '../../../utils/analytics';
import { getQuantityError } from '../../../utils/form-validation';
import { 
  formatPrice,
  normalizeQuantity,
  serverDateShort, 
} from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';
import { 
  getMediaAlt, 
  getOrderedMedia, 
  getProductInventoryLabel, 
} from '../../../utils/product';

import Dropdown from '../../Input/Dropdown';
import StaticImage from '../../Image/StaticImage';

let allActionCreators = Object.assign({});

export class MiniCartItem extends Component {
  
  constructor(props) {
    super(props);

    this.BUYLIST_MAX = 9;
    this.CUSTOM_KEY = 'custom';

    this.state = {
      
      qtyInput: false,

      inputQuantity: '',
      errorQuantity: '',
    }

    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.shouldDisplayQtyInput();
  }

  getUserLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getItemImage() {
    if(!this.props.item || !this.props.item.product || !this.props.item.inventory) { return null; }

    if(this.props.item.product.foreignModel && !this.props.item.product.foreignModel.useProductImages) {
      return this.props.item.product.foreignModel.getThumbnailImage();
    }

    const productMedia = getOrderedMedia(this.props.item.product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: productMedia[0].src, 
                  alt: getMediaAlt(this.props.item.product, productMedia[0]), 
                  noTranslate: true,
                }} />
    }
    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  getItemName() {
    if(!this.props.item || !this.props.item.product || !this.props.item.inventory) { return null; }

    return this.props.item.product.nameWithTags;
  }

  getItemProductLine() {
    if(!this.props.item || !this.props.item.product || !this.props.item.product.productLine) { return null; }

    return this.props.item.product.productLine.name;
  }

  getItemPrice() {
    
    if(!this.props.item || !this.props.item.product || !this.props.item.product.productLine) { return null; }
    let itemPrice = 0;

    try {
      if(this.props.order) {
        itemPrice = parseInt(this.props.item.quantity) * parseFloat(this.props.item.price);
      } else {
        if(this.props.isBuylist) {
          itemPrice = parseInt(this.props.item.quantity) * parseFloat(this.props.item.inventory.buyPrice);
        } else {
          itemPrice = parseInt(this.props.item.quantity) * parseFloat(this.props.item.inventory.sellPrice);
        }
      }
    } catch(errResp) {
      console.error(errResp);
      itemPrice = 0;
    }

    return formatPrice(itemPrice, { addTags: true, language: this.getUserLanguage() });
  }

  formatOptions() {

    const respArray = [];

    try {
      if(!this.props.item || !this.props.item.inventory) {
        return [];
      }
      
      respArray.push({
        display: `0 (${this.props.t(tx.TX_DELETE)})`,
        value: 0,
      });

      const maxQty = this.props.isBuylist ? this.BUYLIST_MAX : (parseInt(this.props.item.inventory.totalQuantity) < PROD_QTY_LIMIT ? parseInt(this.props.item.inventory.totalQuantity) : PROD_QTY_LIMIT);
      for(let i = 1; i <= maxQty; i++) {
        respArray.push({
          display: i,
          value: i,
        });
      }
      if(this.props.isBuylist) {
        respArray.push({
          display: '10+',
          value: this.CUSTOM_KEY,
        });
      }
    } catch(err) {
      console.error(err);
    }
    return respArray;
  }

  changeQuantity(evt) {
    
    const currentQuantity = this.props.item.quantity;
    const val = evt.target.value === this.CUSTOM_KEY ? this.CUSTOM_KEY : parseInt(evt.target.value);
    
    if(val === 0) {
      this.props.removeItem(this.props.item);
      if(val !== this.CUSTOM_KEY) {
        removeFromCartEvent(this.props.item.inventory, this.props.item.product, {
          quantity: currentQuantity,
        });
      }
    } else {
      if(val === this.CUSTOM_KEY) {
        this.setState({ 
          qtyInput: true, 
          inputValue: (this.BUYLIST_MAX + 1).toString(), 
        }, () => {
          if(this.inputRef && this.inputRef.current) {

            const input = this.inputRef.current;
            input.focus(); // Focus on the input

            // // Check if the input can hold a selection
            // const len = input.value.length;
            // if(input.setSelectionRange) {
            //   input.setSelectionRange(len, len); // Move cursor to the end
            // } else if(input.createTextRange) { // For older IE browsers
            //   const range = input.createTextRange();
            //   range.collapse(true);
            //   range.moveEnd('character', len);
            //   range.moveStart('character', len);
            //   range.select();
            // }
          }
        });
      } else {

        const newQty = val - currentQuantity;

        // Fire add_to_cart or remove_from_cart analytics event
        if(newQty > 0) {
          addToCartEvent(this.props.item.inventory, this.props.item.product, {
            quantity: newQty,
          });
        } else if(newQty < 0) {
          removeFromCartEvent(this.props.item.inventory, this.props.item.product, {
            quantity: -1 * newQty,
          });
        }

        this.props.editItem(this.props.item, val);
      }
    }
  }

  getCartItemQuantity() {
    try {
      if(!this.props.item || !this.props.item.quantity) {
        return 1;
      }
      return parseInt(this.props.item.quantity);
    } catch(err) {
      console.error(err);
      return 1;
    }
  }

  getReleaseDate() {
    if(!this.props.item || !this.props.item.product) { return null; }

    return serverDateShort(this.props.item.product.releaseDate)
  }

  isPreorder() {
    if(!this.props.item || !this.props.item.product) { return false; }
    return this.props.item && this.props.item.product && this.props.item.product.isReleased === false;
  }

  isGreyedOut() {
    return this.getReturnQuantity() >= this.getCartItemQuantity();
  }

  getReturnQuantity() {
    if(!this.props.order || !this.props.item) { return 0; }
    return this.props.order.getQuantityRefunded(this.props.item) || 0;
  }

  shouldDisplayQtyInput() {
    if(this.props.isBuylist && this.getCartItemQuantity() > this.BUYLIST_MAX) {
      this.setState({ 
        inputQuantity: this.getCartItemQuantity(), 
        qtyInput: true,
      });
    }
  }

  changeQuantityInput(evt) {
    this.setState({
      inputQuantity: evt.target.value,
    }, () => {
      if(this.state.errorQuantity) {
        this.validateQuantityInput(false);
      }
    });
  }

  validateQuantityInput(shouldNormalize = true) {
    this.setState({ 
      inputQuantity: shouldNormalize ? normalizeQuantity(this.state.inputQuantity) : this.state.inputQuantity,
      errorQuantity: getQuantityError(normalizeQuantity(this.state.inputQuantity)),
    });
  }

  submitQuantityInput() {
    if(getQuantityError(normalizeQuantity(this.state.inputQuantity))) {
      // Error, do not subit; revalidate to show error
      this.validateQuantityInput();
    } else {
      const val = parseInt(this.state.inputQuantity);
      if(val === 0) {
        this.props.removeItem(this.props.item);
      } else if(val > 0) {
        if(this.getCartItemQuantity() !== val) {
          this.props.editItem(this.props.item, val);
        }
      }
    }
  }

  render() {

    const {t} = this.props;

    return <div className={`MiniCartItem`}>
      <div className={`miniCartItemWraper${this.isGreyedOut() ? ' greyed' : ''}`}>
        <div className='mciImageWrapper'>
          <div className='mciImage'>{this.getItemImage()}</div>
        </div>
        <div className='mciBodyWrapper'>
          <div className='mciNameWrapper'>
            <div className='nameWrapper'>{this.getItemName()}</div>
            <div className='productLineWrapper'>{this.getItemProductLine()}</div>
          </div>
          {this.isPreorder() && !this.props.isBuylist ?
            <div className='mciPreorderWrapper'>
              <div className='preorderLiner'>
                <div className='preorderLabelWrapper'>
                  <div className='preorderLabel'>
                    <div className='preorderLabelValue'>{t(tx.TX_PREORDER)}</div>
                  </div>
                </div>
                <div className='preorderReleaseWrapper'>
                  <div className='preorderRelease'>
                    <div className='releaseLabel'>{t(tx.TX_DATE)}</div>
                    <div className='releaseValue'>{this.getReleaseDate()}</div>
                  </div>
                </div>
              </div>
            </div> :
            null
          }
          <div className='mciDetailsWrapper'>
            <div className='mciDetailText'>
              {getProductInventoryLabel(this.props.item.product, this.props.item.inventory, { translate: t })}
            </div>
            <div className='mciDetailKeyValue'></div>
          </div>
        </div>
        <div className='mciPriceWrapper'>
          {this.getReturnQuantity() ?
            <div className='tagWrapper aboveOverlay'>
              <div className='tagLabel'>{this.getReturnQuantity() >= this.getCartItemQuantity() ? t(tx.TX_REFUNDED) : t(tx.TX_REFUNDED_QTY, { qty: this.getReturnQuantity() })}</div>
            </div> :
            null
          }
          {this.props.isBuylist ?
            <div className='buylistWrapper'>
              <div className='buylistLabel'>{t(tx.TX_BUYLIST)}</div>
            </div> :
            ''
          }
          <div className='priceWrapper' dangerouslySetInnerHTML={{ __html: this.getItemPrice() }} />
          {this.props.readOnly ?
            <div className='quantityReadOnlyWrapper'>
              <div className='quantRoX'>&times;</div>
              <div className='quantRoValue'>{this.getCartItemQuantity()}</div>
            </div> :
            <div className='quantityWrapper'>
              <div className='quantityLabel'></div>
              {this.state.qtyInput ?
                <div className='quantityInput'>
                  <input
                    type='number'
                    ref={this.inputRef}
                    className={`inputElement ${this.state.errorQuantity ? 'InputError' : ''}`}
                    min={0}
                    max={PROD_BUYLIST_QTY_LIMIT} 
                    step={1}
                    value={this.state.inputQuantity}
                    onChange={this.changeQuantityInput.bind(this)}
                    onBlur={this.submitQuantityInput.bind(this)} />
                </div> :
                <div className='quantityDropdown'>
                  <Dropdown 
                    className='mciDropdownSelect'
                    options={this.formatOptions()}
                    name={t(tx.TX_QUANTITY)}
                    value={this.getCartItemQuantity()}
                    noTranslate={true}
                    onChange={this.changeQuantity.bind(this)} />
                </div>
              }
            </div>
          }
        </div>
        <div className='miniCartItemDisabledScreen'></div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(MiniCartItem));