import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { TX_SELECT } from '../../../../../constants/strings';

import { 
  getDefaultStyle,
  isRowSelected, 
} from '../../../../../utils/table';

import Checkbox from '../../../../Input/Checkbox';

let allActionCreators = Object.assign({});

export class CheckboxElement extends Component {

  render() {  

    const {t} = this.props;

    return <div className={'CheckboxElement'} {...this.props.customAttr}>
      <div className='checkboxElementWrapper' style={this.props.dataValue ? {} : getDefaultStyle(this.props.schema)}>
        <Checkbox 
          adminTheme={true}
          name={t(TX_SELECT)}
          id={`che-checkbox-${this.props.dataValue}`}
          value={this.props.dataValue}
          checked={isRowSelected(this.props.schema, this.props.dataValue, this.props.selectedRows)}
          onChange={() => this.props.toggleSelectedRow(this.props.dataValue)} />
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(CheckboxElement));


