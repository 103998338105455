import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { IMG_GENERIC_PRODUCT } from '../../../constants/images';

import { 
  getMediaAlt, 
  getOrderedMedia 
} from '../../../utils/product';

import StaticImage from '../../Image/StaticImage';

let allActionCreators = Object.assign({});

export class ProductPageMedia extends Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedMedia: 0, 
      visibleLoaded: false, 

      pageWidth: null,
    }

    this.containerRef = React.createRef();

    this.checkSizeThrottled = _.throttle(this.checkSize.bind(this), 50);
  }

  componentDidMount() {
    window.addEventListener('resize', this.checkSizeThrottled, false);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkSizeThrottled, false);
  }

  checkSize() {
    // Only purpose is to modify state and trigger rerender
    if(window.innerWidth !== this.state.pageWidth) {
      this.setState({ pageWidth: window.innerWidth });
    }
  }

  visibleMediaLoaded(isVisible) {
    
    if(!isVisible) { return null; }
    
    if(isVisible && !this.state.visibleLoaded) {
      this.setState({
        visibleLoaded: true, 
      });
    }
  }

  setSelectedMedia(idx) {
    this.setState({
      selectedMedia: idx, 
    });
  }

  getWrapperWidth() {
    if(this.containerRef && this.containerRef.current) {
      return this.containerRef.current.getBoundingClientRect().width;
    }
    return null;
  }

  render() { 

    const productMedia = getOrderedMedia(this.props.productObj);

    return <div className={'ProductPageMedia'}>
      <div className='ppMediaInnerWrapper'>
        <div className='ppMediaPrimary'>
          <div className='ppMediaPrimaryWrapper'>
            {productMedia.length === 0 ?
              <StaticImage 
                imgObj={IMG_GENERIC_PRODUCT} /> :
              <>
                {productMedia.map((mediaObj, i) => {
                  return <div key={i} className={i === this.state.selectedMedia ? 'primaryMediaItemWrapper' : 'primaryMediaItemWrapper hidden'} ref={this.containerRef}>
                    {i === this.state.selectedMedia || this.state.visibleLoaded ?
                      <StaticImage 
                        imgObj={{
                          src: productMedia[this.state.selectedMedia].src, 
                          alt: getMediaAlt(this.props.productObj, productMedia[this.state.selectedMedia]), 
                          noTranslate: true,
                        }}
                        onImgLoad={() => this.visibleMediaLoaded(i === this.state.selectedMedia)}
                        maxWidth={this.getWrapperWidth()} /> :
                      null
                    }
                  </div>;
                })}
              </>
            }
          </div>
        </div>
        {productMedia.length > 1 ?
          <div className='ppMediaThumbs'>
            {productMedia.map((md, i) => {
              return <div 
                      key={md.id} 
                      className={this.state.selectedMedia === i ? 'ppThumbWrapper current' : 'ppThumbWrapper'}
                      onClick={() => this.setSelectedMedia(i)}>
                <StaticImage 
                  imgObj={{
                    src: productMedia[i].src, 
                    alt: getMediaAlt(this.props.productObj, productMedia[i]), 
                    noTranslate: true,
                  }} /> 
              </div>;
            })}
          </div> :
          null
        }
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageMedia));