import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormContext } from '../../../context/FormProvider';

import styles from './_styles/AdminField.module.scss';

export const AdminField = (props) => {
  
  // Dispatch
  // const dispatch = useDispatch();

  // Context
  const {
    formState,
    setFieldValue,
    errors,
    validateField,
    registerField,
    unregisterField,
  } = useFormContext();

  // Props
  const {
    className,
    disabled = false,
    label,
    maxLength,
    name,
    placeholder,
    required,
    supplement: Supplement,
    type,
    useCustomHook,
    width,
  } = props;

  // Field hook
  const {
    value,
    onChange,
    validate,
  } = useCustomHook;

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const handleChange = (evt) => {
    onChange(evt);
  };

  const handleBlur = () => {
    validateField(name);
  };

  const getClass = () => {

    const rootClass = styles.AdminField;
    const widthClass = width ? styles[width] : '';
    const customClass = className || '';

    return `${rootClass} ${widthClass} ${customClass}`;
  };

  // Effects
  useEffect(() => {
    if(formState[name] !== value) {
      setFieldValue(name, value || formState[name]); // Use initial value if available
    }
    setFieldValue(name, value); // Sync the custom hook value to FormProvider
  }, [name, value, formState, setFieldValue]);

  useEffect(() => {
    if (validate) {
      registerField(name, validate); // Register the field only once
    }
    return () => {
      unregisterField(name); // Unregister on unmount
    };
    // eslint-disable-next-line
  }, [name, registerField, unregisterField]);

  // Render
  return (
    <div className={getClass()}>

      <div className={`${styles.label} ${required ? styles.required : styles.optional}`}>{t(label)}</div>
      <div className={styles.inputWrapper}>
        <input
          type={type}
          className={errors[name] ? 'InputError' : ''}
          value={value || ''}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={t(placeholder)}
          maxLength={maxLength}
          disabled={disabled}
        />
        {errors[name] && <div className={`${styles.errorWrapper} FieldError`}>{t(errors[name])}</div>}
        {Supplement && <Supplement value={formState[name] || ''} />}
      </div>
    </div>
  );
};

export default AdminField;




