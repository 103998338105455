import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  TX_INV_CATEGORY_ADD,
  TX_INV_CATEGORY_NO_CATEGORIES,
} from '../../../../constants/strings';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductCategoryEntry from './ProductCategoryEntry';

import styles from './_styles/ProductCategoryManager.module.scss';

export const ProductCategoryManager = (props) => {
  
  // Props
  const {
    makeClean, 
    product, 
    productPending, 
    setRequestError, 
  } = props;


  // State
  const [adding, setAdding] = useState(false);


  // Internationalization
  const { t } = useTranslation();


  // Effects
  // None yet


  // Methods
  const getCategories = () => {
    return product?.categories || [];
  };

  const categorySaved = () => {
    if(makeClean) {
      makeClean();
    }
  };

  const openAdd = () => {
    setAdding(true);
  };

  const closeAdd = () => {
    setAdding(false);
  };

  return (
    <div className={styles.ProductCategoryManager}>
      <div className={styles.managerWrapper}>
        {!product && productPending ? 
          <div className={styles.loadingWrapper}>
            <div className={styles.loadingIconWrapper}>
              <LoadingIcon />
            </div>
          </div> :
          <div className={styles.listWrapper}>
            {getCategories().length > 0 ?
              <div className={styles.list}>
                {getCategories().map((category, i) => {
                  return <ProductCategoryEntry 
                            key={category.publicUuid} 
                            completeSave={categorySaved}
                            product={product}
                            productPending={productPending}
                            productCategory={category}
                            setRequestError={setRequestError} />
                })}
              </div> :
              <>
                {!adding ?
                  <div className={styles.listNoResults}>
                    <div className={styles.listNoResultsCopy}>{t(TX_INV_CATEGORY_NO_CATEGORIES)}</div>
                  </div> :
                  null
                }
              </>
            }
            <div className={styles.addWrapper}>
              {adding ?
                <div className={styles.addOpen}>
                  <ProductCategoryEntry 
                    closeMethod={closeAdd}
                    completeSave={categorySaved}
                    product={product}
                    productPending={productPending}
                    productCategory={null}
                    setRequestError={setRequestError} />
                </div> :
                <div className={styles.addClosed}>
                  <div className={styles.addLink} onClick={openAdd}>{t(TX_INV_CATEGORY_ADD)}</div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ProductCategoryManager;

