

// URL components

export const URL_SEPARATOR = `/`;

export const URL_NS_ADMIN = `admin`;
export const URL_NS_BUYLIST = `buylist`;
export const URL_NS_SHOP = `shop`;
export const URL_NS_SELL = `sell`;
export const URL_NS_PLAY = `play`;


// URL GET parameters

export const URL_PARAM_DAY = `day`;
export const URL_PARAM_MONTH = `month`;
export const URL_PARAM_RECURRING = `recurring`;
export const URL_PARAM_VIEW = `view`;
export const URL_PARAM_YEAR = `year`;


// Home URL

export const URL_HOME = `/`;


// Admin URLs

export const URL_ADMIN_CUSTOMERS = `/${URL_NS_ADMIN}/customers`;
export const URL_ADMIN_CUSTOMERS_ADD = `/${URL_NS_ADMIN}/customers/add`;
export const URL_ADMIN_CUSTOMERS_VIEW = `/${URL_NS_ADMIN}/customers/{{publicUuid}}`;
export const URL_ADMIN_CUSTOMERS_BULK_UPLOAD = `/${URL_NS_ADMIN}/customers/bulk-uploads`;
export const URL_ADMIN_CUSTOMERS_BULK_UPLOAD_CREATE = `/${URL_NS_ADMIN}/customers/bulk-upload/create`;
export const URL_ADMIN_CUSTOMERS_BULK_UPLOAD_VIEW = `/${URL_NS_ADMIN}/customers/bulk-upload/{{publicUuid}}`;

export const URL_ADMIN_DASHBOARD = `/${URL_NS_ADMIN}`;

export const URL_ADMIN_EVENTS = `/${URL_NS_ADMIN}/events`;
export const URL_ADMIN_EVENTS_CREATE = `/${URL_NS_ADMIN}/events/create`;
export const URL_ADMIN_EVENTS_SCHEDULE_VIEW = `/${URL_NS_ADMIN}/events/{{date}}/{{publicUuid}}`;
export const URL_ADMIN_EVENTS_VIEW = `/${URL_NS_ADMIN}/events/{{publicUuid}}`;

export const URL_ADMIN_INVENTORY = `/${URL_NS_ADMIN}/catalog`;
export const URL_ADMIN_INVENTORY_ADD = `/${URL_NS_ADMIN}/catalog/add`;
export const URL_ADMIN_INVENTORY_VIEW = `/${URL_NS_ADMIN}/catalog/{{productLine}}/view/{{permalink}}`;
export const URL_ADMIN_INVENTORY_BULK_UPLOAD = `/${URL_NS_ADMIN}/catalog/bulk-uploads`;
export const URL_ADMIN_INVENTORY_BULK_UPLOAD_CREATE = `/${URL_NS_ADMIN}/catalog/bulk-upload/create`;
export const URL_ADMIN_INVENTORY_BULK_UPLOAD_VIEW = `/${URL_NS_ADMIN}/catalog/bulk-upload/{{publicUuid}}`;
export const URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES = `/${URL_NS_ADMIN}/catalog/categories`;
export const URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_ADD = `/${URL_NS_ADMIN}/catalog/categories/add`;
export const URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES_VIEW = `/${URL_NS_ADMIN}/catalog/categories/{{publicUuid}}`;
export const URL_ADMIN_INVENTORY_PRODUCT_LINES = `/${URL_NS_ADMIN}/catalog/product-lines`;
export const URL_ADMIN_INVENTORY_PRODUCT_LINES_ADD = `/${URL_NS_ADMIN}/catalog/product-lines/add`;
export const URL_ADMIN_INVENTORY_PRODUCT_LINES_VIEW = `/${URL_NS_ADMIN}/catalog/product-lines/view/{{permalink}}`;

export const URL_ADMIN_ORDERS = `/${URL_NS_ADMIN}/orders`;
export const URL_ADMIN_ORDERS_BUYLIST = `/${URL_NS_ADMIN}/orders/buylist`;
export const URL_ADMIN_ORDERS_BUYLIST_CARTS = `/${URL_NS_ADMIN}/orders/buylist-carts`;
export const URL_ADMIN_ORDERS_BUYLIST_CARTS_VIEW = `/${URL_NS_ADMIN}/orders/buylist-carts/{{publicUuid}}`;
export const URL_ADMIN_ORDERS_CARTS = `/${URL_NS_ADMIN}/orders/carts`;
export const URL_ADMIN_ORDERS_CARTS_VIEW = `/${URL_NS_ADMIN}/orders/carts/{{publicUuid}}`;
export const URL_ADMIN_ORDERS_OPEN = `/${URL_NS_ADMIN}/orders/open`;
export const URL_ADMIN_ORDERS_PREORDER = `/${URL_NS_ADMIN}/orders/preorder`;
export const URL_ADMIN_ORDERS_PURCHASE = `/${URL_NS_ADMIN}/orders/purchase`;
export const URL_ADMIN_ORDERS_ORDER_VIEW = `/${URL_NS_ADMIN}/orders/{{publicUuid}}`;
export const URL_ADMIN_ORDERS_STORE_CREDIT = `/${URL_NS_ADMIN}/orders/store-credit`;

export const URL_ADMIN_MARKETING = `/${URL_NS_ADMIN}/marketing`;
export const URL_ADMIN_MARKETING_COUPONS = `/${URL_NS_ADMIN}/marketing/coupons`;
export const URL_ADMIN_MARKETING_COUPONS_ADD = `/${URL_NS_ADMIN}/marketing/coupons/add`;
export const URL_ADMIN_MARKETING_COUPONS_VIEW = `/${URL_NS_ADMIN}/marketing/coupons/{{code}}`;
export const URL_ADMIN_MARKETING_NOTICES = `/${URL_NS_ADMIN}/marketing/notices`;

export const URL_ADMIN_SETTINGS = `/${URL_NS_ADMIN}/settings`;
export const URL_ADMIN_SETTINGS_ADMIN_USERS = `/${URL_NS_ADMIN}/settings/admin-users`;
export const URL_ADMIN_SETTINGS_ADMIN_USERS_ADD = `/${URL_NS_ADMIN}/settings/admin-users/add`;
export const URL_ADMIN_SETTINGS_ADMIN_USERS_EDIT = `/${URL_NS_ADMIN}/settings/admin-users/edit/{{public_uuid}}`;
export const URL_ADMIN_SETTINGS_INTEGRATIONS = `/${URL_NS_ADMIN}/settings/integrations`;
export const URL_ADMIN_SETTINGS_INTEGRATIONS_ADD = `/${URL_NS_ADMIN}/settings/integrations/add`;
export const URL_ADMIN_SETTINGS_INTEGRATIONS_OAUTH_REDIRECT = `/${URL_NS_ADMIN}/settings/integrations/oauth/{{key}}`;
export const URL_ADMIN_SETTINGS_INTEGRATIONS_VIEW = `/${URL_NS_ADMIN}/settings/integrations/{{key}}`;
export const URL_ADMIN_SETTINGS_PAGES = `/${URL_NS_ADMIN}/settings/pages`;
export const URL_ADMIN_SETTINGS_PAGES_VIEW = `/${URL_NS_ADMIN}/settings/pages/{{key}}`;
export const URL_ADMIN_SETTINGS_PAYMENT_METHODS = `/${URL_NS_ADMIN}/settings/payment-methods`;
export const URL_ADMIN_SETTINGS_PAYMENT_METHODS_ADD = `/${URL_NS_ADMIN}/settings/payment-methods/add`;
export const URL_ADMIN_SETTINGS_PAYMENT_METHODS_SQUARE_OAUTH = `/${URL_NS_ADMIN}/settings/payment-methods/square/oauth`;
export const URL_ADMIN_SETTINGS_PAYMENT_METHODS_VIEW = `/${URL_NS_ADMIN}/settings/payment-methods/{{public_uuid}}`;
export const URL_ADMIN_SETTINGS_SHIPPING_METHODS = `/${URL_NS_ADMIN}/settings/shipping-methods`;
export const URL_ADMIN_SETTINGS_SHIPPING_METHODS_ADD = `/${URL_NS_ADMIN}/settings/shipping-methods/add`;
export const URL_ADMIN_SETTINGS_SHIPPING_METHODS_VIEW = `/${URL_NS_ADMIN}/settings/shipping-methods/{{publicUuid}}`;
export const URL_ADMIN_SETTINGS_STORE_SOCIAL_MEDIA = `/${URL_NS_ADMIN}/settings/social-media`;
export const URL_ADMIN_SETTINGS_STORE_SETTINGS = `/${URL_NS_ADMIN}/settings/store`;


// Private URLs

export const URL_ACCOUNT = `/account`;
export const URL_ACCOUNT_ORDERS = `/account/orders`;
export const URL_ACCOUNT_ORDERS_SINGLE = `/account/orders/{{publicUuid}}`;
export const URL_ACCOUNT_ORDERS_BUYLIST = `/account/buylist`;
export const URL_ACCOUNT_ORDERS_BUYLIST_SINGLE = `/account/buylist/{{publicUuid}}`;
export const URL_ACCOUNT_STORE_CREDIT = `/account/store-credit`;


// Auth URLs

export const URL_LOGIN = `/login`;
export const URL_LOGIN_REAUTH = `/login/reauth`;
export const URL_REGISTER = `/create-account`;
export const URL_FORGOT_PASSWORD = `/forgot-password`;
export const URL_RESET_PASSWORD = `/reset-password/{{token}}`;
export const URL_SET_PASSWORD = `/set-password`;


// Checkout URLs

export const URL_CART = `/cart`;
export const URL_BUYLIST_CART = `/${URL_NS_BUYLIST}/cart`;
export const URL_CHECKOUT = `/checkout`;
export const URL_BUYLIST_CHECKOUT = `/${URL_NS_BUYLIST}/checkout`;
export const URL_CHECKOUT_TRANSBANK_COMPLETE = `/checkout/tb/{{publicUuid}}`;
export const URL_SUCCESS = `/thank-you`;


// General (public) URLs

export const URL_CONTACT_US = `/contact-us`;
export const URL_PRIVACY_POLICY = `/privacy-policy`;
export const URL_RETURN_POLICY = `/return-policy`;
export const URL_TERMS = `/terms`;


// Buy (public) URLs

export const URL_BUY_GENERAL = `/${URL_NS_SHOP}`;
export const URL_BUY_PRODUCT_LINE = `/${URL_NS_SHOP}/{{productLine}}`;
export const URL_BUY_PRODUCT_PAGE = `/${URL_NS_SHOP}/{{productLine}}/{{permalink}}`;


// Sell (event) URLs

export const URL_SELL_GENERAL = `/${URL_NS_SELL}`;
export const URL_SELL_PRODUCT_LINE = `/${URL_NS_SELL}/{{productLine}}`;
export const URL_SELL_PRODUCT_PAGE = `/${URL_NS_SELL}/{{productLine}}/{{permalink}}`;


// Play (event) URLs

export const URL_PLAY_GENERAL = `/${URL_NS_PLAY}`;
export const URL_PLAY_PRODUCT_LINE = `/${URL_NS_PLAY}/{{productLine}}`;


// Maintenance URLs

export const URL_MAINTENANCE = `/maintenance`;
export const URL_MAINTENANCE_LOGIN = `/maintenance/login`;









