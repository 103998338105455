import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';
import { 
  getMediaAlt, 
  getPriceRangeFromInventory, 
  getProductLanguage, 
  getOrderedMedia, 
} from '../../../../utils/product';

import StaticImage from '../../../Image/StaticImage';
import GeneralThumbnailAdd from './GeneralThumbnailAdd';

let allActionCreators = Object.assign({});

export class GeneralThumbnail extends Component {

  constructor(props) {
    super(props);

    this.state = {
      thumbnailLoaded: false, 
    };
  }

  thumbLoaded() {
    this.setState({ thumbnailLoaded: true });
  }

  getUserLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getThumbImage() {
    if(!this.props.productObj) { return null; }

    // Return specialized image if necessary
    if(this.props.productObj.foreignModel && !this.props.productObj.foreignModel.useProductImages) {
      const loadDelay = {
        loadDelay: this.props.loadDelay,
        onLoadLow: this.thumbLoaded.bind(this),
      }
      return <div className='gtImageMagicWrapper'>{this.props.productObj.foreignModel.getPrimaryImage(loadDelay)}</div>;
    }

    const productMedia = getOrderedMedia(this.props.productObj);

    if(productMedia.length === 0) {

      return <StaticImage 
                imgObj={IMG_GENERIC_PRODUCT}
                onImgLoad={this.thumbLoaded.bind(this)} />;
    }

    return <StaticImage 
              imgObj={{
                src: productMedia[0].src, 
                alt: getMediaAlt(this.props.productObj, productMedia[0]), 
                noTranslate: true,
              }}
              onImgLoad={this.thumbLoaded.bind(this)} />;
  }

  getThumbTitle() {
    if(!this.props.productObj) { return null; }
    return this.props.productObj.nameWithTags;
  }

  getThumbProductLine() {
    if(!this.props.productObj || !this.props.productObj.productLine) { return null; }
    return this.props.productObj.productLine.name;
  }

  getThumbSetName() {
    if(!this.props.productObj) { return null; }

    return this.props.productObj.setName || null;
  }

  getPriceString() {
    if(!this.props.productObj) { return null; }

    const priceConfig = {
      addTags: true, 
      available: !this.props.isBuylist, 
      buylist: this.props.isBuylist,
      language: this.getUserLanguage(),
    };

    return getPriceRangeFromInventory(this.props.productObj.inventory, priceConfig);
  }

  getProductLanguageCode() {
    if(!this.props.productObj) {
      return '';
    }

    const lang = getProductLanguage(this.props.productObj);

    if(lang && lang.code) {
      return lang.code;
    }

    return '';
  }

  render() {

    const {t} = this.props;

    return <Link 
            className={this.state.thumbnailLoaded ? 'GeneralThumbnail' : 'GeneralThumbnail unloaded'} 
            to={{
              pathname: this.props.productObj.getProductPageUrl(this.props.isBuylist),
              state: { allowBack: true },
            }}>
      <div className='gtImageWrapper'>
        {this.getThumbImage()}
      </div>
      <div className='gtTitleSection'>
        <div className={'gtTitleWrapper'} lang={this.getProductLanguageCode()}>
          {this.getThumbTitle()}
        </div>
        <div className={'gtProductLineWrapper EllipsisElement'}>
          {this.getThumbProductLine()}
        </div>
        <div className={'gtSetWrapper EllipsisElement'}>
          {this.getThumbSetName()}
        </div>
      </div>
      <div className='gtTagWrapper'>
        {this.props.productObj && !this.props.productObj.isReleased ?
          <div className='tagWrapper'>
            <div className='tagLabel'>{t(tx.TX_PREORDER)}</div>
          </div> :
          null
        }
        {this.props.isBuylist ?
          <div className='tagWrapper'>
            <div className='tagLabel'>{t(tx.TX_BUYLIST)}</div>
          </div> :
          null
        }
      </div>
      <div className='gtInventorySection'>
        <div className={'gtPriceWrapper'}>
          <div className='gtPriceElement' dangerouslySetInnerHTML={{ __html: this.getPriceString() }} />
        </div>
        {this.props.isBuylist ?
          <div className={'gtAddWrapper'}>
            <GeneralThumbnailAdd
              config={this.props.config}
              isBuylist={this.props.isBuylist}
              productObj={this.props.productObj} />
          </div> :
          <div className={'gtQtyWrapper'}>
            <div className={'gtQty'}>
              <div className={'gtQtyLabel'}>{t(tx.TX_QUANTITY_ABBR)}</div>
              <div className={'gtQtyValue'}>{this.props.productObj ? this.props.productObj.totalQuantity : '0'}</div>
              
            </div>
          </div>
        }
      </div>
    </Link>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(GeneralThumbnail));