import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { 
  TX_CANCEL, 
  TX_NAME, 
  TX_PERMALINK, 
  TX_PLACEHOLDER_CATEGORY_NAME,
  TX_PLACEHOLDER_CATEGORY_PERMALINK,
  TX_SAVE,
} from '../../../constants/strings';

import { ProductCategory } from '../../../models/products';

import { productCategoryUpdate } from '../../../actions/product';

import { useSaveContext } from '../../../context/SaveProvider';

import useFormValidationCategoryName from '../../../hooks/form-validation/useFormValidationCategoryName';
import useFormValidationPermalink from '../../../hooks/form-validation/useFormValidationPermalink';

import { formatServerError } from '../../../utils/formatting';

import ActionButton from '../../Buttons/ActionButton';
import AdminField from '../../Form/fields/AdminField';
import AdminPageSectionActions from '../../Admin/blocks/page/AdminPageSectionActions';
import CategoryPermalinkSupplement from '../../Form/fields/supplements/CategoryPermalinkSupplement';
import FormWrapper from '../../Form/FormWrapper';
import TextButton from '../../Buttons/TextButton';

import styles from './_styles/CategoryDetailsEdit.module.scss';

export const CategoryDetailsEdit = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // Props
  const {
    category,
    loading,
    refresh,
    toggleEdit, // Injected by AdminPageSectionToggle
  } = props;

  // Context
  const { registerSaveCallback } = useSaveContext();

  // Constants
  const initialValues = { 
    name: category.name, 
    permalink: category.permalink, 
  };

  // State
  const [syncPermalink, setSyncPermalink] = useState(true);
  const [requestError, setRequestError] = useState(null);
  const [requestPending, setRequestPending] = useState(false);

  // Form Hooks
  const nameHook = useFormValidationCategoryName(category.name);
  const permalinkHook = useFormValidationPermalink(category.permalink);

  // Refs
  const formRef = useRef();
  const isSubmitSuccessRef = useRef(null);

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const handleSubmit = async (formData, config = {}) => {

    let isSuccess = true;
    isSubmitSuccessRef.current = null;

    setRequestPending(true);
    setRequestError(null);

    const categoryObj = new ProductCategory(category);
    categoryObj.name = formData.name;
    categoryObj.permalink = formData.permalink;

    await dispatch(productCategoryUpdate(categoryObj.getApiData()))
      .catch((errResp) => {
        isSuccess = false;
        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);

    if(isSuccess) {
      await refresh();
      toggleEdit();
    }

    isSubmitSuccessRef.current = isSuccess;
  };

  // Effects
  useEffect(() => {
    if(syncPermalink) {
      permalinkHook.setValue(nameHook.value);
    }
  }, [nameHook.value, syncPermalink, permalinkHook]);

  useEffect(() => {

    const save = async () => {
      if (formRef.current) {

        return new Promise((resolve, reject) => {
          
          // One time submit listener
          const onFormSubmit = (e) => {

            // Check if handleSubmit is complete
            const checkCompletion = setInterval(() => {
              if(isSubmitSuccessRef.current !== null) {
                clearInterval(checkCompletion);
                if(isSubmitSuccessRef.current) {
                  resolve();
                } else {
                  reject();
                }
              }
            }, 50);
          };

          formRef.current.addEventListener('submit', onFormSubmit, { once: true });

          // Trigger the form's submit event
          formRef.current.dispatchEvent(
            new Event('submit', { cancelable: true, bubbles: true })
          );
        });
      }
    };

    const unregister = registerSaveCallback(save);

    // Cleanup: Unregister the callback when the component unmounts
    return unregister;
  }, [registerSaveCallback]);

  // Render
  return (
    <div className={styles.CategoryDetailsEdit}>

      <FormWrapper 
        error={requestError}
        formRef={formRef}
        initialValues={initialValues} 
        onSubmit={handleSubmit}>

        <div className={styles.formInputWrapper}>

          <AdminField
            name='name'
            type='text'
            label={TX_NAME}
            placeholder={TX_PLACEHOLDER_CATEGORY_NAME}
            required={true}
            useCustomHook={nameHook} />

          <div style={{ display: 'none' }}>
            {/* Hidden because we don't use permalink on the frontend yet; added here to work with FormProvider */}
            <AdminField
              name='permalink'
              type='text'
              label={TX_PERMALINK}
              maxLength={32}
              placeholder={TX_PLACEHOLDER_CATEGORY_PERMALINK}
              required={true}
              supplement={CategoryPermalinkSupplement}
              useCustomHook={{
                ...permalinkHook,
                onChange: (e) => {
                  permalinkHook.onChange(e);
                  setSyncPermalink(false);
                },
              }} />
          </div>

        </div>

        <AdminPageSectionActions>

          <TextButton
            disabled={loading || requestPending}
            onClick={toggleEdit}>
            {t(TX_CANCEL)}
          </TextButton>

          <ActionButton
            adminTheme={true}
            type='submit'
            disabled={loading || requestPending}>
            {t(TX_SAVE)}
          </ActionButton>

        </AdminPageSectionActions>

      </FormWrapper>

    </div>
  );
};

export default CategoryDetailsEdit;




