import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TX_BACK,
  TX_CATALOG,
  TX_CATEGORIES,
  TX_DELETE,
  TX_DELETE_PROMPT_COPY,
  TX_DELETE_PROMPT_TITLE,
  TX_INV_CATEGORY_DETAILS,
  TX_VIEW,
} from '../../constants/strings';
import { 
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES, 
} from '../../constants/urls';

import { 
  productCategoryDelete,
  productCategoryFetchSingle, 
} from '../../actions/product';

import { useSaveContext } from '../../context/SaveProvider';

import { formatServerError } from '../../utils/formatting';

import ActionButton from '../Buttons/ActionButton';
import AdminPageSection from '../Admin/blocks/page/AdminPageSection';
import AdminTitle from '../Admin/AdminTitle';
import CategoryDetailsEdit from './blocks/CategoryDetailsEdit';
import CategoryDetailsView from './blocks/CategoryDetailsView';
import Confirm from '../Popups/Confirm';
import SavePrompt from '../Popups/SavePrompt';
import WarningButton from '../Buttons/WarningButton';

import adminStyles from '../Admin/_styles/AdminPage.module.scss';
import styles from './_styles/ViewCategory.module.scss';

export const ViewCategory = (props) => {
  
  // Dispatch
  const dispatch = useDispatch();

  // History
  const history = useHistory();

  // Params
  const { categoryUUID } = useParams();

  // Internationalization
  const { t } = useTranslation();

  // Context
  const { triggerSave } = useSaveContext();

  // State
  const [category, setCategory] = useState(null);
  const [deletePromptOpen, setDeletePromptOpen] = useState(null);
  const [editingCount, setEditingCount] = useState(0);
  const [lastBlock, setLastBlock] = useState(null);
  const [requestError, setRequestError] = useState(null);
  const [requestPending, setRequestPending] = useState(false);
  const [saveOpen, setSaveOpen] = useState(false);

  // Refs
  const controllerRef = useRef(null);
  const unblockRef = useRef(null);

  // Methods
  const goBack = () => {
    history.push(URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES);
  };

  const fetchCategory = useCallback(async () => {
  
    setRequestPending(true);
    setRequestError(null);

    // Cancel the previous request if still active
    if(controllerRef.current) {
      controllerRef.current.abort();
    }

    const controller = new AbortController();
    controllerRef.current = controller;

    const resp = await dispatch(productCategoryFetchSingle(categoryUUID, controller.signal))
      .catch((errResp) => {

        if(controller.signal.aborted) { return null; }

        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);
    setCategory(resp || null);

  }, [ categoryUUID, dispatch ]);

  const handleDelete = async () => {

    let isSuccess = true;

    setRequestPending(true);
    setRequestError(null);

    await dispatch(productCategoryDelete(categoryUUID))
      .catch((errResp) => {
        isSuccess = false;
        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);

    if(isSuccess) {
      goBack();
    }
  };

  const toggleDeletePrompt = () => {
    setDeletePromptOpen(!deletePromptOpen);
  };

  const updateEditCount = (delta) => {
    setEditingCount(editingCount + delta);
  };

  const saveAll = async () => {
    
    let success = true;

    await triggerSave()
      .catch((err) => {
        success = false;
      });

    if(success) {
      if(lastBlock?.location) {
        history.push(lastBlock.location.pathname + lastBlock.location.search);
      } else {
        history.push(URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES);
      }
    }
  };

  const toggleSavePrompt = useCallback(() => {
    setSaveOpen(!saveOpen);
  }, [ saveOpen ]);

  const leaveWithoutSave = () => {

    // Unblock nav
    unblockRef.current();

    // Navigate
    if(lastBlock?.location) {
      history.push(lastBlock.location.pathname + lastBlock.location.search);
    } else {
      history.goBack();
    }
  };

  // Effects
  useEffect(() => {
    fetchCategory();
  }, [ fetchCategory ]);

  useEffect(() => {

    // Block navigation
    unblockRef.current = history.block((location, action) => {
      
      if(!editingCount) {
        return true;
      }

      setLastBlock({ location, action });
      toggleSavePrompt();
      return false;
    });

    return () => {
      // Clean up on component unmount
      if (unblockRef.current) {
        unblockRef.current();
      }
    };
  }, [editingCount, history, toggleSavePrompt]);

  // Render
  return (
    <div className={`${styles.ViewCategory} ${adminStyles.AdminPage}`}>
      
      <AdminTitle
        title={TX_VIEW}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: TX_CATALOG,
          },
          {
            url: URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES,
            title: TX_CATEGORIES,
          },
        ]} />

      {requestError && <div className={adminStyles.pageError}>{t(requestError)}</div>}

      <div className={adminStyles.pageBody}>
        <div className={styles.blocksWrapper}>

          <AdminPageSection 
            collapsible={false}
            loading={requestPending}
            title={TX_INV_CATEGORY_DETAILS} 
            toggleable={true}
            updateEditCount={updateEditCount}>
            {[
              <CategoryDetailsView 
                key={0}
                category={category}
                loading={requestPending} />,
              <CategoryDetailsEdit 
                key={1}
                category={category}
                loading={requestPending}
                refresh={fetchCategory} />,
            ]}
          </AdminPageSection>

        </div>
      </div>

      <div className={adminStyles.pageActions}>

        <WarningButton
          className={adminStyles.leftAlign}
          type='button'
          onClick={toggleDeletePrompt}
          disabled={!category || editingCount > 0}>
          {t(TX_DELETE)}
        </WarningButton>

        <ActionButton
          adminTheme={true}
          type='button'
          onClick={goBack}>
          {t(TX_BACK)}
        </ActionButton>

      </div>

      <SavePrompt
        open={saveOpen}
        closeMethod={toggleSavePrompt}
        onConfirm={saveAll}
        onCancel={leaveWithoutSave} />

      <Confirm
        title={TX_DELETE_PROMPT_TITLE}
        copy={TX_DELETE_PROMPT_COPY}
        open={deletePromptOpen}
        confirmValue={TX_DELETE}
        closeMethod={toggleDeletePrompt}
        onConfirm={handleDelete} />

    </div>
  );
};

export default ViewCategory;




