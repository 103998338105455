import React from 'react';

import styles from './_styles/AdminPageSectionStatic.module.scss';

const AdminPageSectionStatic = ({ children }) => {

  return (
    <div className={styles.AdminPageSectionStatic}>
      {children}
    </div>
  );
};

export default AdminPageSectionStatic;
