
import * as _ from 'underscore';
import { 
	toDate, 
} from 'date-fns-tz';

import {
	API_KEY_FOREIGN_ID,
	API_KEY_FOREIGN_MODEL,
	API_KEY_FOREIGN_SET_ID,
	API_KEY_FOREIGN_SET_MODEL,
	API_FOREIGN_MODEL_LORCANA_CARD, 
	API_FOREIGN_MODEL_MAGIC_CARD,
	API_FOREIGN_MODEL_POKEMON_CARD,
	API_FOREIGN_MODEL_STARWARS_CARD,
} from '../constants/api';
import { 
	CD_NEW,
	CONDITIONS_ALL,
	CONDITIONS_PRODUCT_PAGE_GENERIC, 
} from '../constants/conditions';
import {
	FV_KEY_BUY_PRICE_MIN,
	FV_KEY_BUY_PRICE_MAX,
	FV_KEY_CONDITION,
	FV_KEY_FINISH,
	FV_KEY_LANGUAGE,
	FV_KEY_SELL_PRICE_MIN,
	FV_KEY_SELL_PRICE_MAX,
} from '../constants/filters';
import { IMG_GENERIC_PRODUCT } from '../constants/images';
import { 
	EN_OBJ,
	LANG_EN,
	LANG_PRODUCT_ALL,
} from '../constants/languages';
// import { STORE_MEDIA_ORIGIN } from '../constants/store';  
import * as tx from '../constants/strings';
import { 
	URL_ADMIN_INVENTORY_VIEW,
	URL_BUY_PRODUCT_PAGE,
	URL_SELL_PRODUCT_PAGE, 
} from '../constants/urls';

// import { EventFormat } from './events';

import { getConditionObjectFromServerResp } from '../utils/condition';
import { 
	getCurrencySymbol,
	getCurrencyMinorCount, 
} from '../utils/currency';
import { getFinishObjectFromServerResp } from '../utils/finish';
import {
	getDateError,
	getDescriptionError,
	getManagedSKUError,
	getSKUError,
	getNameError,
	getPermalinkError,
	getPriceError,
	getQuantityError,
	getWeightError,
	isFormValid,
} from '../utils/form-validation';
import { 
	formatPrice,
	formatServerError, 
	stringFormat, 
} from '../utils/formatting';
import {
	generateCombinations,
	getStoreDefaultWeightUnit,
	isNumeric,
	isVarBool,
	isVarNumber,
	isVarObject,
	isVarString,
	twoDigitInt,
} from '../utils/general';
import { getLanguageObjectFromServerResp } from '../utils/language';
import {
	convertWeightBetweenUnits,
	convertWeightToG,
	getWeightUnitFromKey,
} from '../utils/measurements';
import { getPrintingObjectFromServerResp } from '../utils/printings';
// import { getOrderedInventory } from '../utils/product';


import StaticImage from '../components/Image/StaticImage';


export class Product {

	constructor(props) {

		if(!props) { props = {}; }

		const productLineObj = props.productLine || props.product_line || {};
		const weightUnitValue = props.weightUnit || props.weight_unit || '';
		const releaseDateValue = props.releaseDate || props.release_date || null;

		const attributeArray = props.attributes || [];
		const categoryArray = props.categories || [];
		const inventoryArray = props.inventory || [];
		const mediaArray = props.media || [];
		const tagArray = props.tags || [];

		this.id = props.id || '';
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.title = props.title || '';
		this.sku = props.sku || '';
		this.permalink = props.permalink || '';
		this.description = props.description || '';
		this.shortDescription = props.shortDescription || props.short_description || '';
		this.weight = parseFloat(props.weight) || 0;
		this.weightUnit = isVarString(weightUnitValue) ? getWeightUnitFromKey(weightUnitValue) : weightUnitValue;
		this.weightG = convertWeightToG(this.weight, this.weightUnit);

		this.releaseDate = null;
		if(releaseDateValue) {
			this.releaseDate = releaseDateValue instanceof Date ? releaseDateValue : toDate(releaseDateValue);
		}

		this.isBuylist = props.isBuylist || props.is_buylist || false;
		this.isEnabled = props.isEnabled || props.is_enabled || false;

		this.productLine = new ProductLine(productLineObj);

		// Switches foreign_model which is string in server and model on frontend
		this.foreignModelCode = props.foreignModelCode || props.foreign_model || '';
		if(!this.foreignModelCode && props.foreignModel && props.foreignModel.foreignModelCode) {
			this.foreignModelCode = props.foreignModel.foreignModelCode;
		}

		const foreignModelObj = props.foreignModel || props.foreign_obj || null;
		const modelClass = Product.getForeignModel(this.foreignModelCode);

		this.foreignModel = null;		
		if(modelClass && foreignModelObj) {
			this.foreignModel = new modelClass(foreignModelObj);
		}

		// Foreign set reference
		this.foreignSetCode = props.foreignSetCode || props.foreign_set_model || '';
		if(!this.foreignSetCode && props.foreignSet && props.foreignSet.foreignModelCode) {
			this.foreignSetCode = props.foreignSet.foreignModelCode;
		}

		const foreignSetObj = props.foreignSet || props.foreign_set || null;
		const setModelClass = Product.getForeignSetModel(this.foreignSetCode);

		this.foreignSet = null;		
		if(setModelClass && foreignSetObj) {
			this.foreignSet = new setModelClass(foreignSetObj);
		}

		// Shortcuts to foreign models
		this.lorcana = this.foreignModelCode && this.foreignModelCode === API_FOREIGN_MODEL_LORCANA_CARD ? this.foreignModel : null;
		this.magic = this.foreignModelCode && this.foreignModelCode === API_FOREIGN_MODEL_MAGIC_CARD ? this.foreignModel : null;
		this.pokemon = this.foreignModelCode && this.foreignModelCode === API_FOREIGN_MODEL_POKEMON_CARD ? this.foreignModel : null;
		this.starwars = this.foreignModelCode && this.foreignModelCode === API_FOREIGN_MODEL_STARWARS_CARD ? this.foreignModel : null;

		this.languageCode = props.language || props.lang || '';
		this.language = getLanguageObjectFromServerResp(this.languageCode);

		this.inventory = [];
		for(const inv of inventoryArray) {
			this.inventory.push(new Inventory(inv));
		}

		this.media = [];
		for(const med of mediaArray) {
			this.media.push(new ProductMedia(med));
		}

		this.attributes = [];
		for(const attr of attributeArray) {
			this.attributes.push(new ProductAttribute(attr));
		}

		this.categories = [];
		for(const cat of categoryArray) {
			this.categories.push(new ProductCategory(cat));
		}

		this.tags = [];
		for(const tag of tagArray) {
			this.tags.push(new ProductTag(tag));
		}

		const productSetModelClass = Product.getProductSetModel(this.foreignModelCode);
		this.productSet = ProductSet.hasRequiredAttributes(this.attributes) ? new productSetModelClass({ attributes: this.attributes }) : null;
	}

	// Cheater functions to ensure backwards compatibility;
	// Remove when able
	get product_line() { return this.productLine; }
	get is_buylist() { return this.isBuylist; }
	get is_enabled() { return this.isEnabled; }
	get short_description() { return this.shortDescription; }

	get inStock() {
		for(const inv of this.inventory) {
			if(inv.totalQuantity > 0) { return true; }
		}
		return false;
	}

	get isReleased() {
		if(this.releaseDate && this.releaseDate > new Date()) {
			return false;
		}
		return true;
	}

	get localizedName() {
		if(this.isMagic()) {
			return this.magic.localizedName;
		}
		return this.name;
	}

	get setName() {
		if(this.foreignSet) {
			return this.foreignSet.name;
		}
		return this.productSet && this.productSet.name ? this.productSet.name : '';
	}

	get nameWithTags() {

		// We don't use translation since tags are now mostly user-entered
		// The tags are in english when promoted from foreign_model to product (CatalogTags have a translation constant)

		let nameString = this.name;

		for(const tag of this.tags) {
			nameString = `${nameString} (${tag.name})`;
		}
		return nameString;
	}

	static getForeignModel(mod) {

		const { PROD_MAPPING_FOREIGN_MODELS } = require('../constants/product');
		
		const foreignParentModel = ProductForeignModel;

		if(!mod) {
			return foreignParentModel;
		}

		// If prop passed was not a string model lookup key, then return as-is (was probably an already instantiated foreign model)
		if(!isVarString(mod)) {
			return mod;
		}

		return PROD_MAPPING_FOREIGN_MODELS[mod] || null;
	}

	static getForeignSetModel(mod) {

		const { PROD_MAPPING_FOREIGN_MODELS } = require('../constants/product');
		
		if(!mod) {
			return null;
		}

		// If prop passed was not a string model lookup key, then return as-is (was probably an already instantiated foreign model)
		if(!isVarString(mod)) {
			return mod;
		}

		return PROD_MAPPING_FOREIGN_MODELS[mod] || null;
	}

	static getProductSetModel(mod) {
		
		if(!mod) {
			return ProductSet;
		}

		const { PROD_MAPPING_FOREIGN_MODEL_SETS } = require('../constants/product');

		// If prop passed was not a string model lookup key, then return as-is (was probably an already instantiated foreign model)
		if(!isVarString(mod)) {
			return mod;
		}

		return PROD_MAPPING_FOREIGN_MODEL_SETS[mod] || ProductSet;
	}

	getProductPageUrl(isBuylist = false) {
		if(this.permalink && this.productLine.permalink) {
			const baseUrl = isBuylist ? URL_SELL_PRODUCT_PAGE : URL_BUY_PRODUCT_PAGE;
			return stringFormat(baseUrl, {
				productLine: this.productLine.permalink,
				permalink: this.permalink,
			});
	  }
	  return '';
	}

	getAdminUrl() {
		if(this.permalink && this.productLine.permalink) {
			return stringFormat(URL_ADMIN_INVENTORY_VIEW, {
				productLine: this.productLine.permalink,
				permalink: this.permalink,
			});
	  }
	  return '';
	}

	isMagic() {
		return this.magic !== null;
	}

	isPokemon() {
		return this.pokemon !== null;
	}

	hasCondition() {
		return this.foreignModel && this.foreignModel.hasCondition() ? true : false;
	}

	allConditions() {
		return this.foreignModel && this.foreignModel.allConditions ? this.foreignModel.allConditions() : CONDITIONS_PRODUCT_PAGE_GENERIC;
	}

	hasFinish() {
		return this.foreignModel && this.foreignModel.hasFinish() ? true : false;
	}

	allFinishes() {
		return this.foreignModel && this.foreignModel.allFinishes ? this.foreignModel.allFinishes() : [];
	}

	hasPrinting() {
		return this.foreignModel && this.foreignModel.hasPrinting() ? true : false;
	}

	allPrintings() {
		return this.foreignModel && this.foreignModel.allPrintings ? this.foreignModel.allPrintings() : [];
	}

	getPriceRange(config = {}) {
	
		// Config schema
		// addTags: boolean; default false- not used here
		// language: LANG_ constant; default LANG_EN
		// omitSymbol: boolean; default false

		const { PROD_PRICE_CLASS_SYMBOL } = require('../constants/product');

		const addTags = config.addTags ? true : false;
		const language = config.language ? config.language : LANG_EN;
		const omitSymbol = config.omitSymbol ? true : false;

		const symbol = omitSymbol ? '' : getCurrencySymbol();
		
		const invArray = [];
		for(const inv of this.inventory) {
			if(inv.totalQuantity > 0) {
				invArray.push(inv);
			}
		}

		if(invArray.length === 0) {
			if(addTags) {
				return symbol ? `<span class='${PROD_PRICE_CLASS_SYMBOL}'>${symbol}</span> -` : `-`;
			} else {
				return symbol ? `${symbol} -` : `-`;
			}
		}

		let minVal = parseFloat(invArray[0].sellPrice);
		let maxVal = parseFloat(invArray[0].sellPrice);
		for(const inv of invArray) {
			if(parseFloat(inv.sellPrice) < minVal) {
				minVal = parseFloat(inv.sellPrice);
			}
			if(parseFloat(inv.sellPrice) > maxVal) {
				maxVal = parseFloat(inv.sellPrice);
			}
		}
		return maxVal === minVal ? `${formatPrice(maxVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })}` : `${formatPrice(minVal, { addTags: addTags, language: language, omitSymbol: omitSymbol })} — ${formatPrice(maxVal, { addTags: addTags, language: language, omitSymbol: true })}`;
	}

	getMaxPrice(config = {}) {

		// Config schema
		// None yet; just return value
		
		const invArray = [];
		for(const inv of this.inventory) {
			if(inv.totalQuantity > 0) {
				invArray.push(inv);
			}
		}

		if(invArray.length === 0) {
			return 0;
		}

		let maxVal = parseFloat(invArray[0].sellPrice);
		for(const inv of invArray) {
			if(parseFloat(inv.sellPrice) > maxVal) {
				maxVal = parseFloat(inv.sellPrice);
			}
		}
		return maxVal;
	}

	get totalQuantity() {
		
		let invTotal = 0;
		for(const inv of this.inventory) {
			if(parseInt(inv.totalQuantity)) {
				invTotal += parseInt(inv.totalQuantity);
			}
		}
		return invTotal;
	}

	updateInventory(invObj) {
		if(!invObj || !invObj.id) {
			return null;
		}

		let inventoryMatched = false;
		const newInventory = [];
		for(const inv of this.inventory) {
			if(inv.id === invObj.id) {
				newInventory.push(invObj);
				inventoryMatched = true;
			} else {
				newInventory.push(inv);
			}
		}
		if(inventoryMatched === false) {
			newInventory.push(invObj);
		}
		this.inventory = newInventory;
	}

	getTagByValue(tagValue) {
		if(!tagValue) { return null; }

		for(const tag of this.tags) {
			if(tag.name === tagValue.trim()) {
				return tag;
			}
		}
		return null;
	}

	getAttribute(attrKey) {
		for(const attr of this.attributes) {
			if(attr.key === attrKey) {
				return attr;
			}
		}
		return null;
	}

	getAttributeValue(attrKey) {
		const attr = this.getAttribute(attrKey);
		return attr ? attr.value : null;
	}

	getApiData() {

	  	const apiData = {
		    is_enabled: this.isEnabled,
		    sku: this.sku,
		    name: this.name, 
		    description: this.description,
		    product_line_permalink: this.productLine.permalink,
		    permalink: this.permalink,
		    weight: isNumeric(this.weightG) ? Math.round(this.weightG) : null,
		};

		if(this.foreignModel) {
			apiData[API_KEY_FOREIGN_ID] = this.foreignModel.id;
			apiData[API_KEY_FOREIGN_MODEL] = this.foreignModel.foreignModelCode;
		}

		if(this.foreignSet) {
			apiData[API_KEY_FOREIGN_SET_ID] = this.foreignSet.id;
			apiData[API_KEY_FOREIGN_SET_MODEL] = this.foreignSet.foreignModelCode;
		}

		if(this.releaseDate) {
			apiData['release_date'] = `${this.releaseDate.getFullYear()}-${twoDigitInt(this.releaseDate.getMonth() + 1)}-${twoDigitInt(this.releaseDate.getDate())}`;				
		}

		return apiData;
	}

	getEventItemData(config = {}) {

		// Config schema
		// index: integer; index in list, 0-based
		// discount: float; default 0
		// quantity: integer; default 1
		// price: float; product/inventory price, default max price of inventory
		// isBuylist: boolean; default false
		// item_list_id: string; list id that can be traced through analytics
		// item_list_name: string; human-readable name of list, ie Product Gallery

		const itemObject = {
			item_id: this.sku,
			item_name: this.setName ? `${this.name} | ${this.setName}` : this.name,
			// affiliation: '',
			// coupon: '',
			discount: config.discount || 0,
			index: config.index || 0,
			// item_brand: '',
			item_category: this.productLine ? this.productLine.name : '',
			// item_category2: '',
			// item_category3: '',
			// item_category4: '',
			// item_category5: '',
			// item_variant: '',
			// location_id: '',
			price: config.price || this.getMaxPrice(),
			quantity: config.quantity || 1,
			is_buylist: config.isBuylist || false,
		}
		if(config.item_list_id) {
			itemObject['item_list_id'] = config.item_list_id;
		}
		if(config.item_list_name) {
			itemObject['item_list_name'] = config.item_list_name;
		}
		return itemObject;
	}

	isValid() {

		// REQUIRED
		// Product line
		// SKU (unique for store)
		// Name
		// Permalink (unique-ish)

		// OPTIONAL
		// Description

		if(!this.productLine) {
			return false;
		}

		const productErrorObj = {
	    sku: this.productLine.isManaged ? getManagedSKUError(this.sku) : getSKUError(this.sku),
	    name: getNameError(this.name),
	    permalink: getPermalinkError(this.permalink),
	    description: getDescriptionError(this.description, true),
	    weight: getWeightError(this.weightG, true),
	    releaseDate: getDateError(this.weightG, true),
	  };

	  const productValid = isFormValid(productErrorObj);
	  if(!productValid) {
	  	return false;
	  }

	  for(const iv of this.inventory) {
	  	if(!iv.isValid()) {
	  		return false;
	  	}
	  }

	  for(const md of this.media) {
	  	if(!md.isValid()) {
	  		return false;
	  	}
	  }

	  return true;
	}

	get segment() {
		if(!this.productLine) { return null; }

		for(const seg of this.productLine.segments) {
			
			let segmentMatch = true;
			for(const df of seg.definitions) {

				let definitionMatch = false;
				if(df.isProduct) {
					if(this[df.modelKey] === df.modelValue) {
						definitionMatch = true;
					}
				} else if(df.isInventory) {
					for(const inv of this.inventory) {
						if(inv[df.modelKey] === df.modelValue) {
							definitionMatch = true;
							break;
						}
					}
				}

				if(definitionMatch === false) {
					segmentMatch = false;
					break;
				}
			}

			if(segmentMatch) {
				return seg;
			}
		}
		return null;
	}

	inventoryMatchesFilters(filterObj) {

		// Right now, this is treated as an OR; will return true if any inventory matches
		// If we need an AND match in the future, we can pass it as config

		for(const inv of this.inventory) {
			if(inv.matchesFilters(filterObj)) {
				return true;
			}
		}
		return false;
	}

	sortedInventory(configObj = {}) {

		// Config params
		// lang: language code
		// translate: t function or null for no translate
		// filters: filter object; will only return inventory matching filters
		// addBlankLines: should inject blank lines, default false

		const { getOrderedInventory } = require('../utils/product');

		const filters = configObj.filters || null;
		const addBlankLines = configObj.addBlankLines || false;		

		if(addBlankLines && filters) {

			const altSort = [ ...this.inventory ];

			const filterKeys = filters ? Object.keys(filters) : [];

			const subFilters = {};

			// Currently, we only backfill condition, but we can do it with language and finish if needed
			
			const additionKeys = [
				FV_KEY_CONDITION,
			];

			for(const addKey of additionKeys) {
				if(filterKeys.includes(addKey)) {
					subFilters[addKey] = filters[addKey].split(',');
				}
			}

			// The idea here is to use subFilters to generate all combinations, but right now, since there's only one, we do it more linearly ignoring combinations
			for(const fKey in subFilters) {
				for(const fVal of subFilters[fKey]) {
					if(!this.inventoryMatchesFilters({ [fKey]: fVal })) {
						altSort.push(new Inventory({ [fKey]: fVal }));
					}
				}
			}

			if(altSort.length > this.inventory.length) {
				configObj['inventoryAlt'] = altSort;
			}
		}

		return getOrderedInventory(this, configObj);
	}

	inventoryExportLine(inv, configObj = {}) {

		const t = configObj.translate || null;
		const tHeader = false;

		const keySku = t && tHeader ? t(tx.TX_SKU) : 'SKU';
		const keyTcgId = t && tHeader ? t(tx.TX_TCGPLAYER_ID) : 'TCGPlayer Id';
		const keyName = t && tHeader ? t(tx.TX_NAME) : 'Name';
		const keyCollectorNumber = t && tHeader ? t(tx.TX_COLLECTOR_NUMBER): 'Collector Number';
		const keyProductLineName = t && tHeader ? t(tx.TX_PRODUCT_LINE) : 'Product Line';
		const keySetName = t && tHeader ? t(tx.TX_SET) : 'Set';
		const keyPermalink = t && tHeader ? t(tx.TX_PERMALINK) : 'Permalink';
		const keyWeight = t && tHeader ? t(tx.TX_WEIGHT) : 'Weight';
		const keySellPrice = t && tHeader ? t(tx.TX_SELL_PRICE) : 'Sell Price';
		const keyBuyPrice = t && tHeader ? t(tx.TX_BUY_PRICE) : 'Buy Price';
		const keyQuantity = t && tHeader ? t(tx.TX_TOTAL_QUANTITY) : 'Total Quantity';
		const keyAddQuantity = t && tHeader ? t(tx.TX_ADD_NOUN, { noun: t(tx.TX_QUANTITY) }) : 'Add Quantity';
		const keyCondition = t && tHeader ? t(tx.TX_CONDITION) : 'Condition';
		const keyLanguage = t && tHeader ? t(tx.TX_LANGUAGE) : 'Language';
		const keyFinish = t && tHeader ? t(tx.TX_FILTER_FINISH) : 'Finish';
		const keyPrinting = t && tHeader ? t(tx.TX_PRINTING) : 'Printing';

		if(inv.isBlankPlaceholder) {

			return {
				[keySku]: this.sku,
				[keyTcgId]: this.foreignModel && this.foreignModel.tcgplayerId ? this.foreignModel.tcgplayerId : '',
				[keyName]: this.nameWithTags,
				[keyCollectorNumber]: this.foreignModel && this.foreignModel.collectorNumber ? this.foreignModel.collectorNumber : '',
				[keyProductLineName]: this.productLine.name,
				[keySetName]: this.productSet && this.productSet.name ? this.productSet.name : '',
				[keyPermalink]: this.permalink,
				[keyWeight]: this.weight,
				[keySellPrice]: '',
				[keyBuyPrice]: '',
				[keyQuantity]: '',
				[keyAddQuantity]: '', 
				[keyLanguage]: inv.language ? inv.language.nameDefault || inv.language.name : '',
				[keyCondition]: inv.condition ? inv.condition.exportName || inv.condition.key : '',
				[keyFinish]: inv.finish ? inv.finish.exportName || inv.finish.key : '',
				[keyPrinting]: inv.printing ? inv.printing.exportName || inv.printing.key : '',
			};

		} else {

			return {
				[keySku]: this.sku,
				[keyTcgId]: this.foreignModel && this.foreignModel.tcgplayerId ? this.foreignModel.tcgplayerId : '',
				[keyName]: this.nameWithTags,
				[keyCollectorNumber]: this.foreignModel && this.foreignModel.collectorNumber ? this.foreignModel.collectorNumber : '',
				[keyProductLineName]: this.productLine.name,
				[keySetName]: this.productSet && this.productSet.name ? this.productSet.name : '',
				[keyPermalink]: this.permalink,
				[keyWeight]: this.weight,
				[keySellPrice]: inv.sellPrice,
				[keyBuyPrice]: inv.buyPrice,
				[keyQuantity]: inv.totalQuantity || 0,
				[keyAddQuantity]: '', 
				[keyLanguage]: inv.language ? inv.language.nameDefault || inv.language.name : '',
				[keyCondition]: inv.condition ? inv.condition.exportName || inv.condition.key : '',
				[keyFinish]: inv.finish ? inv.finish.exportName || inv.finish.key : '',
				[keyPrinting]: inv.printing ? inv.printing.exportName || inv.printing.key : '',
			};
		}
	}

	inventoryLines(configObj = {}) {

		// Config params
		// lang: language code
		// translate: t function or null for no translate
		// filters: filter object; will only return inventory matching filters
		// addBlankLines: should inject blank lines, default false

		const { getOrderedInventory } = require('../utils/product');

		const filters = configObj.filters || null;
		const addBlankLines = configObj.addBlankLines || false;

		const sortedAndInjected = this.sortedInventory(configObj);

		const lines = [];
		if(addBlankLines === true) {

			if(this.foreignModel && this.foreignModel.exportInventoryDetails) {

				// Add condition/finish/language/printing combinations for foreign models
				const inventoryVariations = {
					language: [ EN_OBJ.code ],
					condition: this.foreignModel.allConditions().map(condition => condition.key),
				};

				if(this.foreignModel.hasFinish()) {
					inventoryVariations['finish'] = this.foreignModel.allFinishes().map(finish => finish.key);
				}

				if(this.foreignModel.hasPrinting()) {
					inventoryVariations['printing'] = this.foreignModel.allPrintings().map(printing => printing.key);
				}

				const inventoryArray = [];
				const combinations = generateCombinations(inventoryVariations);
				for(const combination of combinations) {
					inventoryArray.push(new Inventory(Object.assign({}, combination, { isBlankPlaceholder: true })));
				}

				const inventoryInjected = [];
				for(const blankInv of inventoryArray) {
					let wasInjected = false;
					for(const productInv of this.inventory) {
						if(productInv.sameConfig(blankInv)) {
							inventoryInjected.push(productInv);
							wasInjected = true;
							break;
						}
					}
					if(!wasInjected) {
						inventoryInjected.push(blankInv);
					}
				}

				const sortedInventory = getOrderedInventory(this, { inventoryAlt: inventoryInjected });
				for(const inv of sortedInventory) {

					if(filters && inv.matchesFilters(filters) === false) {
						continue;
					}

					lines.push(this.inventoryExportLine(inv, configObj));
				}

			} else {
				// This is the default product to be added without any inventory data
				// The addBlankLines param specifically refers to add filtered blank lines
				lines.push(this.inventoryExportLine(new Inventory({ isBlankPlaceholder: true }), configObj));
			}

		} else {

			for(const inv of sortedAndInjected) {

				if(filters && inv.matchesFilters(filters) === false) {
					continue;
				}

				lines.push(this.inventoryExportLine(inv, configObj));
			}
		}
		return lines;
	}
}

export class Inventory {

	constructor(props) {

		if(!props) { props = {}; }

	  	this.id = props.id || null;

	  	this.buyPrice = parseFloat(props.buyPrice) || parseFloat(props.buy_price) || null;
	  	this.reserveAmazon = props.reserveAmazon || props.reserve_amazon || props.reserve_quantity_amazon || '';
	  	this.reserveEbay = props.reserveEbay || props.reserve_ebay || props.reserve_quantity_ebay || '';
	  	this.sellPrice = parseFloat(props.sellPrice) || parseFloat(props.sell_price) || null;
	  	this.targetMin = props.targetMin || props.target_min || props.min_quantity || null;
	  	this.targetMax = props.targetMax || props.target_max || props.max_quantity || null;
	  	this.totalQuantity = parseInt(props.totalQuantity) || parseInt(props.total_quantity) || 0;

	  	// Switches foreign_model which is string in server and model on frontend
		this.foreignModelCode = props.foreignModelCode || props.foreign_model || '';
		if(!this.foreignModelCode && props.foreignModel && props.foreignModel.foreignModelCode) {
			this.foreignModelCode = props.foreignModel.foreignModelCode;
		}

		const foreignModelObj = props.foreignModel || props.foreign_obj || null;
		const modelClass = Product.getForeignModel(this.foreignModelCode);

		this.foreignModel = null;		
		if(modelClass && foreignModelObj) {
			this.foreignModel = new modelClass(foreignModelObj);
		}

	  	this.condition = isVarObject(props.condition) ? props.condition : getConditionObjectFromServerResp(props.condition || null);
	  	this.finish = isVarObject(props.finish) ? props.finish : getFinishObjectFromServerResp(props.finish || null);
	  	this.language = isVarObject(props.language) ? props.language : getLanguageObjectFromServerResp(props.language || null);
	  	this.printing = isVarObject(props.printing) ? props.printing : getPrintingObjectFromServerResp(props.printing || null);

	  	this.isBlankPlaceholder = props.isBlankPlaceholder || false;

	  	// Attribute booleans
	  	if(isVarBool(props.isSealed)) {
	  		this.isSealed = props.isSealed;
	  	} else if(isVarBool(props.is_sealed)) {
	  		this.isSealed = props.is_sealed;
	  	} else {
	  		this.isSealed = true;
	  	}

	  	if(isVarBool(props.isBuylist)) {
	  		this.isBuylist = props.isBuylist;
	  	} else if(isVarBool(props.is_buylist)) {
	  		this.isBuylist = props.is_buylist;
	  	} else {
	  		this.isBuylist = false;
	  	}

	  	if(isVarBool(props.automatedPricing)) {
	  		this.automatedPricing = props.automatedPricing;
	  	} else if(isVarBool(props.automated_pricing)) {
	  		this.automatedPricing = props.automated_pricing;
	  	} else {
	  		this.automatedPricing = false;
	  	}
	}

	static uniqueKeys() {
		return [
			'language',
			'condition',
			'finish',
			'printing',
		];
	}

	sameConfig(invObj) {
		// Determines if the passed invObj is the same configuration as the model;
		// Use to prevent adding redundant inventory to the same product
		// TODO: should probably use a term more specific than "config", it's too general.  Some word the indicates that inv is "unique" within the scope of the product

		for(const key of Inventory.uniqueKeys()) {
			if(!_.isEqual(this[key], invObj[key])) {
				return false;
			}
		}
		return true;
	}

	getUpdateApiData(updateData, product) {

		if(!this.id || !product) {
			// These values will throw a backend validation error; that's okay, this should never be called when there is not an id or permalink
			// Backend error provides a more better user experience
			return { 
				pl_permalink: '',
				permalink: '', 
				pk: 0, 
			}
		}

		const apiData = {
			pl_permalink: product.productLine.permalink,
			pk: this.id,
			permalink: product.permalink,
		};
		for(const key in updateData) {
			apiData[key] = updateData[key];
		}
		return apiData;
	}

	getCreateApiData(product) {

		const apiData = {
			sku: product.sku,
			finish: this.finish && this.finish.key ? this.finish.key : null,
			printing: this.printing && this.printing.key ? this.printing.key : null,
			is_sealed: this.isSealed,
			condition: this.isSealed === false ? this.condition.key : CD_NEW.key,
			sell_price: parseFloat(this.sellPrice),
			quantity: parseInt(this.totalQuantity),
		};		

		if(this.targetMax) {
			apiData['max_quantity'] = parseInt(this.targetMax);
		}

		if(this.targetMin) {
			apiData['min_quantity'] = parseInt(this.targetMin);
		}

		if(this.language && this.language.code) {
			apiData['language'] = this.language.code;
		}

		if(this.isBuylist && this.buyPrice) {
			apiData['buy_price'] = parseFloat(this.buyPrice);
			apiData['is_buylist'] = true;
		}

	    const foreignModel = this.foreignModel || product.foreignModel || null;
	    if(foreignModel) {
			apiData['foreign_id'] = foreignModel.id;
			apiData['foreign_model'] = foreignModel.foreignModelCode;
		}

    	return apiData;
	}

	allConditions() {
		// Returns all possible conditions, unfiltered
		// Uses models, unlike method in ProductLine above that relies on permalinks
		if(this.foreignModel) {
			return this.foreignModel.allConditions();
		}
		return CONDITIONS_ALL;
	}

	getFetchApiData(product) {
		if(!this.id || !product) {
			return null;
		}
		return { 
			pl_permalink: product.productLine.permalink,
			permalink: product.permalink, 
			id: this.id, 
		};
	}

	isValid() {

		// Required
		// Price
		// Quantity

		// Optional
		// Condition
		// Min quantity
		// Max quantity
		
		if(!this.isSealed && !this.condition) {
			return false;
		}

		if(this.foreignModel) {

			if(this.foreignModel.hasFinish() && !this.finish) {
				return false;
			}
		}

		let errorObj = {
	    sellPrice: getPriceError(this.sellPrice),
	    buyPrice: this.isBuylist ? getPriceError(this.buyPrice) : '',
	    totalQuantity: getQuantityError(this.totalQuantity),
	    targetMin: getQuantityError(this.targetMin, true),
	    targetMax: getQuantityError(this.targetMax, true),
	  };

	  return isFormValid(errorObj);
	}

	matchesFilters(filterObj = {}) {

		if(!filterObj) { return true; }

		// Should be the same filters used in table.js catalog filters;
		// Only need to test the ones that can vary across inventory
		// We don't check for total quantity since that's an aggragate of inventory records

		const filterKeys = Object.keys(filterObj);

		if(filterKeys.includes(FV_KEY_SELL_PRICE_MIN)) {
			if(parseFloat(filterObj[FV_KEY_SELL_PRICE_MIN]) && this.sellPrice < parseFloat(filterObj[FV_KEY_SELL_PRICE_MIN])) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_SELL_PRICE_MAX)) {
			if(parseFloat(filterObj[FV_KEY_SELL_PRICE_MAX]) && this.sellPrice > parseFloat(filterObj[FV_KEY_SELL_PRICE_MAX])) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_BUY_PRICE_MIN)) {
			if(parseFloat(filterObj[FV_KEY_BUY_PRICE_MIN]) && this.buyPrice < parseFloat(filterObj[FV_KEY_BUY_PRICE_MIN])) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_BUY_PRICE_MAX)) {
			if(parseFloat(filterObj[FV_KEY_BUY_PRICE_MAX]) && this.buyPrice > parseFloat(filterObj[FV_KEY_BUY_PRICE_MAX])) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_LANGUAGE)) {
			const languageCodes = filterObj[FV_KEY_LANGUAGE].split(',');

			if(this.language === null || languageCodes.includes(this.language.code) === false) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_CONDITION)) {
			const conditionKeys = filterObj[FV_KEY_CONDITION].split(',');

			if(this.condition === null || conditionKeys.includes(this.condition.key) === false) {
				return false;
			}
		}

		if(filterKeys.includes(FV_KEY_FINISH)) {
			const finishKeys = filterObj[FV_KEY_FINISH].split(',');

			if(this.finish === null || finishKeys.includes(this.finish.key) === false) {
				return false;
			}
		}

		return true;
	}
}


export class ProductForeignModel {

	// Should mostly be null/stub functions
	// Null functions should be basic documentation when adding another ForeignModel

	// Anything universal/general should also be here

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.name = props.name || '';		
	}

	// Required to be implemented

	get foreignModelCode() {
		return null;
	}	

	get languageObj() {
		return EN_OBJ;
	}

	createProduct() {
		return new Product({});
	}

	primaryImageSrc() {
		return IMG_GENERIC_PRODUCT.src;
	}

	thumbnailImageSrc() {
		return IMG_GENERIC_PRODUCT.src;
	}

	getPrimaryImage(props = {}) {
		return <StaticImage imgObj={IMG_GENERIC_PRODUCT} {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <StaticImage imgObj={IMG_GENERIC_PRODUCT} {...props} />;
	}

	// Optional configuration; can leave defailt

	get componentThumbnailGallery() {
		return null;
	}

	get componentProductPageDetails() {
		return null;
	}

	imageAlt() {
		return this.name;
	}

	hasFinish() {
		return false;
	}

	hasPrinting() {
		return false;
	}

	hasCondition() {
		return this.allConditions().length > 0;
	}

	allConditions() {
		return [];
	}

	get localizedName() {
		return this.name;
	}

	get allowSealed() {
		return true;
	}

	get productPageLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL } = require('../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL;
	}

	get productPageDetailLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL } = require('../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_GENERAL;
	}
}


export class ProductMedia {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.caption = props.caption || '';
		this.key = props.key || '';
		this.order = props.order || 0;
		this.url = props.url || '';

		// Not database params; used for frontend-manipulation
		this.id = props.id || null;
		this.file = props.file || null;
		this.obj = props.obj || null;
	}

	get src() {
		if(this.url && this.url.includes('http')) {
			return this.url;
		}
		return `${process.env.REACT_APP_BUILD_MEDIA_ORIGIN}/${this.key}`;
	}

	isValid() {

		// Required
		// tbd

		// Right now, it's used as a mix of frontend and server resp
		// Until then, return true
		
		return true;
	}
}


export class CatalogTag {

	constructor(props = {}) {

		if(!props) { props = {}; }

		// Can't pass key since they are dependent on product line
		// Primarily a wrapper for the constant to prevent errors if key not found

		this.key = props.key || null;
		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_key || '';
		this.shouldDisplay = props.shouldDisplay || props.is_display || false;
	}
}


export class ProductLine {

	constructor(props = {}) {

		if(!props) { props = {}; }

		const managedLineObj = props.managedLine || props.managed_line || null;
		const displayOrderVal = isVarNumber(props.displayOrder) ? props.displayOrder : (isVarNumber(props.display_order) ? props.display_order : null);

		this.id = props.id || props.pk || null;
		this.name = props.name || '';
		this.permalink = props.permalink || '';
		this.displayOrder = isVarNumber(displayOrderVal) ? parseInt(displayOrderVal) : 99;
		this.managedLineId = props.managedLineId || props.managed_line_id || 0;

		this.hasBuylist = props.hasBuylist || props.has_buylist || false;
		this.hasEvents = props.hasEvents || props.has_events || false;
		
		this.inMenu = props.inMenu || props.in_menu || false;
		this.isEnabled = props.isEnabled || props.is_enabled || false;
		this.isManaged = props.isManaged || props.is_managed || false;
		this.automatedPricing = props.automatedPricing || props.automated_pricing || false;

		this.managedLine = managedLineObj ? new ProductLine(managedLineObj) : null;

		const priceMatricesArray = props.priceMatrices || props.price_matrices || [];
		this.priceMatrices = [];
		for(const pm of priceMatricesArray) {
			this.priceMatrices.push(new ProductLinePricingMatrix(pm));
		}

		const segmentsArray = props.segments || [];
		this.segments = [];
		for(const sg of segmentsArray) {
			this.segments.push(new ProductLineSegment(sg));
		}

		const defaultsArray = props.defaults || [];
		this.defaults = [];
		for(const df of defaultsArray) {
			this.defaults.push(new ProductLineDefault(df));
		}
	}

	get eventFormats() {

		const { EventFormat } = require('./events');

		const { PL_EVENT_FORMATS } = require('../constants/product');

		const formatKeys = Object.keys(PL_EVENT_FORMATS);
		if(formatKeys.includes(this.permalink)) {

			const formatModels = [];
			for(const ft of PL_EVENT_FORMATS[this.permalink]) {
				formatModels.push(new EventFormat(ft))
			}
			return formatModels;
		}
		return [];
	}

	get bulkUploadFormats() {

		const { 
			PL_BULK_UPLOAD_FORMAT_GENERAL,
			PL_BULK_UPLOAD_FORMATS, 
		} = require('../constants/product');

		if(this.permalink && PL_BULK_UPLOAD_FORMATS[this.permalink]) {
			return PL_BULK_UPLOAD_FORMATS[this.permalink];
		}
		return [ PL_BULK_UPLOAD_FORMAT_GENERAL ];
	}

	get languages() {

		const { PL_LANGUAGUES } = require('../constants/product');

		return PL_LANGUAGUES[this.permalink] || LANG_PRODUCT_ALL;
	}

	get placeholderProduct() {

		const { PL_PLACEHOLDER_VALUES } = require('../constants/product');

		if(PL_PLACEHOLDER_VALUES[this.permalink] && PL_PLACEHOLDER_VALUES[this.permalink].productName) {
			return PL_PLACEHOLDER_VALUES[this.permalink].productName;
		}
		return tx.TX_PLACEHOLDER_PRODUCT_NAME;
	}

	get setModel() {

		const { PL_SET_MODELS } = require('../constants/product');

		return PL_SET_MODELS[this.permalink] || ProductSet;
	}

	hasSets() {
		return !!this.isManaged;
	}

	getBulkUploadFormat(formatKey) {
		for(const format of this.bulkUploadFormats) {
			if(formatKey === format.key) {
				return new BulkUploadFormat(format);
			}
		}
		return new BulkUploadFormat();
	}

	getAllConditions(isBuylist = false) {

		if(!this.permalink) { return []; }

		const { PL_CONDITIONS_PRODUCT_PAGE } = require('../constants/product');

		const allConditions = Object.keys(PL_CONDITIONS_PRODUCT_PAGE).includes(this.permalink) ? PL_CONDITIONS_PRODUCT_PAGE[this.permalink] : CONDITIONS_PRODUCT_PAGE_GENERIC;
		
		if(isBuylist) {
			const filteredConditions = [];
			for(const cond of allConditions) {
				if(!cond.buylistExclude) {
					filteredConditions.push(cond);
				}
			}
			return filteredConditions;
		}
		return allConditions;
	}

	getDefaultValue(key, segment = null) {
		
		const { PROD_DEFAULT_KEY_WEIGHT } = require('../constants/product');

		const defaultObj = this.getDefaultObj(key, segment);

		if(defaultObj) {
			switch(key) {
				case PROD_DEFAULT_KEY_WEIGHT:
					return convertWeightBetweenUnits(defaultObj.weight, defaultObj.weightUnit, getStoreDefaultWeightUnit());
				default:
					return null;
			}
		}
		return null;
	}

	getDefaultObj(key, segment = null) {
		
		for(const df of this.defaults) {
			if(segment === null && df.segment === null) {
				return df;
			}
			if(segment && segment.publicUuid === df.segment.publicUuid) {
				return df;
			}
		}
		return null;
	}

	getApiData() {
		const apiData = {
      name: this.name,
      permalink: this.permalink,
      is_managed: this.isManaged,
      managed_line_id: this.managedLineId,
      in_menu: this.inMenu,
      display_order: this.displayOrder,
      has_buylist: this.hasBuylist,
      has_events: this.hasEvents,
      is_enabled: this.isEnabled,
		};
		if(this.id && this.id > 0) {
			apiData['pk'] = this.id;
		}
		return apiData;
	}
}


export class ProductLineSegment {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.nameLocalizationKey = props.nameLocalizationKey || props.name_translation_key || '';
		this.displayOrder = props.displayOrder || props.display_order || '';

		const definitionsArray = props.definitions || [];
		this.definitions = [];
		for(const df of definitionsArray) {
			this.definitions.push(new ProductLineSegmentDefinition(df));
		}
	}
}


export class ProductLineSegmentDefinition {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.dataType = props.dataType || props.data_type || '';
		this.dataValue = props.dataValue || props.data_value || '';
		this.queryKey = props.queryKey || props.query_key || '';
		this.queryModel = props.queryModel || props.query_model || '';
		this.queryOrder = props.queryOrder || props.query_order || '';
	}

	get isProduct() {
		const { PROD_SEGMENT_MODEL_PRODUCT } = require('../constants/product');
		return PROD_SEGMENT_MODEL_PRODUCT === this.queryModel;
	}

	get isInventory() {
		const { PROD_SEGMENT_MODEL_INVENTORY } = require('../constants/product');
		return PROD_SEGMENT_MODEL_INVENTORY === this.queryModel;
	}

	get modelKey() {
		const keyMapping = {
			is_sealed: 'isSealed',
		};
		return keyMapping[this.queryKey] || this.queryKey;
	}

	get modelValue() {

		const { 
			PROD_SEGMENT_VALUE_TYPE_BOOL,
			PROD_SEGMENT_VALUE_TYPE_INT,
			PROD_SEGMENT_VALUE_TYPE_DOUBLE, 
		} = require('../constants/product');

		switch(this.dataType) {
			case PROD_SEGMENT_VALUE_TYPE_BOOL:
				if(this.dataValue === 'true') {
					return true;
				} else if(this.dataValue === 'false') {
					return false;
				}
				return !!this.dataValue;
			case PROD_SEGMENT_VALUE_TYPE_INT:
				return parseInt(this.dataValue);
			case PROD_SEGMENT_VALUE_TYPE_DOUBLE:
				return parseFloat(this.dataValue);
			default:
				return !!this.dataValue;
		}
	}
}


export class ProductLineDefault {

	constructor(props = {}) {

		if(!props) { props = {}; }

		const segmentObj = props.segment || props.product_line_segment || null;
		const weightUnitValue = props.weightUnit || props.weight_unit || '';

		this.publicUuid = props.publicUuid || props.public_uuid || '';

		this.weight = parseFloat(props.weight) || 0;
		this.weightUnit = isVarString(weightUnitValue) ? getWeightUnitFromKey(weightUnitValue) : weightUnitValue;
		this.weightG = convertWeightToG(this.weight, this.weightUnit);

		this.segment = segmentObj ? new ProductLineSegment(segmentObj) : null;
	}
}


export class ProductLinePricingMatrix {

	constructor(props = {}) {

		if(!props) { props = {}; }

		const segmentObj = props.lineSegment || props.product_line_segment || null;
		const productLineObj = props.productLine || props.product_line || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.finish = isVarObject(props.finish) ? props.finish : getFinishObjectFromServerResp(props.finish || null);
		this.isDefault = props.isDefault || props.is_default || false;
		this.isEnabled = props.isEnabled || props.is_enabled || false;

		const rulesArray = props.rules || [];
		this.rules = [];
		for(const ru of rulesArray) {
			this.rules.push(new ProductLinePricingMatrixRule(ru));
		}
		
		this.productLine = new ProductLine(productLineObj);
		this.lineSegment = segmentObj ? new ProductLineSegment(segmentObj) : null;
	}

	static defaultApiData() {
		const { PROD_PRICING_DEFAULT_MATRIX } = require('../constants/product');
		return PROD_PRICING_DEFAULT_MATRIX;
	}
}


export class ProductLinePricingMatrixRule {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.isParent = props.isParent || props.is_parent || false;
		this.condition = isVarObject(props.condition) ? props.condition : getConditionObjectFromServerResp(props.condition || null);
		this.language = isVarObject(props.language) ? props.language : getLanguageObjectFromServerResp(props.language || null);

		this.amountSell = parseFloat(props.amountSell) || parseFloat(props.mod_amount_sell / getCurrencyMinorCount()) || 0;;
		this.percentSell = parseFloat(props.percentSell) || parseFloat(props.mod_percent_sell) || 100.0;

		this.amountBuy = parseFloat(props.amountBuy) || parseFloat(props.mod_amount_buy / getCurrencyMinorCount()) || 0;;
		this.percentBuy = parseFloat(props.percentBuy) || parseFloat(props.mod_percent_buy) || 100.0;
	}

	getCreateApiData() {
		const apiData = {
      is_parent: false,
			condition: this.condition ? this.condition.key : null,
			language: this.language ? this.language.code : null,
			mod_percent_sell: this.percentSell,
      mod_amount_sell: this.amountSell,
      mod_percent_buy: this.percentBuy,
      mod_amount_buy: this.amountBuy,
		};
		return apiData;
	}

	getUpdateApiData() {
		const apiData = {
      mod_percent_sell: this.percentSell,
      mod_amount_sell: this.amountSell,
      mod_percent_buy: this.percentBuy,
      mod_amount_buy: this.amountBuy,
		};
		return apiData;
	}
}


export class BulkUpload {

	constructor(props = {}) {

		if(!props) { props = {}; }

		const productLineObj = props.productLine || props.product_line || {};

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.originalFilename = props.originalFilename || props.original_filename || '';

		this.batchCount = parseInt(props.batchCount) || parseInt(props.batch_count) || parseInt(props.processed_batches) || 0;
		this.records = parseInt(props.records) || 0;
		this.createDate = props.createDate || props.create_date || '';
		this.countError = parseInt(props.countError) || parseInt(props.count_error) || parseInt(props.total_error) || 0;
		this.countSkipped = parseInt(props.countSkipped) || parseInt(props.count_skipped) || parseInt(props.total_skipped) || 0;

		this.date = new Date(this.createDate);
		this.productLine = new ProductLine(productLineObj);
	}

	get totalBatches() {

		const { PROD_BULK_UPLOAD_CHUNK_SIZE } = require('../constants/product');

		return Math.ceil( this.records / PROD_BULK_UPLOAD_CHUNK_SIZE );
	}

	get percentComplete() {
		if(!this.records) { return 0; }
		return 100 * (this.batchCount / this.totalBatches);
	}

	get countProcessed() {
		
		if(!this.records) { return 0; }

		const { PROD_BULK_UPLOAD_CHUNK_SIZE } = require('../constants/product');

		return this.batchCount === this.totalBatches ? this.records : this.batchCount * PROD_BULK_UPLOAD_CHUNK_SIZE;
	}

	get countSuccess() {
		if(!this.countProcessed) { return 0; }
		return this.countProcessed - this.countError - this.countSkipped;
	}

	isComplete() {

		const { PROD_BULK_UPLOAD_CHUNK_SIZE } = require('../constants/product');

		return this.batchCount === Math.ceil( this.records / PROD_BULK_UPLOAD_CHUNK_SIZE );
	}

	isFresh() {
		const freshThreshold = 1*24*60*60*1000;
		return Date.now() - this.date.getTime() < freshThreshold;
	}
}


export class BulkUploadFormat {

	constructor(props = {}) {

		if(!props) { props = {}; }

		// Can't pass key since they are dependent on product line
		// Primarily a wrapper for the constant to prevent errors if key not found

		this.key = props.key || null;
		this.name = props.name || '';
		this.schema = props.schema || [];
	}
}



export class BulkUploadResult {

	constructor(props = {}) {

		if(!props) { props = {}; }

		this.statusValue = props.status || '';

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.serverError = props.error || '';
		this.lineData = props.lineData || props.import_data || {};
		this.lineNumber = props.lineNumber || props.line_number || {};
		this.debugData = props.debugData || props.debug_data || {};
	}

	get status() {

		if(!this.statusValue) { return {}; }

		const { PROD_BULK_UPLOAD_STATUSES } = require('../constants/product');

		for(const statusObj of PROD_BULK_UPLOAD_STATUSES) {
			if(statusObj.key === this.statusValue) {
				return statusObj;
			}
		}
		return {};
	}

	get message() {
		if(!this.statusValue) { return tx.TX_null; }
		if((this.isError || this.isSkipped) && this.serverError) {
			return formatServerError(this.serverError);
		}
		return tx.TX_null;
	}

	get errorCode() {
		try {
			return this.serverError && this.serverError.code ? this.serverError.code : '';
		} catch(err) {
			return '';
		}
	}

	get isSuccess() {

		const { PROD_BULK_UPLOAD_STATUS_SUCCESS } = require('../constants/product');

		return this.statusValue === PROD_BULK_UPLOAD_STATUS_SUCCESS.key;
	}

	get isError() {

		const { PROD_BULK_UPLOAD_STATUS_ERROR } = require('../constants/product');

		return this.statusValue === PROD_BULK_UPLOAD_STATUS_ERROR.key;
	}

	get isSkipped() {

		const { PROD_BULK_UPLOAD_STATUS_SKIPPED } = require('../constants/product');

		return this.statusValue === PROD_BULK_UPLOAD_STATUS_SKIPPED.key;
	}

	hasDebugData() {
		return Object.keys(this.debugData).length > 0;
	}

	containsData(needle, config = {}) {

		if(!needle) { return false; }

		const searchTerm = needle.toLowerCase();

		for(const dataKey in this.lineData) {
			if(this.lineData[dataKey].toLowerCase().includes(searchTerm)) {
				return true;
			}
		}
		return false;
	}
}


export class ProductTag {

	constructor(props) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
	}

	getApiData() {
		const apiData = {
			name: this.name,
		}
		if(this.publicUuid) {
			apiData['tag_uuid'] = this.publicUuid;
		}
		return apiData;
	}
}


export class ProductAttribute {

	constructor(props) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.key = props.key || null;
		this.value = props.value || '';
	}

	getApiData() {
		const apiData = {
			key: this.key,
			value_string: this.value,
		}
		if(this.publicUuid) {
			apiData['attribute_uuid'] = this.publicUuid;
		}
		return apiData;
	}
}


export class ProductCategory {

	constructor(props) {

		if(!props) { props = {}; }

		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.name = props.name || '';
		this.permalink = props.permalink || '';
		this.productCount = parseInt(props.productCount) || parseInt(props.product_count) || 0;
	}

	getApiData() {
		const apiData = {
			name: this.name,
			permalink: this.permalink,
		}
		if(this.publicUuid) {
			apiData['category_uuid'] = this.publicUuid;
		}
		return apiData;
	}
}


export class ProductSet {

	constructor(props = {}) {

		if(!props) { props = {}; }

		const { 
			PROD_ATTR_KEY_SET_CODE,
			PROD_ATTR_KEY_SET_NAME, 
		} = require('../constants/product');

		this.ATTRIBUTE_KEY_SET_CODE = PROD_ATTR_KEY_SET_CODE;
		this.ATTRIBUTE_KEY_SET_NAME = PROD_ATTR_KEY_SET_NAME;

		this.REQUIRED_ATTRIBUTE_KEYS = [
			this.ATTRIBUTE_KEY_SET_CODE,
			this.ATTRIBUTE_KEY_SET_NAME,
		];
		
		this.id = props.id || null;
		this.publicUuid = props.publicUuid || props.public_uuid || '';
		this.code = props.code || '';
		this.name = props.name || '';

		const attributeProps = props.attributes || [];

		for(const attr of attributeProps) {
			if(attr.key === this.ATTRIBUTE_KEY_SET_CODE) {
				this.code = attr.value;
			} else if(attr.key === this.ATTRIBUTE_KEY_SET_NAME) {
				this.name = attr.value;
			}
		}
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	static hasRequiredAttributes(attributes) {
		if(!attributes) { return false; }

		const instance = new ProductSet();
		const keys = attributes.map((attr) => attr.key);

		for(const reqKey of instance.REQUIRED_ATTRIBUTE_KEYS) {
			if(!keys.includes(reqKey)) {
				return false;
			}
		}
		return true;
	}

	getProductSetAttributes(product) {

		if(!product) {
			return [];
		}

		const respAttributes = [];
		for(const attr of product.attributes) {
			if(attr.key === this.ATTRIBUTE_KEY_SET_CODE) {
				const codeAttr = new ProductAttribute(attr);
				codeAttr.value = this.lookupKey;
				respAttributes.push(codeAttr);
			} else if(attr.key === this.ATTRIBUTE_KEY_SET_NAME) {
				const nameAttr = new ProductAttribute(attr);
				nameAttr.value = this.name;
				respAttributes.push(nameAttr);
			}
		}
		return respAttributes;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}

	getApiData() {
		const apiData = {
			name: this.name,
		}
		if(this.publicUuid) {
			apiData['set_uuid'] = this.publicUuid;
		}
		return apiData;
	}
}



















