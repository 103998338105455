import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { TABLE_VALUE_LINK_TARGET } from '../../../../../constants/tables';

import { 
  getActionLabel, 
  getActionLinkUrl, 
  isActionIcon, 
} from '../../../../../utils/table';

import { Icon } from '../../../../Icons/Icon';

let allActionCreators = Object.assign({});

export class LinkElement extends Component {

	render() {	

    const {t} = this.props;

    return <div className={'LinkElement'}>
      <div className='linkElementWrapper'>
        {TABLE_VALUE_LINK_TARGET === '_blank' ?
          <a href={getActionLinkUrl(this.props.schema, this.props.dataRow)}  className='leLink' target={TABLE_VALUE_LINK_TARGET}>
            {isActionIcon(this.props.schema) ?
              <div className='leIconWrapper'>
                <Icon 
                  value={getActionLabel(this.props.schema)}  
                  iconClass={'leIcon'} 
                  ellipsisLabel={false}
                  onClick={() => {}} />
              </div> :
              <div className='leTextWrapper'>{t(getActionLabel(this.props.schema))}</div>
            }
          </a> :
          <Link to={{ pathname: getActionLinkUrl(this.props.schema, this.props.dataRow), state: { from: this.props.linkFrom }}} className='leLink'>
          	{isActionIcon(this.props.schema) ?
          		<div className='leIconWrapper'>
                <Icon 
                  value={getActionLabel(this.props.schema)}  
                  iconClass={'leIcon'} 
                  ellipsisLabel={false}
                  onClick={() => {}} />
              </div> :
          		<div className='leTextWrapper'>{t(getActionLabel(this.props.schema))}</div>
          	}
          </Link>
        }
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(LinkElement));


