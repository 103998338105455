import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { getStoreLanguage } from '../../../../utils/language';

const allActionCreators = Object.assign({});

export class ProductDetailRowProduct extends Component {

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getTertiaryTitle() {
    if(!this.props.product) { return ''; }

    if(this.props.product.productSet) {
      return this.props.product.productSet.name;
    }

    return '';
  }

  getSupplamentalData() {
    const supArray = [{
      key: tx.TX_TOTAL_QUANTITY,
      value: this.props.product.totalQuantity.toLocaleString(this.getLanguage()),
    }];
    return supArray;
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductDetailRowProduct'}>
      <div className='productBlockLiner'>
        <div className='productBanner'>
          <div className='productPrimary' lang={this.props.product.languageCode || ''}>{this.props.product.nameWithTags}</div>
          <div className='productSecondary'>{this.props.product.productLine.name}</div>
          <div className='productTertiary'>{this.getTertiaryTitle()}</div>
        </div>
        <div className='productDetails'>
          {this.getSupplamentalData().map((ln, i) => {
            return <div className='detailLine' key={i}>
              <div className='detailTitle'>{t(ln.key)}</div>
              <div className='detailValue'>{ln.value}</div>
            </div>;
          })}
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRowProduct));