import React from 'react';
import { useTranslation } from 'react-i18next';

import { addHighlightTags } from '../../../utils/formatting';
import { getStoreLanguage } from '../../../utils/language';

import parentStyles from './_styles/AutocompleteOption.module.scss';
// import styles from './_styles/AutocompleteOptionUser.module.scss';

const AutocompleteOptionUser = (props) => {

  // Props
  const {
    user, 
    lastQuery, 
  } = props;

  // Internationalization
  const { i18n } = useTranslation();

  // Methods
  const getHighlightedAutocomplete = (suggestString) => {
    if (!lastQuery) {
      return suggestString;
    }
    return addHighlightTags(suggestString, lastQuery, parentStyles.suggestStrong);
  };

  // Render
  return (
    <div className={`${parentStyles.AutocompleteOptionUser} ${parentStyles.AutocompleteOption}`}>
      <div className={parentStyles.optionLiner}>
        <div
          className={parentStyles.optionLinePrimary}
          dangerouslySetInnerHTML={{
            __html: getHighlightedAutocomplete(user ? user.fullName(getStoreLanguage(i18n)) : ''),
          }}
        />
        <div
          className={parentStyles.optionLineSecondary}
          dangerouslySetInnerHTML={{
            __html: getHighlightedAutocomplete(user?.email || ''),
          }}
        />
      </div>
    </div>
  );
};
export default AutocompleteOptionUser;