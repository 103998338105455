import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_SEARCH } from '../../../../constants/icons';
import { PL_PERMALINK_MAGIC } from '../../../../constants/product';
import * as tx from '../../../../constants/strings';

import { ProductLine } from '../../../../models/products';

import { 
  simulateTab,
} from '../../../../utils/general';

import Checkbox from '../../../Input/Checkbox';
import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';

import * as productActionCreators from '../../../../actions/product';
import * as productLineActionCreators from '../../../../actions/product-line';
const allActionCreators = Object.assign({}, productActionCreators, productLineActionCreators);

export class FilterMagicSet extends Component {

  constructor(props) {
    super(props);

    this.state = {
      inputSearch: '',
      loading: false,
    }
  }

  async componentDidMount() {
    if(!this.getSetData()) {

      this.setState({ loading: true });

      const fixedLine = new ProductLine({ permalink: PL_PERMALINK_MAGIC });

      const setData = await this.props.productSetsByProductLine(fixedLine)
        .catch((errResp) => {
          console.error(errResp);
        });

      this.setState({ loading: false });

      if(!setData) { return null; }

      this.props.setCustomFilterOptions({
        [this.props.filterElement.key]: setData.data,
      });

      this.props.productLineSetAdHocData({
        [PL_PERMALINK_MAGIC]: {
          sets: setData.data,
        }
      });

    } else {
      this.props.setCustomFilterOptions({
        [this.props.filterElement.key]: this.getSetData(),
      });
    }
  }

  static isListElement() {
    return true;
  }

  getSetData() {
    if(this.props.productLine && this.props.productLine.data && this.props.productLine.data[PL_PERMALINK_MAGIC] && this.props.productLine.data[PL_PERMALINK_MAGIC].sets) {
      return this.props.productLine.data[PL_PERMALINK_MAGIC].sets;
    }
    return null;
  }

  changeSearch(evt) {
    this.setState({ inputSearch: evt.target.value });
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      simulateTab();
    }
  }

  clearInput() {
    this.setState({ inputSearch: '' });
  }

  getOptions(showAll = false) {

    if(!this.state.inputSearch) {
      return this.getSetData() || [];
    }

    const filteredOptions = [];
    const needle = this.state.inputSearch.toLowerCase();

    for(const elem of this.getSetData()) {

      const displayString = elem.name.toLowerCase();
      if(showAll || displayString.includes(needle)) {
        filteredOptions.push(elem);
      }
    }
    return filteredOptions;
  }

  getCurrentValue() {
    try {
      if(Object.keys(this.props.filters).includes(this.props.filterElement.key) === false) {
        return [];
      } else {
        return this.props.filters[this.props.filterElement.key].split(',');
      }
    } catch(err) {
      console.error(err);
    }
    return [];
  }

  toggleOption(evt, key) {

    if(evt) { evt.stopPropagation(); }

    const currentValue = this.getCurrentValue();
    if(currentValue.includes(key)) {
      if(currentValue.length === 1) {
        this.props.clearFilter();  
      } else {

        currentValue.splice(currentValue.indexOf(key), 1);

        this.props.setFilters({
          [this.props.filterElement.key]: currentValue.join(','),
        });
      }
    } else {

      currentValue.push(key);

      this.props.setFilters({
        [this.props.filterElement.key]: currentValue.join(','),
      });
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'FilterMagicSet FilterMultiselect AdminTableFilter'}>
      <div className='filterElementTitle'>
        <div className='filterElementTitleValue'>{t(this.props.filterElement.name)}</div>
        <div className='filterElementClearLink' onClick={this.props.clearFilter.bind(this)}>{t(tx.TX_CLEAR)}</div>
      </div>
      <div className='filterElementBody'>
        <div className='multiWrapper'>
          <div className='multiInputWrapper'>
            <div className='multiInput'>
              <div className='multiInputLiner'>

                <div className='multiQuickSearchInput'>
                  <input
                    type='text'
                    name={t(tx.TX_SEARCH)}
                    value={this.state.inputSearch}
                    placeholder={t(tx.TX_SEARCH)}
                    onChange={this.changeSearch.bind(this)}
                    onKeyPress={this.handleKeyPress.bind(this)} />
                  <div className='searchOverlay'>
                    <Icon value={ICON_SEARCH} iconClass='searchIcon' />
                  </div>
                  {this.state.inputSearch ?
                    <div className='clearOverlay' onClick={this.clearInput.bind(this)}>&times;</div> :
                    null 
                  }
                </div>
                <div className={`multiElements ${this.state.loading ? 'loading' : ''}`}>
                  {this.state.loading ?
                    <div className='loadingOptions'>
                      <div className='loadingIconWrapper'>
                        <LoadingIcon />
                      </div>
                      <div className='loadingLabel'>{t(tx.TX_LOADING)}</div>
                    </div> :
                    <>
                      {this.getOptions().length === 0 ?
                        <div className='noneFound'>{t(tx.TX_NO_RESULTS)}</div> :
                        <>
                          {this.getOptions().map((elem, i) => {
                            return <div className={`elementWrapper ${this.getCurrentValue().includes(elem.code) ? 'selected' : ''}`} key={elem.code} onClick={(evt) => this.toggleOption(evt, elem.code)}>
                              <div className='elementSelectLine'>
                                <div className='elementCheckboxWrapper'>
                                  <div className='elementCheckboxLiner'>
                                    <Checkbox 
                                      adminTheme={true}
                                      name={elem.name}
                                      id={`ms-elem-${elem.code}`}
                                      value={elem.code}
                                      checked={this.getCurrentValue().includes(elem.code)}
                                      onChange={() => {}} />
                                  </div>
                                </div>
                                <div className='elementDisplayWrapper'>
                                  <div className='elementDisplayLiner'>{elem.name}</div>
                                </div>
                              </div>
                            </div>;
                          })}
                        </>
                      }
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='multiResultsWrapper'>
            <div className='multiResults'>
              <div className='multiResultsBody'>
                {this.getOptions(true).map((opt, j) => {
                  
                  if(!this.getCurrentValue().includes(opt.code)) {
                    return null;
                  }

                  return <div className='resultRow' key={j}>
                    <div className='removeActionWrapper' onClick={(evt) => this.toggleOption(evt, opt.code)}>
                      <div className='removeX'>&times;</div>
                    </div>
                    <div className='resultNameWrapper'>
                      <div className='resultNameValue'>{this.props.filterElement.optionsTranslate ? t(opt.name) : opt.name}</div>
                    </div>
                  </div>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {
    productLine: state.productLine,
  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(FilterMagicSet));