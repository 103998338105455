import React from 'react';

import { isFeatureAvailable } from '../../utils/features';

import styles from './_styles/FeatureWrapper.module.scss';

export const FeatureWrapper = (props) => {
  
  // Props
  const {
    children,
    feature,
  } = props;

  // Conditional display
  if(!isFeatureAvailable(feature)) {
    return null;
  }

  // Render
  return (
    <div className={styles.FeatureWrapper}>
      {children}
    </div>
  );
};

export default FeatureWrapper;




