import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import styles from './_styles/OnePieceCardImage.module.scss';

const OnePieceCardImage = (props) => {
    
  // Props
  const {
    onepieceObj, 
    thumbnail, 
    onLoadLow, 
    placeholderImg,
    loadDelay,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  return (
    <div className={styles.OnePieceCardImage}>
      <div className={styles.imageWrapper}>
        {thumbnail ? (
          <StaticImage
            imgObj={{
              src: onepieceObj.primaryImageSrc(),
              alt: onepieceObj.localizedName,
              noTranslate: true,
            }}
            onImgLoad={onLoadLow}
          />
        ) : (
          <ProgressiveImage
            alt={onepieceObj.imageAlt(t)}
            className={styles.onepieceObjImageImg}
            src={onepieceObj.primaryImageSrc()}
            thumbnailSrc={onepieceObj.thumbnailImageSrc()}
            loadDelay={loadDelay}
            placeholder={placeholderImg}
            onLoadLow={onLoadLow}
          />
        )}
      </div>
    </div>
  );
};
export default OnePieceCardImage;