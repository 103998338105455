import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { breaksToBrs } from '../../../utils/formatting';
import { addMagicManaSymbols } from '../../../utils/magic';

import parentStyles from './_styles/ProductPageDetails.module.scss';
import styles from './_styles/ProductPageDetailsMagic.module.scss';

let allActionCreators = Object.assign({});

export class ProductPageDetailsMagic extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getForeignModel() {
    return this.props.modelOverride || this.props.productObj.foreignModel;
  }

  getProductName() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedName : this.getForeignModel().localizedName;
  }

  getFlipProductName() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedName : '';
  }

  getProductTypeLine() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedTypeLine : this.getForeignModel().localizedTypeLine;
  }

  getFlipProductTypeLine() {
    return this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedTypeLine : '';
  }

  getProductDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face1.localizedText : this.getForeignModel().localizedText;
    return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
  }

  getFlipProductDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face2.localizedText : '';
    return txt ? addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle)) : '';
  }

  getProductSecondaryDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face1.flavorText : this.getForeignModel().flavorText;
    return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
  }

  getFlipProductSecondaryDescription() {
    const txt = this.getForeignModel().multipleFaces ? this.getForeignModel().face2.flavorText : '';
    return txt ? addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle)) : '';
  }

  getCost() {
    return <div 
            className={styles.titleCost}
            dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.getForeignModel().multipleFaces ? this.getForeignModel().face1.manaCost : this.getForeignModel().manaCost, { shadow: true })}} />;
  }

  getFlipCost() {
    if(this.getForeignModel().multipleFaces) {
      return <div 
              className={styles.titleCost}
              dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.getForeignModel().face2.manaCost, { shadow: true })}} />;
    }    
    return null;
  }

  render() {

    if(!this.props.productObj || !this.getForeignModel() || !this.getForeignModel().isMagicCard) { return null; }

    return <div className={`${styles.ProductPageDetailsMagic} ${parentStyles.ProductPageDetails}`}>
      <div className={`${styles.detailsWrapper} ${parentStyles.detailsWrapper}`}>
        <div className={styles.magicWrapper} lang={this.getForeignModel().language}>
          <div className={styles.faceWrapper}>
            <div className={parentStyles.headerWrapper}>
              <div className={styles.titleWrapper} lang={this.getForeignModel().language}>
                <span className={styles.titleText}>{this.getProductName()}</span>
                {this.getCost()}
              </div>
              <div className={styles.typeLine}>{this.getProductTypeLine()}</div>
            </div>
            <div className={parentStyles.borderWrapper}>
              <div className={styles.magicDetails}>
                <div 
                  className={styles.magicDesc}
                  dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
                <div 
                  className={styles.magicSecondaryDesc}
                  dangerouslySetInnerHTML={{ __html: this.getProductSecondaryDescription() }} />
              </div>
            </div>
          </div>
          {this.getForeignModel().multipleFaces ?
            <div className={`${styles.faceWrapper} ${styles.face2}`}>
              <div className={parentStyles.headerWrapper}>
                <div className={`${styles.titleWrapper} ${styles.faceTwoTitleWrapper}`}>
                  <span className={styles.titleText}>{this.getFlipProductName()}</span>
                  {this.getFlipCost()}
                </div>
                <div className={styles.typeLine}>{this.getFlipProductTypeLine()}</div>
              </div>
              <div className={parentStyles.borderWrapper}>
                <div className={styles.magicDetails}>
                  <div 
                    className={styles.magicDesc}
                    dangerouslySetInnerHTML={{ __html: this.getFlipProductDescription() }} />
                  <div 
                    className={styles.magicSecondaryDesc}
                    dangerouslySetInnerHTML={{ __html: this.getFlipProductSecondaryDescription() }} />
                </div>
              </div>
            </div> :
            null
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetailsMagic));