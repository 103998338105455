import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  TX_EDIT, 
  TX_INV_CATEGORY_NUMBER_OF_PRODUCTS, 
  TX_NAME,
} from '../../../constants/strings';

import ActionButton from '../../Buttons/ActionButton';
import AdminPageSectionActions from '../../Admin/blocks/page/AdminPageSectionActions';
import AdminPageSectionViewElement from '../../Admin/blocks/page/AdminPageSectionViewElement';

import styles from './_styles/CategoryDetailsView.module.scss';

export const CategoryDetailsView = (props) => {
  
  // Props
  const {
    category,
    loading,
    toggleEdit, // Injected by AdminPageSectionToggle
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Render
  return (
    <div className={styles.CategoryDetailsView}>
      <AdminPageSectionViewElement
        label={TX_NAME}
        loading={loading}
        value={category?.name} />
      <AdminPageSectionViewElement
        label={TX_INV_CATEGORY_NUMBER_OF_PRODUCTS}
        loading={loading}
        value={category?.productCount} />
      <AdminPageSectionActions>
        <ActionButton
          adminTheme={true}
          disabled={loading}
          onClick={toggleEdit}>
          {t(TX_EDIT)}
        </ActionButton>
      </AdminPageSectionActions>
    </div>
  );
};

export default CategoryDetailsView;



