import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PROD_ATTR_KEY_SET_NAME } from '../../../../constants/product';
import * as tx from '../../../../constants/strings';

import { ProductSet } from '../../../../models/products';

import useFormValidationProductSet from '../../../../hooks/form-validation/useFormValidationProductSet';
import useFormValidationProductSetName from '../../../../hooks/form-validation/useFormValidationProductSetName';
import useSignal from '../../../../hooks/useSignal';

import { isFormValid } from '../../../../utils/form-validation';
import { formatServerError } from '../../../../utils/formatting';
import { dynamicSort } from '../../../../utils/sort';

import { 
  productAttributeUpdate, 
  productSetsByProductLine, 
  productSetCreate, 
} from '../../../../actions/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import SearchableDropdown from '../../../Input/SearchableDropdown';

export const BulkActionChangeSet = (props) => {

  const dispatch = useDispatch();
  
  // Props
  const {
    blockClose, 
    closeMethod, 
    refreshData,
    selectedRows, 
    submitSignal, 
  } = props;

  // State
  const [adding, setAdding] = useState(false);
  const [requestError, setRequestError] = useState(false);
  const [requestPending, setRequestPending] = useState(false);
  const [resetSignal, setResetSignal] = useState(0);
  const [setData, setSetData] = useState(null);
  const [setDataLoading, setSetDataLoading] = useState(false);

  // I18N
  const { t } = useTranslation();

  // Form
  const { 
    value: inputProductSet, 
    error: errorProductSet, 
    onChange: changeProductSet, 
    validate: validateProductSet, 
    setValue: setProductSet,
  } = useFormValidationProductSet(null);

  const { 
    value: inputProductSetName, 
    error: errorProductSetName, 
    onChange: changeProductSetName, 
    validate: validateProductSetName, 
    setValue: setProductSetName,
  } = useFormValidationProductSetName('');


  const validateAll = useCallback((config = {}) => {
    const errors = [];
    if(adding) {
      errors.push(validateProductSetName(null, config));
    } else {
      errors.push(validateProductSet(null, config));
    }
    return isFormValid(errors);
  }, [adding, validateProductSet, validateProductSetName]);


  const getProductLine = useCallback(() => {
  
    if(!selectedRows) { return null; }

    const uniqueValues = selectedRows.reduce((acc, prod) => {
      acc[prod.productLine.permalink] = true;
      return acc;
    }, {});

    return Object.keys(uniqueValues).length === 1 ? selectedRows[0].productLine : null;
  }, [selectedRows]);


  const refreshTableData = useCallback(async () => {      
    refreshData(false, false, true, false);
  }, [refreshData]);


  const saveSets = useCallback(async () => {

    const productLine = getProductLine();
    if(!productLine) {
      return null;
    }

    if(validateAll()) {

      blockClose(true);
      setRequestPending(true);

      let setToAssign = inputProductSet;

      if(adding) {

        const newSet = new ProductSet({
          name: inputProductSetName,
        });

        const createSetResp = await dispatch(productSetCreate(productLine, newSet.getApiData()))
          .catch((errResp) => {
            setRequestPending(false);
            setRequestError(formatServerError(errResp));
            blockClose(false);
          });

        if(!createSetResp) {
          return null;
        }

        setToAssign = createSetResp;
      }

      const setRequests = [];
      for(const prod of selectedRows) {
        const setAttributes = setToAssign.getProductSetAttributes(prod);
        for(const attr of setAttributes) {
          if(prod.getAttributeValue(attr.key) !== attr.value) {
            setRequests.push(dispatch(productAttributeUpdate(prod.productLine.permalink, prod.permalink, attr.getApiData())));
          }
        }
      }

      const attrRespArray = await Promise.all(setRequests)
        .catch((errResp) => {
          setRequestPending(false);
          setRequestError(formatServerError(errResp));
          blockClose(false);
        });

      if(!attrRespArray) {
        return null;
      }

      blockClose(false);
      refreshTableData();
      closeMethod();
    }
  }, [
    adding, 
    blockClose, 
    closeMethod, 
    dispatch, 
    getProductLine, 
    inputProductSet, 
    inputProductSetName, 
    refreshTableData, 
    selectedRows, 
    setRequestPending, 
    validateAll,
  ]);


  const fetchProductSets = useCallback(async () => {
  
    const productLine = getProductLine();
    if(!productLine) {
      return null;
    }

    setSetData(null);
    setSetDataLoading(true);

    const setData = await dispatch(productSetsByProductLine(productLine))
      .catch((errResp) => {
        console.error(errResp);
        setSetDataLoading(false);
      });

    setSetData(setData ? setData.data : null);
    setSetDataLoading(false);
  }, [dispatch, getProductLine]);


  const currentSetNames = () => {
    if(!selectedRows) { return []; }

    const uniqueNames = selectedRows.reduce((acc, prod) => {
      acc[prod.getAttributeValue(PROD_ATTR_KEY_SET_NAME)] = true;
      return acc;
    }, {});

    return Object.keys(uniqueNames).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));
  };

  const getSetOptions = () => {
    if(!setData) { return []; }

    const setResp = [];
    for(const st of setData) {
      setResp.push(st.toOption({ selfValue: true }))
    }
    return setResp.sort(dynamicSort('display'));
  };

  const toggleAdd = (initValue) => {
    setAdding(!adding);
    setProductSetName(initValue || '');
  };

  const resetSet = () => {
    setAdding(false);
    setProductSet(null);
    setProductSetName('');
    setResetSignal(Date.now());
  };

  // Save sets when receiving signal to submit
  useSignal(() => {
    if(submitSignal) {
      saveSets();
    }
  }, submitSignal);


  // Fetch product sets on load
  useEffect(() => {
    fetchProductSets();
  }, [fetchProductSets]);


  return (
    <div className={'BulkActionChangeSet BulkActionModal'}>
      <div className='baWrapper'>

        <div className={`baFormError ${requestError ? 'present' : ''}`}>{t(requestError || tx.TX_null)}</div>
        
        {!getProductLine() ?
          <div className='errorSelection'>
            <div className='errorBody'>
              <div className='errorLabel'>{t(!selectedRows || !selectedRows.length ? tx.TX_INV_TAGS_NO_PRODUCTS_SELECTED : tx.TX_INV_SETS_MUST_BE_SAME_PRODUCT_LINE)}</div>
              <button className={'errorAction'} type='button' onClick={closeMethod}>{t(tx.TX_CLOSE)}</button>
            </div>
          </div> :
              
          <div className='changeSetWrapper'>
            <div className='currentSetsWrapper'>
              <div className='currentSetsTitle'>{t(tx.TX_INV_SETS_CURRENT_SETS)}</div>
              {currentSetNames().length === 0 ?
                <div className='currentSetsNone'>{t(tx.TX_NONE)}</div> :
                <div className='currentSetsList'>
                  {currentSetNames().map((setName, i) => {
                    return <div key={i} className='currentSetName'>{setName}</div>
                  })}
                </div>
              }
            </div>
            <div className='setsHr'></div>
            <div className={'newSetWrapper adminForm'}>
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SET)}</div>
                {inputProductSet ?
                  <div className='selectedSet'>
                    <div className='selectedSetLiner'>
                      <div className='setName'>{inputProductSet.name}</div>
                      <div className='setClear' onClick={() => setProductSet(null)}>{t(tx.TX_CHANGE)}</div>
                    </div>
                  </div> :
                  <>
                    <div className='setFieldAction' onClick={resetSet}>{t(tx.TX_RESET)}</div>
                    {adding ?
                      <form 
                        className={'customSetAdminForm'}
                        onSubmit={saveSets}>
                        <div className='adminInputWrapper'>
                          <input 
                            type={'text'}
                            className={'pmInput'}
                            name={t(tx.TX_SET)}
                            value={inputProductSetName}
                            onChange={changeProductSetName}
                            onBlur={validateProductSetName}
                            placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_SET)}
                            required={false} />
                          {errorProductSetName ?
                            <div className={'FieldError'}>{t(errorProductSetName)}</div> :
                            null
                          }
                        </div>
                      </form> : 
                      <div className='adminInputWrapper'>
                        <div className='adminDropdownWrapper'>
                          <SearchableDropdown 
                            className={'adminDropdownSelect'}
                            options={getSetOptions()}
                            selectOption={changeProductSet}
                            loading={setDataLoading}
                            name={t(tx.TX_SET)}
                            placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_SET)}
                            required={false}
                            noTranslate={true}
                            adminTheme={true}
                            addAction={toggleAdd}
                            addLabel={tx.TX_INV_SETS_ADD_SET}
                            resetSignal={resetSignal} />
                        </div>
                        {errorProductSet ?
                          <div className={'FieldError'}>{t(errorProductSet)}</div> :
                          null
                        }
                      </div>
                    }
                  </>
                }
              </div>
            </div>
          </div>
        }

        {requestPending ?
          <div className='savingOverlay'>
            <div className='savingIconWrapper'>
              <LoadingIcon />
            </div>
            <div className='savingCopy'>{t(tx.TX_SAVING)}</div>
          </div> :
          null
        }

      </div>
    </div>
  );
};

export default BulkActionChangeSet;




