import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { TX_BACK } from '../../constants/strings';

import styles from './_styles/AdminTitle.module.scss';

let allActionCreators = Object.assign({});

export class AdminTitle extends Component {

  constructor(props) {
    super(props);

    this.state = {
      overviewOpen: false,
    };

    this.overviewRef = React.createRef();

    this.checkClick = this.checkClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.checkClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.checkClick, false);
  }

  checkClick(evt) {
    if(this.state.overviewOpen) {
      let targetElement = evt.target;
      do {
          if(targetElement === this.overviewRef.current) {
              return;
          }
          targetElement = targetElement.parentNode;
      } while (targetElement);
      this.toggleOverviewOpen(false);
    }  
  }

	getTitle() {
    if(this.props.titleData) {
      return this.props.t(this.props.title, this.props.titleData);
    }
    return this.props.t(this.props.title);
  }

  getOverviewComponent() {
    if(!this.props.overview) { return null; }

    const OverviewComponent = this.props.overview;
    return <OverviewComponent closeMethod={() => this.toggleOverviewOpen(false)} />;
  }

  toggleOverviewOpen(isOpen = null) {
    this.setState({ overviewOpen: isOpen === null ? !this.state.overviewOpen : isOpen });
  }

  render() {

    const {t} = this.props;

    return <div className={styles.AdminTitle}>

      <div className={styles.titleFull}>
        {this.props.breadcrumbs.map((crumb, i) => {
          return <span className={styles.crumbWrapper} key={i}>
            <Link to={crumb.urlDynamic || crumb.url} className={styles.crumbLink}>{t(crumb.title)}</Link>
            <span className={styles.crumbChevron}>&gt;</span>
          </span>;
        })}
        <span className={styles.pageTitle}>{this.props.title ? this.getTitle() : ''}</span>
      </div>

      <div className={styles.titleMobile}>
        {this.props.breadcrumbs.length ?
          <Link className={styles.backWrapper} to={this.props.breadcrumbs[this.props.breadcrumbs.length - 1].url}>
            <div className={styles.arrow}>&lt;</div>
            <div className={styles.label}>{t(TX_BACK)}</div>
          </Link> :
          null
        }
        <div className={`${styles.mobileTitle} ${!this.props.breadcrumbs.length ? styles.noBack : ''}`}>{this.props.title ? this.getTitle() : ''}</div>
      </div>

      {this.props.overview ?
        <div className={styles.titleOverview} ref={this.overviewRef}>
          <div className={styles.overviewIconWrapper} onClick={() => this.toggleOverviewOpen()}>
            <div className={`${styles.overviewIcon} FlexCenter`}>?</div>
          </div>
          <div className={`${styles.overviewDrawerWrapper} ${this.state.overviewOpen ? styles.open : ''}`}>
            <div className={styles.overviewDrawerLiner}>
              {this.getOverviewComponent()}
            </div>
          </div>
        </div> :
        null
      }
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTitle));