import React from 'react';
import { useTranslation } from 'react-i18next';

import StaticImage from '../Image/StaticImage';
import ProgressiveImage from '../Image/ProgressiveImage';

import styles from './_styles/WarhammerImage.module.scss';

const WarhammerImage = (props) => {
    
  // Props
  const {
    warhammerObj, 
    thumbnail, 
    onLoadLow, 
    loadDelay,
    placeholderImg,
  } = props;

  // Internationalization
  const { t } = useTranslation();

  return (
    <div className={styles.WarhammerImage}>
      <div className={styles.imageWrapper}>
        {thumbnail ? (
          <StaticImage
            imgObj={{
              src: warhammerObj.primaryImageSrc(),
              alt: warhammerObj.localizedName,
              noTranslate: true,
            }}
            onImgLoad={onLoadLow}
          />
        ) : (
          <ProgressiveImage
            alt={warhammerObj.imageAlt(t)}
            className={styles.warhammerObjImageImg}
            src={warhammerObj.primaryImageSrc()}
            thumbnailSrc={warhammerObj.thumbnailImageSrc()}
            loadDelay={loadDelay}
            placeholder={placeholderImg}
            onLoadLow={onLoadLow}
          />
        )}
      </div>
    </div>
  );
};
export default WarhammerImage;