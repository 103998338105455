import React from 'react';

import styles from './_styles/AdminPageSectionActions.module.scss';

export const AdminPageSectionActions = (props) => {
  
  // Props
  const {
    children,
  } = props;

  // Render
  return (
    <div className={styles.AdminPageSectionActions}>
      {children}
    </div>
  );
};

export default AdminPageSectionActions;




