import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as _ from 'underscore';

import { 
  ICON_CHEVRON_DOWN,
  ICON_FILTER,
  ICON_SEARCH, 
} from '../../../../constants/icons';
import * as tx from '../../../../constants/strings';

import { 
  controlsIsAddEnabled, 
  controlsIsFilterEnabled, 
  controlsIsQuickSearchEnabled, 
  getTableAddLink, 
  getTableFiltersDefault,
  getTableFiltersExtended, 
  getTableNoun,
  isTablePaginated, 
} from '../../../../utils/table';

import { Icon } from '../../../Icons/Icon';

let allActionCreators = Object.assign({});

export class AdminTableControls extends Component {

  constructor(props) {
    super(props);

    this.state = {
      buttonWrapperWidth: null,
      controlWidth: null,

      filtersOpen: false,
      filtersTransitioning: false,
    }

    // Create refs
    this.buttonWrapper = React.createRef();
    this.controlsSection = React.createRef();

    this.controlsFilters = React.createRef();

    // Bind event listeners
    this.filtersTransitionEnd = this.filtersTransitionEnd.bind(this);
    this.updateWidthStateThrottled = _.throttle(this.updateWidthState.bind(this), 250);
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWidthStateThrottled, false);

    if(this.buttonWrapper && this.buttonWrapper.current) {
      this.setState({ buttonWrapperWidth: this.buttonWrapper.current.offsetWidth });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidthStateThrottled, false);

    if(this.controlsFilters && this.controlsFilters.current) {
      this.controlsFilters.current.removeEventListener('transitionend', this.filtersTransitionEnd, false);
    }
  }

  updateWidthState() {
    if(this.controlsSection && this.controlsSection.current) {
      this.setState({ 
        controlWidth: this.controlsSection.current.clientWidth, 
      });
    }
  }

	getNoun() {
    return this.props.t(getTableNoun(this.props.config));
  }

  getFilterStyles() {

    const assembledStyles = {};

    if(controlsIsFilterEnabled(this.props.config) === false) {
      assembledStyles['display'] = 'none';
    }
    return assembledStyles;
  }

  getAddStyles() {

    const assembledStyles = {};

    if(controlsIsAddEnabled(this.props.config) === false) {
      assembledStyles['display'] = 'none';
    }
    return assembledStyles; 
  }

  getQuickSearchStyles() {

    const QS_MIN_WIDTH = 300;

    const assembledStyles = {};
    
    if(controlsIsQuickSearchEnabled(this.props.config) === false) {
      assembledStyles['display'] = 'none';
      return assembledStyles;
    }

    if(!this.buttonWrapper || !this.buttonWrapper.current) {
      return assembledStyles;
    }

    const qsWidth = this.buttonWrapper.current.parentNode.offsetWidth - this.buttonWrapper.current.offsetWidth;
    if(qsWidth < QS_MIN_WIDTH) {
      assembledStyles['display'] = 'block';
      assembledStyles['right'] = 'unset';
      assembledStyles['width'] = '100%';
      return assembledStyles;
    }
    
    assembledStyles['right'] = this.buttonWrapper.current.offsetWidth ? `${this.buttonWrapper.current.offsetWidth}px` : '0';

    return assembledStyles; 
  }

  overrideHeight() {
    if(controlsIsFilterEnabled(this.props.config) === false && controlsIsAddEnabled(this.props.config) === false && controlsIsQuickSearchEnabled(this.props.config) === false) {
      return { height: '0', minHeight: '0' };
    } else {
      return {};
    }
  }

  handleKeyPress(evt) {
    if(evt.key === 'Enter') {
      evt.preventDefault();
      if(this.props.submitQuickSearch) {
        this.props.submitQuickSearch();
      }
    }
  }

  filtersTransitionEnd() {
    this.setState({
      filtersTransitioning: false,
    }, () => {
      if(this.controlsFilters && this.controlsFilters.current) {
        this.controlsFilters.current.removeEventListener('transitionend', this.filtersTransitionEnd, false);
      }
    });
  }

  toggleFilterOpen() {

    if(this.controlsFilters && this.controlsFilters.current) {
      this.controlsFilters.current.addEventListener('transitionend', this.filtersTransitionEnd, false);
    }

    this.setState({
      filtersOpen: !this.state.filtersOpen,
      filtersTransitioning: true,
    });
  }

  clearAllFilters() {
    this.props.removeFilters(Object.keys(this.props.filters));
  }

  clearFilterKeys(filterElement) {
    if(!filterElement) { return null; }
    try {
      this.props.removeFilters(filterElement.apiKeys);
    } catch(err) {
      console.error(err);
    }
  }

  getFilterComponent(filterElement) {
    if(!filterElement || !filterElement.component) { return null; }
    try {
      const FilterComponent = filterElement.component;

      if(!FilterComponent) {
        return null;
      }

      return <FilterComponent
                config={this.props.config}
                clearFilter={() => this.clearFilterKeys(filterElement)}
                filters={this.props.filters}
                filterElement={filterElement}
                removeFilters={this.props.removeFilters.bind(this)}
                setCustomFilterOptions={this.props.setCustomFilterOptions.bind(this)}
                setFilters={this.props.setFilters.bind(this)} />;
    } catch(err) {
      console.error(err);
    }
    return null;
  }

  render() {

		const {t} = this.props;
	
    return <div className={'AdminTableControls'} style={this.overrideHeight()} ref={this.controlsSection}>

      <div className='quickSearchWrapper' style={this.getQuickSearchStyles()}>
        <form className='quickSearchForm' onSubmit={this.props.submitQuickSearch.bind(this)}>
          <div className='qsInnerWrapper'>
            <div className='qsInputWrapper'>
              <input 
                placeholder={t(tx.TX_PLACEHOLDER_QUICK_SEARCH)}
                value={this.props.quickSearchValue}
                onChange={this.props.changeQuickSearch.bind(this)}
                onKeyPress={this.handleKeyPress.bind(this)} />
            </div>
            {isTablePaginated(this.props.config) ?
              <div className='qsButtonWrapper' onClick={this.props.submitQuickSearch.bind(this)}>
                <div className='qsButtonInnerWrapper'>
                  <Icon 
                    value={ICON_SEARCH} 
                    iconClass='qsIcon' />
                </div>
              </div> :
              null
            }
          </div>
        </form>
      </div>

      <div 
        className={!controlsIsFilterEnabled(this.props.config) && !controlsIsAddEnabled(this.props.config) ? 'atcButtonsWrapper noButtons' : 'atcButtonsWrapper'} 
        ref={this.buttonWrapper}>
        <div className='filterLinkWrapper' style={this.getFilterStyles()}>
          <button 
            className={`filterButton ${this.state.filtersOpen ? 'open' : ''} ${this.state.filtersTransitioning ? 'transitioning' : ''}`}
            onClick={this.toggleFilterOpen.bind(this)}
            type='button'>
            <span className='filterButtonIcon'>
              {this.state.filtersOpen ?
                <Icon value={ICON_CHEVRON_DOWN} iconClass={'filterIcon'} /> :
                <Icon value={ICON_FILTER} iconClass='filterIcon' />
              }
            </span>
            <span className='filterButtonCopy'>{t(tx.TX_FILTERS)}</span>
          </button>
          <div className={`filterTabExt ${this.state.filtersOpen ? 'open' : ''}`}></div>
        </div>
        <div className='addButtonWrapper' style={this.getAddStyles()}>
          <Link className={'addButton addButtonDesktop'} to={getTableAddLink(this.props.config)}>{t(tx.TX_ADD_NOUN, {noun: this.getNoun()})}</Link>
          <Link className={'addButton addButtonMobile'} to={getTableAddLink(this.props.config)}>{t(tx.TX_ADD)}</Link>
        </div>
      </div>

      <div className={`filterToggleContainer ${this.state.filtersOpen ? 'open' : ''}`} ref={this.controlsFilters}>
        <div className='filterContainerLiner'>
          <div className={'filterBlock defaultBlock'}>
            <div className='filterBlockLiner'>
              <div className='filterBlockTitle'>
                <div className='filterBlockTitleValue'>{t(tx.TX_FILTERS)}</div>
                <div className='filterBlockTitleClear' onClick={this.clearAllFilters.bind(this)}>{t(tx.TX_CLEAR_ALL)}</div>
              </div>
              <div className='filterBlockBody'>
                {getTableFiltersDefault(this.props.config).map((filter, i) => {
                  return <div className='filterElement' key={i}>
                    <div className='filterElementLiner'>{this.getFilterComponent(filter)}</div>
                  </div>;
                })}
                {getTableFiltersExtended(this.props.config, this.props.filters).map((filter, j) => {
                  return <div className='filterElement' key={j}>
                    <div className='filterElementLiner'>{this.getFilterComponent(filter)}</div>
                  </div>;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>;
	}
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(AdminTableControls));


