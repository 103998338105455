import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import * as tx from '../../../../constants/strings';

import { ProductTag } from '../../../../models/products';

import { 
  productTagCreate, 
  productTagUpdate, 
  productTagDelete, 
} from '../../../../actions/product';

import LoadingIcon from '../../../Icons/LoadingIcon';
import ProductTagEntry from './ProductTagEntry';

import '../../_styles/_tagmanager.scss';

export const BulkTagManager = (props) => {

  const dispatch = useDispatch();
  
  // Props
  const {
    makeClean,
    products,
    productPending,
    setRequestError,
  } = props;


  // State
  const [adding, setAdding] = useState(false);


  // Internationalization
  const { t } = useTranslation();


  // Effects
  // None yet


  // Methods

  const sharedTags = () => {
    
    const tagsResp = [];

    if(!products || products.length === 0) {
      return tagsResp;
    }

    // First make an array of arrays of tag values; if have length zero, short circuit and return []
    const tagArrays = [];
    for(const prod of products) {
      const valueArray = prod.tags.map(tag => tag.name);
      if(valueArray.length === 0) {
        return [];
      }
      tagArrays.push(valueArray);
    }

    // Initialize the intersection set with the first array
    let intersection = new Set(tagArrays[0]);

    // Iterate over the array of arrays starting from the second array
    tagArrays.slice(1).forEach(ta => {
        // Create a set from the current array
        let currentSet = new Set(ta);
        // Filter the intersection set to only include elements also in the current set
        intersection = new Set([...intersection].filter(x => currentSet.has(x)));
    });

    // Convert the final intersection set to an array
    const values = Array.from(intersection);
    for(const val of values) {
      tagsResp.push(new ProductTag({ name: val }));
    }
    return tagsResp;
  };

  const tagSaved = () => {
    makeClean();
  };

  const openAdd = () => {
    setAdding(true);
  };

  const closeAdd = () => {
    setAdding(false);
  };

  const saveSharedTag = (tagValue, previousValue) => {

    const tagRequests = [];
    for(const prod of products) {

      const sharedTag = prod.getTagByValue(previousValue);
      const existingTag = prod.getTagByValue(tagValue);

      if(!sharedTag) {
        // Can't find shared tag, so shouldn't do anything
        continue;
      }

      if(existingTag) {
        // Tag already exists, so delete one being "changed"
        tagRequests.push(dispatch(productTagDelete(prod.productLine.permalink, prod.permalink, sharedTag.getApiData())));
      } else {
        // Tag being updated
        sharedTag.name = tagValue;
        tagRequests.push(dispatch(productTagUpdate(prod.productLine.permalink, prod.permalink, sharedTag.getApiData())));
      }
    }

    return Promise.all(tagRequests);
  };

  const addSharedTag = (tagValue, previousValue) => {
    
    // previousValue not used, but
    // keep it in props since this is also passed as saveOverride and we'll have access

    const tagRequests = [];
    for(const prod of products) {

      const existingTag = prod.getTagByValue(tagValue);

      if(existingTag) {
        // Tag already exists, so don't need to do anything
        continue;
      } else {
        // Tag to be added
        const addTag = new ProductTag({ name: tagValue.trim() });
        tagRequests.push(dispatch(productTagCreate(prod.productLine.permalink, prod.permalink, addTag.getApiData())));
      }
    }
    return Promise.all(tagRequests);
  };

  const deleteSharedTag = (tagValue) => {
    
    // Don't save previous tag

    const tagRequests = [];
    for(const prod of products) {

      const existingTag = prod.getTagByValue(tagValue);

      if(existingTag) {
        // Tag already exists, so don't need to do anything
        tagRequests.push(dispatch(productTagDelete(prod.productLine.permalink, prod.permalink, existingTag.getApiData())));
        continue;
      }
    }
    return Promise.all(tagRequests);
  };

  return (
    <div className='BulkTagManager'>
      <div className='tagManagerWrapper'>
        {!products && productPending ? 
          <div className='loadingWrapper'>
            <div className='loadingIconWrapper'>
              <LoadingIcon />
            </div>
          </div> :
          <div className='tagListWrapper'>
            {sharedTags().length > 0 ?
              <>
                <div className='tagNotice'>{t(tx.TX_INV_TAGS_ONLY_SHARED_SHOWN)}</div>
                <div className='tagList'>
                  {sharedTags().map((tag, i) => {
                    return <ProductTagEntry 
                              key={i} 
                              completeSave={tagSaved}
                              deleteOverride={deleteSharedTag}
                              product={products[0]}
                              productPending={productPending}
                              productTag={tag}
                              saveOverride={saveSharedTag}
                              setRequestError={setRequestError} />
                  })}
                </div>
              </> :
              <>
                {!adding ?
                  <div className='tagListNoResults'>
                    <div className='tagListNoResultsCopy'>{t(tx.TX_INV_TAGS_NO_SHARED_TAGS)}</div>
                  </div> :
                  null
                }
              </>
            }
            <div className='addWrapper'>
              {adding ?
                <div className='addOpen'>
                  <ProductTagEntry 
                    closeMethod={closeAdd}
                    completeSave={tagSaved}
                    deleteOverride={deleteSharedTag}
                    product={products[0]}
                    productPending={productPending}
                    productTag={null}
                    saveOverride={addSharedTag}
                    setRequestError={setRequestError} />
                </div> :
                <div className='addClosed'>
                  <div className='addLink' onClick={openAdd}>{t(tx.TX_INV_TAGS_ADD_TAG)}</div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BulkTagManager;

