import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { 
  TX_ADD, 
  TX_CATALOG, 
  TX_CATEGORIES, 
  TX_INV_CATEGORY_ADD, 
  TX_NAME, 
  TX_PERMALINK, 
  TX_PLACEHOLDER_CATEGORY_NAME,
  TX_PLACEHOLDER_CATEGORY_PERMALINK,
} from '../../constants/strings';
import { 
  URL_ADMIN_INVENTORY, 
  URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES, 
} from '../../constants/urls';

import { ProductCategory } from '../../models/products';

import { productCategoryCreate } from '../../actions/product';

import useFormValidationCategoryName from '../../hooks/form-validation/useFormValidationCategoryName';
import useFormValidationPermalink from '../../hooks/form-validation/useFormValidationPermalink';

import { formatServerError } from '../../utils/formatting';

import AdminTitle from '../Admin/AdminTitle';
import AdminField from '../Form/fields/AdminField';
import AdminFormWrapper from '../Form/AdminFormWrapper';
import CategoryPermalinkSupplement from '../Form/fields/supplements/CategoryPermalinkSupplement';

import styles from './_styles/AddCategory.module.scss';

export const AddCategory = (props) => {

  // Dispatch
  const dispatch = useDispatch();
  
  // Constants
  const initialValues = { 
    name: '', 
    permalink: '', 
  };

  // State
  const [syncPermalink, setSyncPermalink] = useState(true);
  const [requestError, setRequestError] = useState(null);
  const [requestPending, setRequestPending] = useState(false);

  // Form Hooks
  const nameHook = useFormValidationCategoryName('');
  const permalinkHook = useFormValidationPermalink('');

  // Methods
  const handleSubmit = async (formData, config = {}) => {

    let isSuccess = true;

    setRequestPending(true);
    setRequestError(null);

    const category = new ProductCategory({
      name: formData.name,
      permalink: formData.permalink,
    });

    await dispatch(productCategoryCreate(category.getApiData()))
      .catch((errResp) => {
        isSuccess = false;
        console.error(errResp);
        setRequestError(formatServerError(errResp));
      });

    setRequestPending(false);

    return isSuccess;
  };

  // Effects
  useEffect(() => {
    if(syncPermalink) {
      permalinkHook.setValue(nameHook.value);
    }
  }, [nameHook.value, syncPermalink, permalinkHook]);

  // Render
  return (
    <div className={`${styles.AddCategory} AdminPage`}>

      <AdminTitle
        title={TX_ADD}
        breadcrumbs={[
          {
            url: URL_ADMIN_INVENTORY,
            title: TX_CATALOG,
          },
          {
            url: URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES,
            title: TX_CATEGORIES,
          },
        ]} />

      <div className='adminBody'>
        <AdminFormWrapper 
          blockDirtyNav={true}
          cancelUrl={URL_ADMIN_INVENTORY_PRODUCT_CATEGORIES}
          error={requestError}
          initialValues={initialValues} 
          onSubmit={handleSubmit}
          requestPending={requestPending}
          title={TX_INV_CATEGORY_ADD}>

          <AdminField
            name='name'
            type='text'
            label={TX_NAME}
            placeholder={TX_PLACEHOLDER_CATEGORY_NAME}
            required={true}
            useCustomHook={nameHook} />

          <div style={{ display: 'none' }}>
            {/* Hidden because we don't use permalink on the frontend yet; added here to work with FormProvider */}
            <AdminField
              name='permalink'
              type='text'
              label={TX_PERMALINK}
              maxLength={32}
              placeholder={TX_PLACEHOLDER_CATEGORY_PERMALINK}
              required={true}
              supplement={CategoryPermalinkSupplement}
              useCustomHook={{
                ...permalinkHook,
                onChange: (e) => {
                  permalinkHook.onChange(e);
                  setSyncPermalink(false);
                },
              }} />
          </div>

        </AdminFormWrapper>
      </div>
      
    </div>
  );
};

export default AddCategory;




