import React from 'react';

import parentStyles from './_styles/ProductPageDetails.module.scss';
import styles from './_styles/ProductPageDetailsStarWars.module.scss';

const ProductPageDetailsStarWars = (props) => {
  
  // Constants
  const descriptionBreakStyle = { margin: '0 0 0.5em 0' };

  // Props
  const {
    productObj,
  } = props;

  // Methods
  const getProductDescription = () => {
    const { breaksToBrs } = require('../../../utils/formatting');    
    return breaksToBrs(productObj?.foreignModel?.bodyText, descriptionBreakStyle);
  };

  if(!productObj?.foreignModel?.isStarwarsCard) {
    return null;
  }

  return (
    <div className={`${styles.ProductPageDetailsStarWars} ${parentStyles.ProductPageDetails}`}>
      <div className={`${styles.detailsWrapper} ${parentStyles.detailsWrapper}`}>
        <div className={styles.starwarsWrapper} lang={productObj.foreignModel.language}>
          <div className={styles.faceWrapper}>
            <div className={parentStyles.headerWrapper}>
              <div className={styles.titleWrapper} lang={productObj.foreignModel.language}>
                <span className={styles.titleText}>{productObj?.foreignModel?.name || ''}</span>
              </div>
              <div className={styles.typeLine}>{productObj?.foreignModel?.type?.name || ''}</div>
            </div>
            <div className={parentStyles.borderWrapper}>
              <div className={styles.starwarsDetails}>
                <div
                  className={styles.starwarsDesc}
                  dangerouslySetInnerHTML={{ __html: getProductDescription() }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProductPageDetailsStarWars;
