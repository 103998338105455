
import { toDate } from 'date-fns-tz';

import { 
	API_FOREIGN_MODEL_ONEPIECE_CARD,
	API_FOREIGN_MODEL_ONEPIECE_SEALED,
	API_FOREIGN_MODEL_ONEPIECE_SET, 
} from '../../constants/api';
import { CONDITIONS_PRODUCT_TCG } from '../../constants/conditions';
import { FINISHES_ONEPIECE } from '../../constants/finishes';
import { 
	LANG_EN,
	LANG_PRODUCT_ONEPIECE, 
} from '../../constants/languages';
import { 
	ONEPIECE_CARD_SKU_PREFIX, 
	// ONEPIECE_SEALED_SKU_PREFIX, 
} from '../../constants/syncs/onepiece';
import { 
	TX_ONEPIECE, 
	TX_null, 
} from '../../constants/strings';

import { 
	CatalogTag,
	Product,
	ProductForeignModel,
	ProductSet,
} from '../products';

import { dynamicSort } from '../../utils/sort';

import OnePieceCardImage from '../../components/OnePiece/OnePieceCardImage';
import OnePieceCardThumbnail from '../../components/Gallery/blocks/elements/OnePieceCardThumbnail';
import ProductPageDetailsOnePiece from '../../components/ProductPage/blocks/ProductPageDetailsOnePiece';



export class OnepieceSet extends ProductSet {

	constructor(props) {

		super(props);
		
		this.id = props.id || null;
		this.code = props.code || props.set_code || this.code || '';
		this.name = props.name || props.set_name || this.name || '';
		this.cardCount = parseInt(props.cardCount) || parseInt(props.card_count) || 0;

		const releaseDateValue = props.releaseDate || props.release_date || null;
		this.releaseDate = null;
		if(releaseDateValue) {
			this.releaseDate = releaseDateValue instanceof Date ? releaseDateValue : toDate(releaseDateValue);
		}	
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_ONEPIECE_SET;
	}

	get lookupKey() {
		return this.code;
	}

	set lookupKey(val) {
		this.code = val;
	}

	toOption(config = {}) {

		const idValue = config.idValue || false;
		const selfValue = config.selfValue || false;

		let value = this.code;
		if(idValue) {
			value = this.id;
		} else if(selfValue) {
			value = this;
		}

		return {
			display: this.name,
			value: value,
			count: null,
		};
	}
}


export class OnepieceCard extends ProductForeignModel {

	constructor(props = {}) {

		super(props);

		this.id = props.id || null;
		this.name = props.name || '';
		this.bodyText = props.bodyText || props.body_text || '';
		this.languageCode = props.languageCode || props.lang || LANG_EN;
		this.rarity = props.rarity || '';
		this.collectorNumber = props.collectorNumber || props.collector_number || '';
		this.tcgplayerId = props.tcgplayerId || props.tcg_product_id || null;
		this.cost = parseInt(props.cost) || 0;
		this.power = parseInt(props.power) || 0;
		this.life = parseInt(props.life) || 0;
		this.counterPlus = parseInt(props.counterPlus) || parseInt(props.counter_plus) || 0;
				
		this.onepieceSet = new OnepieceSet(props.onepieceSet || props.onepiece_set || {});
		this.images = new OnepieceCardImages(props.images || props.card_media || {});

		const cardTypeObj = props.type || props.cardType || props.card_type || null;
		this.type = cardTypeObj ? new OnepieceCardType(cardTypeObj) : null;

		const cardSubtypeObj = props.subtype || props.cardSubtype || props.card_subtype || null;
		this.subtype = cardSubtypeObj ? new OnepieceCardSubtype(cardSubtypeObj) : null;

		const attributesArray = props.attributes || [];
		this.attributes = [];
		for(const aa of [ ...attributesArray ].sort(dynamicSort('sequence'))) {
			this.attributes.push(new OnepieceCardAttribute(aa));
		}

		const colorsArray = props.colors || [];
		this.colors = [];
		for(const cl of [ ...colorsArray ].sort(dynamicSort('sequence'))) {
			this.colors.push(new OnepieceCardColor(cl));
		}

		this.tags = [];
		const tagsArray = props.tags || [];
		for(const tg of tagsArray) {
			this.tags.push(new CatalogTag(tg));
		}
	}

	get isOnepieceCard() {
		return true;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_ONEPIECE_CARD;
	}

	get languageObj() {
		for(const lang of LANG_PRODUCT_ONEPIECE) {
			if(lang.code === this.languageCode) {
				return lang;
			}
		}
		return LANG_PRODUCT_ONEPIECE[0];
	}

	get exportInventoryDetails() {
		return true;
	}

	allConditions() {
		return CONDITIONS_PRODUCT_TCG;
	}

	hasFinish() {
		return true;
	}

	allFinishes() {
		return FINISHES_ONEPIECE;
	}

	get allowSealed() {
		return false;
	}

	get alwaysDisplayLanguage() {
		return true;
	}

	get setName() {
		return this.onepieceSet?.name || '';
	}

	primaryImageSrc(isBack = false) {
		return this.images?.primarySrc || OnepieceCardImages.defaultPrimarySrc();
	}

	thumbnailImageSrc(isBack = false) {
		return this.images?.thumbnailSrc || OnepieceCardImages.defaultThumbnailSrc();
	}

	getPrimaryImage(props = {}) {
		return <OnePieceCardImage 
		          onepieceObj={this}
		          loadDelay={0}
		          placeholderImg={OnepieceCardImages.defaultPrimarySrc()}
		          {...props} />;
	}

	getThumbnailImage(props = {}) {
		return <OnePieceCardImage 
		          onepieceObj={this}
		          loadDelay={0}
		          placeholderImg={OnepieceCardImages.defaultThumbnailSrc()}
		          noFlip={true}
		          thumbnail={true}
		          {...props} />;
	}

	imageAlt(t = null) {
	
		const cardName = this.name;
		const setName = this.setName;

		if(!cardName) {
			return t ? t(TX_ONEPIECE) : '';
		}

		const respArray = [ cardName ];
		if(setName) {
			respArray.push(setName);
		}
		if(t) {
			respArray.push(t(TX_ONEPIECE));
		}

		return respArray.join(' | ');
	}

	get componentThumbnailGallery() {
		return OnePieceCardThumbnail;
	}

	get componentProductPageDetails() {
		return ProductPageDetailsOnePiece;
	}

	get productPageLayoutClass() {
		const { PROD_PAGE_LAYOUT_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_CLASS_TCG;
	}

	get productPageDetailLayoutClass() {
		const { PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG } = require('../../constants/product');
		return PROD_PAGE_LAYOUT_DETAILS_CLASS_TCG;
	}

	createProduct(productLine) {
		return new Product({
			name: this.name,
			title: this.name,
			sku: this.productSku,
			permalink: this.productPermalink,
			description: '',

			product_line: productLine,

			foreignModelCode: this.foreignModelCode,
			foreignModel: this,

			languageObj: this.languageObj,
			languageCode: this.languageObj.code,			
		});
	}

	get productSku() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');

		const idBase = '000000000';
		const idString = this.id ? this.id.toString() : '';
		const leadingZeros = idBase.substring(0, idBase.length - idString.length);

		return `${ONEPIECE_CARD_SKU_PREFIX}${PROD_SKU_MANAGED_DEMARKATION}${leadingZeros}${idString}`.toLowerCase();
	}

	get productPermalink() {

		const { PROD_SKU_MANAGED_DEMARKATION } = require('../../constants/product');
		
		return this.productSku.replaceAll(PROD_SKU_MANAGED_DEMARKATION, '');
	}

	get set() {
		return this.onepieceSet || null;
	}

	nameWithTags(t = null) {
		
		let nameString = this.name;
		for(const tag of this.tags) {
			if(tag.shouldDisplay) {
				if(t) {
					nameString += ` (${tag.nameTranslationKey ? t(tag.nameTranslationKey) : tag.name})`;
				} else {
					nameString += ` (${tag.name})`;
				}
			}
		}
		return nameString;
	}
}


export class OnepieceSealed {

	constructor(props) {

		if(!props) { props = {}; }

		this.id = props.id || null;
	}

	get foreignModelCode() {
		return API_FOREIGN_MODEL_ONEPIECE_SEALED;
	}
}


export class OnepieceCardColor {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class OnepieceCardAttribute {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
		this.sequence = parseInt(props.sequence) || 0;
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class OnepieceCardType {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class OnepieceCardSubtype {

	constructor(props) {

		if(!props) { props = {}; }

		this.name = props.name || '';
		this.nameTranslationKey = props.nameTranslationKey || props.name_translation_key || '';
	}

	hasTranslation() {
		return !!this.nameTranslationKey;
	}

	get translation() {
		return this.nameTranslationKey || TX_null;
	}
}


export class OnepieceCardImages {

	constructor(props) {

		if(!props) { props = {}; }

		this.default = props.default || '';
	}

	static defaultPrimarySrc() {
		return '/img/product/onepiece/cardback.webp';
	}

	static defaultThumbnailSrc() {
		return '/img/product/onepiece/cardback.webp';
	}

	get primarySrc() {
		return this.default || OnepieceCardImages.defaultPrimarySrc();
	}

	get thumbnailSrc() {
		return this.default || OnepieceCardImages.defaultThumbnailSrc();
	}
}


// export class OnepieceImages {

// 	constructor(props) {

// 		if(!props) { props = {}; }

// 		this.default = props.default || '';
// 	}

// 	static defaultPrimarySrc() {
// 		return '/img/product/noimage.png';
// 	}

// 	static defaultThumbnailSrc() {
// 		return '/img/product/noimage.png';
// 	}

// 	get primarySrc() {
// 		return this.default || OnepieceCardImages.defaultPrimarySrc();
// 	}

// 	get thumbnailSrc() {
// 		return this.default || OnepieceCardImages.defaultThumbnailSrc();
// 	}
// }


























