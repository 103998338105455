import React from 'react';
import { Link } from 'react-router-dom';

import ConditionalWrapper from '../Utils/ConditionalWrapper';

import styles from './_styles/ActionButton.module.scss';

export const ActionButton = (props) => {
  
  // Constants
  const SIZE_MEDIUM = 'medium';

  // Props
  const {
    adminTheme = false,
    children,
    className,
    disabled,
    linkTo,
    onClick,
    size = SIZE_MEDIUM,
    style = {},
    type = 'button',
  } = props;

  // Methods
  const getClass = () => {

    const rootClass = styles.ActionButton;
    const sizeClass = size && size !== SIZE_MEDIUM ? styles[size] : '';
    const adminClass = adminTheme ? styles.adminTheme : '';
    const customClass = className || '';

    return `${rootClass} ${sizeClass} ${adminClass} ${customClass}`;
  };

  const handleClick = (evt) => {
    if(onClick) {
      onClick(evt);
    }
  };

  // Render
  return (
    <ConditionalWrapper
      condition={linkTo}
      wrapper={(children) => (
        <Link 
          className={styles.textLink}
          to={linkTo}>
          {children}
        </Link>
      )}>
      <button 
        type={type} 
        className={getClass()}
        disabled={!!disabled}
        onClick={handleClick}
        style={style}>
        {children}
      </button>
    </ConditionalWrapper>
  );
};

export default ActionButton;




