import React from 'react';
import FormProvider from '../../context/FormProvider';
import Form from './Form';

const FormWrapper = (props) => {
  
  const { initialValues, ...rest } = props;

  return (
    <FormProvider 
      initialValues={initialValues}>
      <Form {...rest} />
    </FormProvider>
  );
};

export default FormWrapper;
