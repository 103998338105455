import React from 'react';
import { useTranslation } from 'react-i18next';

import { TX_INV_CATEGORY_URL } from '../../../../constants/strings';

import supplementStyles from './_styles/FieldSupplement.module.scss';
import styles from './_styles/CategoryPermalinkSupplement.module.scss';

const CategoryPermalinkSupplement = (props) => {

  // Props
  const {
    value = '',
  } = props;

  // Internationalization
  const { t } = useTranslation();

  // Render
  return (
    <div className={`${supplementStyles.FieldSupplement} ${styles.CategoryPermalinkSupplement}`}>
      <span className={supplementStyles.urlLabel}>{t(TX_INV_CATEGORY_URL)}:</span>
      <span className={supplementStyles.urlValue}>{`${window.location.origin}/${value}`}</span>
    </div>
  );
};

export default CategoryPermalinkSupplement;
