import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { 
  ERROR_PRODUCT_EXISTS_PERMALINK, 
  ERROR_PRODUCT_EXISTS_SKU, 
} from '../../../../constants/errors';
import { ICON_CALENDAR } from '../../../../constants/icons';
import { WEIGHT_UNITS_ALL } from '../../../../constants/measurements';
import {
  PL_PERMALINK_WARHAMMER,
} from '../../../../constants/product';
import * as tx from '../../../../constants/strings';
import { URL_NS_SHOP } from '../../../../constants/urls';

import { Product } from '../../../../models/products';

import { 
  isFormValid, 
  getDateError, 
  getDescriptionError, 
  getNameError, 
  getPermalinkError, 
  getSKUError,
  getWeightError,
} from '../../../../utils/form-validation';
import { 
  formatServerError, 
  normalizeWeight,
  stringToPermalink,
} from '../../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../../utils/general';
import { 
  getLanguageObjectFromCode,
  getStoreLanguage, 
} from '../../../../utils/language';
import { 
  convertWeightBetweenUnits, 
  convertWeightToG, 
  getWeightUnitFromKey,
} from '../../../../utils/measurements';
import { dynamicSort } from '../../../../utils/sort';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Dropdown from '../../../Input/Dropdown';
import { Icon } from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';
import SavePrompt from '../../../Popups/SavePrompt';
import SearchableDropdown from '../../../Input/SearchableDropdown';
import Toggle from '../../../Input/Toggle';

import * as commonActionCreators from '../../../../actions/common';
import * as productActionCreators from '../../../../actions/product';
let allActionCreators = Object.assign({}, commonActionCreators, productActionCreators);

export class ProductDetailsEdit extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isDirty: false,

      inputStatus: this.props.productObj ? this.props.productObj.is_enabled : true,
      inputSKU: this.props.productObj && this.props.productObj.sku ? this.props.productObj.sku.toUpperCase() : '',
      inputName: this.props.productObj ? this.props.productObj.name : '',
      inputSet: this.props.productObj ? this.props.productObj.foreignSet : null,
      inputPermalink: this.props.productObj ? this.props.productObj.permalink : '',
      inputDescription: this.props.productObj ? this.props.productObj.description : '',
      inputWeight: this.props.productObj && this.props.productObj.weight ? normalizeWeight(convertWeightBetweenUnits(this.props.productObj.weight, this.props.productObj.weightUnit, getStoreDefaultWeightUnit()), getStoreDefaultWeightUnit()) : '',
      inputWeightUnit: getStoreDefaultWeightUnit(),
      inputReleaseDate: this.props.productObj ? this.props.productObj.releaseDate : '',
      
      errorSKU: '',
      errorName: '',
      errorSet: '', 
      errorPermalink: '',
      errorDescription: '',
      errorWeight: '', 
      errorReleaseDate: '', 

      requestPending: false,
      requestError: null,

      savePromptOpen: false, 

      setData: null,
      setDataLoading: false,
    };

    this.descriptionLimit = 8192;

    this.controllerSku = null;
    this.controllerPermalink = null;
  }

  componentDidMount() {
    this.getSetData();
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.remoteSave === true && prevProps.remoteSave === false) {
      this.saveAction();
    }
  }

  componentWillUnmount() {
    if(this.controllerSku) {
      this.controllerSku.abort();
    }
    if(this.controllerPermalink) {
      this.controllerPermalink.abort();
    }
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getLocale() {
    const lang = getLanguageObjectFromCode(this.getLanguage());

    if(!lang) { return ''; }
    return lang.locale || '';
  }

  makeDirty() {
    this.setState({ isDirty: true });
    this.props.makeDirty();
  }

  changeStatus(evt) {
    this.setState({
      inputStatus: !this.state.inputStatus, 
    });
    this.makeDirty();
  }

  changeName(evt) {
    this.setState({
      inputName: evt.target.value,
    }, () => {
      if(this.state.errorName) {
        this.validateName();
      }
    });
    this.makeDirty();
  }

  validateName() {
    this.setState({ errorName: getNameError(this.state.inputName) });
  }

  hasSetOptions() {
    if(!this.props.productObj || !this.props.productObj.productLine || !this.props.productObj.productLine.isManaged) { return false; }

    if(this.props.productObj.productLine.permalink === PL_PERMALINK_WARHAMMER) {
      return false;
    }
    
    return true;
  }

  async getSetData() {

    this.setState({
      setData: null,
      setDataLoading: true,
    });

    const setData = await this.props.productSetsByProductLine(this.props.productLine)
      .catch((errResp) => {
        console.error(errResp);
      });

    this.setState({
      setData: setData ? setData.data : null,
      setDataLoading: false,
    });
  }
  
  getSetOptions() {
    if(!this.state.setData) { return []; }

    const setResp = [];
    for(const st of this.state.setData) {
      setResp.push(st.toOption({ selfValue: true }))
    }
    return setResp.sort(dynamicSort('display'));
  }

  changeSet(val) {
    this.setState({
      inputSet: val,
    });
    this.makeDirty();
  }

  changeSKU(evt) {
    this.setState({
      inputSKU: evt.target.value.toUpperCase(),
    }, () => {
      if(this.state.errorSKU) {
        this.validateSKU();
      }
    });
    this.makeDirty();
  }

  validateSKU() {
    this.setState({ errorSKU: getSKUError(this.state.inputSKU) }, () => {
      if(!this.state.errorSKU) {
        
        // If SKU same as original, no validation needed
        if(this.state.inputSKU.toLowerCase() === this.props.productObj.sku.toLowerCase()) {
          return;
        }

        // Async call to check if product with that SKU exists
        if(this.controllerSku) {
          this.controllerSku.abort();
        }
        const controllerSku = new AbortController();
        this.controllerSku = controllerSku;

        this.props.productFetchSingleBySku(this.state.inputSKU, controllerSku.signal)
        .then((resp) => {
          if(resp && resp.id) {
            this.setState({ errorSKU: ERROR_PRODUCT_EXISTS_SKU });
          }
        })
        .catch((errResp) => {
          // Do nothing
        });
      }
    });
  }

  changePermalink(evt) {
    this.setState({
      inputPermalink: stringToPermalink(evt.target.value, true, 32),
    }, () => {
      if(this.state.errorPermalink) {
        this.validatePermalink();
      }
    });
    this.makeDirty();
  }

  validatePermalink() {
    this.setState({ errorPermalink: getPermalinkError(this.state.inputPermalink) }, () => {
      if(!this.state.errorPermalink) {

        // If permalink same as original, no validation needed
        if(this.state.inputPermalink.toLowerCase() === this.props.productObj.permalink.toLowerCase()) {
          return;
        }
        
        // Async call to check if product with that SKU exists
        if(this.controllerPermalink) {
          this.controllerPermalink.abort();
        }
        const controllerPermalink = new AbortController();
        this.controllerPermalink = controllerPermalink;

        this.props.productFetchSingle(this.state.inputPermalink, this.props.productObj.productLine.permalink, controllerPermalink.signal)
        .then((resp) => {
          if(resp && resp.id) {
            this.setState({ errorPermalink: ERROR_PRODUCT_EXISTS_PERMALINK });
          }
        })
        .catch((errResp) => {
          // Do nothing
        });
      }
    });
  }

  changeDescription(evt) {
    this.setState({
      inputDescription: evt.target.value,
    }, () => {
      if(this.state.errorDescription) {
        this.validateDescription();
      }
    });
    this.makeDirty();
  }

  validateDescription() {
    this.setState({ errorDescription: getDescriptionError(this.state.inputDescription, true) });
  }

  getProductUrl() {
    if(this.props.productObj && this.props.productObj.product_line && this.props.productObj.product_line.permalink) {
      return window.location.origin + '/' + URL_NS_SHOP + '/' + this.props.productObj.product_line.permalink + '/' + this.state.inputPermalink;
    }
    return '';
  }

  getDescritpionLength() {
    return this.state.inputDescription ? this.state.inputDescription.length : 0;
  }

  changeWeight(evt) {
    this.setState({
      inputWeight: evt.target.value,
    }, () => {
      if(this.state.errorWeight) {
        this.validateWeight(false);
      }
    });
    this.makeDirty();
  }

  validateWeight(shouldNormalize = true) {
    this.setState({ 
      inputWeight: shouldNormalize ? normalizeWeight(this.state.inputWeight, this.state.inputWeightUnit) : this.state.inputWeight, 
      errorWeight: getWeightError(this.state.inputWeight, true),
    });
  }

  changeWeightUnit(evt) {
    const previousUnit = this.state.inputWeightUnit;
    this.setState({
      inputWeightUnit: getWeightUnitFromKey(evt.target.value),
    }, () => {
      this.setState({
        inputWeight: normalizeWeight(convertWeightBetweenUnits(this.state.inputWeight, previousUnit, this.state.inputWeightUnit), this.state.inputWeightUnit),
      });
    });
    this.makeDirty();
  }

  getWeightUnitOptions() {
    const weightOptions = [];
    for(const ut of WEIGHT_UNITS_ALL) {
      weightOptions.push({
        display: ut.display,
        value: ut.key,
      });
    }
    return weightOptions;
  }

  changeReleaseDate(selectedDate) {
    this.setState({
      inputReleaseDate: selectedDate,
      isDirty: true,
    }, () => {
      if(this.state.errorReleaseDate) {
        this.validateReleaseDate();
      }
    });
  }

  validateReleaseDate() {
    this.setState({ errorReleaseDate: getDateError(this.state.inputReleaseDate, true) });
  }

  async saveAction(evt) {
    if(evt) { evt.preventDefault(); }
    if(this.validateAll()) {

      const editableProduct = new Product(this.props.productObj);

      editableProduct.sku = this.state.inputSKU;
      editableProduct.isEnabled = this.state.inputStatus;
      editableProduct.name = this.state.inputName;
      editableProduct.permalink = this.state.inputPermalink;
      editableProduct.description = this.state.inputDescription;

      editableProduct.weight = this.state.inputWeight;
      editableProduct.weightUnit = this.state.inputWeightUnit;
      editableProduct.weightG = convertWeightToG(this.state.inputWeight, this.state.inputWeightUnit);
      editableProduct.releaseDate = this.state.inputReleaseDate;

      editableProduct.foreignSet = this.state.inputSet;

      this.setState({
        requestPending: true,
        requestError: null,
      });

      await this.props.productEdit(editableProduct.getApiData(), this.props.productObj.permalink, this.props.productObj.productLine.permalink)
        .catch((errResp) => {
          this.setState({
            requestPending: false,
            requestError: formatServerError(errResp),
          });

          if(this.props.remoteSave) {
            this.props.finishRemote('details', true);
          }
          return null;
        });

      this.setState({
        requestPending: false,
        requestError: null,
      }, () => {

        if(this.props.remoteSave) {
          this.props.finishRemote('details', false, this.props.productObj.permalink !== this.state.inputPermalink ? this.state.inputPermalink : this.props.productObj.permalink);
        } else {
          this.props.makeClean('details', this.props.productObj.permalink !== this.state.inputPermalink ? this.state.inputPermalink : false);
        }
        this.props.toggleEdit();
      });

    } else {
      // Validation failed, pass back to parent
      this.props.finishRemote('details', true);
    }
  }

  validateAll() {
    let errorObj = {
      errorSKU: getSKUError(this.state.inputSKU),
      errorName: getNameError(this.state.inputName),
      errorPermalink: getPermalinkError(this.state.inputPermalink),
      errorDescription: getDescriptionError(this.state.inputDescription, true),
    };
    this.setState(errorObj);
    return isFormValid(errorObj);
  }

  toggleSavePrompt(evt) {
    if(evt) { evt.preventDefault(); }
    this.setState({ savePromptOpen: !this.state.savePromptOpen });
  }

  tryToToggle() {
    if(this.state.isDirty === true) {
      this.toggleSavePrompt();
    } else {
      this.props.toggleEdit();
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductDetailsEdit adminSection'}>
      <div className='adminSectionBody'>
        <div className='adminSectionBodyWrapper'>
          <form 
            className={'productDetailsForm adminForm'}
            onSubmit={this.saveAction.bind(this)}>
            <div className='adminFormTitle'>
              <div className='afTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_PRODUCT_DETAILS)}</div>
            </div>
            <div className={this.state.requestError ? 'adminFormError present' : 'adminFormError'}>{t(this.state.requestError)}</div>

            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_ENABLED_QUESTION)}</div>
              <div className='adminInputWrapper'>
                <div className='adminInputToggleWrapper'>
                  <Toggle
                    checked={this.state.inputStatus}
                    onToggle={this.changeStatus.bind(this)}
                    trueValue={tx.TX_ENABLED}
                    falseValue={tx.TX_DISABLED} />
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_SKU)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorSKU ? 'InputError' : ''}
                  value={this.state.inputSKU}
                  onChange={this.changeSKU.bind(this)}
                  onBlur={this.validateSKU.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_INV_SKU)}
                  maxLength={32} />
              </div>
              {this.state.errorSKU ?
                <div className={'FieldError'}>{t(this.state.errorSKU)}</div> :
                null
              }
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_NAME)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorName ? 'InputError' : ''}
                  value={this.state.inputName}
                  onChange={this.changeName.bind(this)}
                  onBlur={this.validateName.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_INV_NAME)}
                  maxLength={100} />
              </div>
              {this.state.errorName ?
                <div className={'FieldError'}>{t(this.state.errorName)}</div> :
                null
              }
            </div>
            {this.hasSetOptions() ?
              <div className='adminFieldWrapper'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_SET)}</div>
                {!this.state.inputSet ?
                  <div className='adminInputWrapper'>
                    <div className='adminDropdownWrapper'>
                      <SearchableDropdown 
                        className={'adminDropdownSelect'}
                        options={this.getSetOptions()}
                        selectOption={this.changeSet.bind(this)}
                        loading={this.state.setDataLoading}
                        name={t(tx.TX_SET)}
                        placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_SET)}
                        required={false}
                        noTranslate={true}
                        adminTheme={true} />
                    </div>
                    {this.state.errorSet ?
                      <div className={'adminError FieldError'}>{t(this.state.errorSet)}</div> :
                      null
                    }
                  </div> :
                  <div className='selectedSet'>
                    <div className='selectedSetLiner'>
                      <div className='setName'>{this.state.inputSet.name}</div>
                      <div className='setClear' onClick={() => this.changeSet(null)}>{t(tx.TX_CLEAR)}</div>
                    </div>
                  </div>
                }
              </div> :
              null
            }
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel adminRequired'}>{t(tx.TX_INV_PRODUCT_PERMALINK)}</div>
              <div className='adminInputWrapper'>
                <input
                  type='text'
                  className={this.state.errorPermalink ? 'InputError' : ''}
                  value={this.state.inputPermalink}
                  onChange={this.changePermalink.bind(this)}
                  onBlur={this.validatePermalink.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_PRODUCT_PERMALINK)}
                  maxLength={32} />
                {this.state.errorPermalink ?
                  <div className={'adminError FieldError'}>{t(this.state.errorPermalink)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <span className='plUrlLabel'>{t(tx.TX_INV_PRODUCT_URL)}:</span>
                  <span className='plUrlValue'>{this.getProductUrl()}</span>
                </div>
              </div>
            </div>
            <div className='adminFieldWrapper'>
              <div className={'adminFieldLabel'}>{t(tx.TX_DESCRIPTION)}</div>
              <div className='adminInputWrapper'>
                <textarea
                  className={this.state.errorDescription ? 'InputError' : ''}
                  value={this.state.inputDescription}
                  onChange={this.changeDescription.bind(this)}
                  onBlur={this.validateDescription.bind(this)}
                  placeholder={t(tx.TX_PLACEHOLDER_INV_DESCRIPTION)}
                  maxLength={this.descriptionLimit} />
                {this.state.errorDescription ?
                  <div className={'FieldError'}>{t(this.state.errorDescription)}</div> :
                  null
                }
                <div className={'FieldNotice fieldSupplement'}>
                  <div className='productCharacterCount'>
                    <span className='countNum'>{(this.descriptionLimit - this.getDescritpionLength()).toLocaleString(this.getLanguage())}</span>
                    <span className='countSlash'>/</span>
                    <span className='countNum'>{this.descriptionLimit.toLocaleString(this.getLanguage())}</span>
                    <span className='countCopy'>{t(tx.TX_CHARACTERS_REMAINING)}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className='adminFieldWrapper'>
              <div className='adminInputWrapper halfWidth'>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_WEIGHT)}</div>
                <div className={this.state.errorTime ? 'adminInputWeight fieldError' : 'adminInputWeight'}>
                  <div className='aiwLiner'>
                    <div className={'aiwInputElement aiwElement'}>
                      <input
                        type='text'
                        className={this.state.errorWeight ? 'InputError' : ''}
                        value={this.state.inputWeight}
                        onChange={this.changeWeight.bind(this)}
                        onBlur={this.validateWeight.bind(this)}
                        placeholder={t(tx.TX_PLACEHOLDER_WEIGHT)}
                        maxLength={100} />
                      {this.state.errorWeight ?
                        <div className={'FieldError'}>{t(this.state.errorWeight)}</div> :
                        null
                      }
                    </div>
                    <div className={'aiwPostScriptWrapper aiwElement'}>
                      <Dropdown 
                        className={'aiwPostSelect'}
                        options={this.getWeightUnitOptions()}
                        name={t(tx.TX_WEIGHT)}
                        value={this.state.inputWeightUnit.key}
                        noTranslate={true}
                        onChange={this.changeWeightUnit.bind(this)} />
                    </div>
                  </div>
                </div>
              </div>

              <div className={'adminInputWrapper halfWidth'}>
                <div className={'adminFieldLabel adminOptional'}>{t(tx.TX_RELEASE_DATE)}</div>
                <div className='adminInputDate'>
                  <div className={this.state.errorReleaseDate ? 'datePickerWrapper error' : 'datePickerWrapper'}>
                    <DatePicker 
                      selected={this.state.inputReleaseDate} 
                      onChange={(date) => this.changeReleaseDate(date)}
                      onBlur={this.validateReleaseDate.bind(this)}
                      locale={this.getLocale()}
                      closeOnScroll={true}
                      dateFormat='P'
                      placeholderText={t(tx.TX_PLACEHOLDER_DATE_SELECT)} />
                  </div>
                  <div className='calendarOverlay'>
                    <Icon value={ICON_CALENDAR} />
                  </div>
                  {this.state.errorReleaseDate ?
                    <div className={'adminError FieldError'}>{t(this.state.errorReleaseDate)}</div> :
                    null
                  }
                </div>
              </div>
            </div>

            <div className='adminActionRow'>
              <div 
                className={'adminAction adminActionCancel'} 
                onClick={this.tryToToggle.bind(this)}>
                {t(tx.TX_CANCEL)}
              </div>
              <button 
                className={'adminAction adminActionSave'} 
                type='submit'
                disabled={this.state.requestPending}>
                {t(tx.TX_SAVE)}
              </button>
            </div>
            <div className='adminFormPending' style={{display: this.state.requestPending ? 'block' : 'none'}}>
              <div className='adminFormPendingScreen'></div>
              <div className='adminFormPendingWrapper'>
                <LoadingIcon />
              </div>
            </div>
          </form>
        </div>
      </div>
      <SavePrompt
        open={this.state.savePromptOpen}
        closeMethod={this.toggleSavePrompt.bind(this)}
        onConfirm={this.saveAction.bind(this)}
        onCancel={this.props.toggleEdit.bind(this)} />
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailsEdit));