import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import AdminPageSectionStatic from './AdminPageSectionStatic';
import AdminPageSectionToggle from './AdminPageSectionToggle';
import LoadingIcon from '../../../Icons/LoadingIcon';

import styles from './_styles/AdminPageSection.module.scss';

export const AdminPageSection = (props) => {
  
  // Props
  const {
    children, 
    collapsible, 
    defaultCollapsed = false, 
    loading,
    title, 
    toggleable, 
    updateEditCount, 
  } = props;

  // State
  const [isCollapsed, setIsCollapsed] = useState(defaultCollapsed);

  // Internationalization
  const { t } = useTranslation();

  // Methods
  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Render
  return (
    <div className={styles.AdminPageSection}>
      <div className={styles.sectionLiner}>
        <div className={styles.sectionHeader}>
          <div className={styles.titleWrapper}>
            <div className={styles.titleValue}>{t(title)}</div>
          </div>
          {collapsible ?
            <div className={styles.toggleWrapper} onClick={toggleCollapsed}>
              {isCollapsed ? '+' : '—'}
            </div> :
            null
          }
        </div>
        <div className={`${styles.sectionBody} ${collapsible ? styles.collapsible : ''} ${!isCollapsed ? styles.open : ''}`}>
          <div className={styles.contentWrapper}>
            {toggleable ? (
              <AdminPageSectionToggle updateEditCount={updateEditCount}>{children}</AdminPageSectionToggle>
            ) : (
              <AdminPageSectionStatic>{children}</AdminPageSectionStatic>
            )}
          </div>
          <div className={`${styles.sectionLoading} ${loading ? styles.active : ''}`}>
            <div className={styles.screen}></div>
            <div className={styles.iconWrapper}>
              <LoadingIcon />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPageSection;




