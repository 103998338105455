import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { FEATURE_CATEGORIES } from '../../../../constants/features';
import { ICON_OPEN_EXTERNAL } from '../../../../constants/icons';
import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import { PROD_DEFAULT_KEY_WEIGHT } from '../../../../constants/product';
import * as tx from '../../../../constants/strings';

import { isFeatureAvailable } from '../../../../utils/features';
import { 
  breaksToBrs,
  normalizeWeight,
  serverDateFull,
} from '../../../../utils/formatting';
import { getStoreDefaultWeightUnit } from '../../../../utils/general';
import { getStoreLanguage } from '../../../../utils/language';
import { addMagicManaSymbols } from '../../../../utils/magic';
import { convertWeightBetweenUnits } from '../../../../utils/measurements';

import Icon from '../../../Icons/Icon';
import LoadingIcon from '../../../Icons/LoadingIcon';
import StaticImage from '../../../Image/StaticImage';

let allActionCreators = Object.assign({});


export class ProductDetailsView extends Component {

  constructor(props) {
    super(props);

    this.ACTION_KEY_CHANGE_SET = 'change-set';
    this.ACTION_KEY_EDIT_TAGS = 'edit-tags';
    this.ACTION_KEY_EDIT_CATEGORIES = 'edit-categories';

    this.descriptionElement = React.createRef();
    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  getLanguage() {
    const { i18n } = this.props;
    return getStoreLanguage(i18n);
  }

  getProductName() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.nameWithTags;
  }

  getProductLineName() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.productLine ? this.props.productObj.productLine.name : '';
  }

  getSku() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.productSet) {
      return this.props.productObj.productSet.name;
    }
    return this.props.productObj.sku.toUpperCase();
  }

  getProductDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      const txt = this.props.productObj.foreignModel.doubleSided ? this.props.productObj.foreignModel.face1.localizedText : this.props.productObj.foreignModel.localizedText;
      return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
    }
    return this.props.productObj.description ? breaksToBrs(this.props.productObj.description, this.descriptionBreakStyle) : '';
  }

  getFlipProductDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard && this.props.productObj.foreignModel.doubleSided) {
      return addMagicManaSymbols(breaksToBrs(this.props.productObj.foreignModel.face2.localizedText, this.descriptionBreakStyle));
    }
    return '';
  }

  shouldTruncateDescription() {
    if(!this.props.productObj) { return false; }
    if(this.props.productObj.foreignModel) {
      return true;
    }
    return false;
  }

  getProductLink() {
    if(!this.props.productObj) { return ''; }
    return this.props.productObj.getProductPageUrl();
  }

  getProductImage() {
    if(!this.props.productObj) { return <StaticImage imgObj={IMG_GENERIC_PRODUCT} />; }
    if(this.props.productObj.foreignModel && !this.props.productObj.foreignModel.useProductImages) {
      return this.props.productObj.foreignModel.getPrimaryImage()
    } else if(this.props.productObj.media && this.props.productObj.media.length) {
      return (<StaticImage imgObj={{
               src: this.props.productObj.media[0].src, 
               alt: this.props.productObj.media[0].caption ? this.props.productObj.media[0].caption : this.props.t(tx.TX_ALT_PRODUCT_IMAGE), 
               noTranslate: true,
             }} />);
    } else {
      return (<StaticImage imgObj={IMG_GENERIC_PRODUCT} />);
    }
  }

  isEditAllowed() {
    if(!this.props.productObj) { return true; }
    if(this.props.productObj.foreignModel) {
      // return !!this.props.productObj.foreignModel.useProductImages;
      return false;
    }
    return true;
  }

  isComponentImg() {
    if(!this.props.productObj) { return false; }
    return this.props.productObj.foreignModel ? true : false;
  }

  getCost() {
    if(!this.props.productObj) { return null; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      return <div 
              className='pdvTitleCost'
              dangerouslySetInnerHTML={{__html: addMagicManaSymbols(this.props.productObj.foreignModel.manaCost, { shadow: true })}} />;
    }    
    return null;
  }

  getSecondaryDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard) {
      const txt = this.props.productObj.foreignModel.doubleSided ? this.props.productObj.foreignModel.face1.flavorText : this.props.productObj.foreignModel.flavorText;
      return addMagicManaSymbols(breaksToBrs(txt, this.descriptionBreakStyle));
    }
    return '';
  }

  getFlipSecondaryDescription() {
    if(!this.props.productObj) { return ''; }
    if(this.props.productObj.foreignModel && this.props.productObj.foreignModel.isMagicCard && this.props.productObj.foreignModel.doubleSided) {
      return addMagicManaSymbols(breaksToBrs(this.props.productObj.foreignModel.face2.flavorText, this.descriptionBreakStyle));
    }
    return '';
  }

  // For managed products where language can live on product object
  getProductLanguage() {
    if(!this.props.productObj) { return this.getLanguage(); }
    if(this.props.productObj.foreignModel) {
      return this.props.productObj.foreignModel.language;
    }
    return this.getLanguage();
  }

  getWeightValue() {
    
    // Product weight
    if(this.props.productObj && this.props.productObj.weight) {
      return convertWeightBetweenUnits(this.props.productObj.weight, this.props.productObj.weightUnit, getStoreDefaultWeightUnit());
    }

    // Default
    if(this.props.productObj) {
      return this.props.productObj.productLine.getDefaultValue(PROD_DEFAULT_KEY_WEIGHT, this.props.productObj.segment);
    }

    return 0;
  }

  isWeightDefault() {
    if(!this.props.productObj) { return false;}

    return this.getWeightValue() === this.props.productObj.productLine.getDefaultValue(PROD_DEFAULT_KEY_WEIGHT, this.props.productObj.segment);
  }

  getWeightDisplay() {

    const defaultUnit = getStoreDefaultWeightUnit();

    return `${normalizeWeight(this.getWeightValue(), defaultUnit)} ${defaultUnit.display}`;
  }

  getProductActions() {
    const dropdownOptions = [
      {
        display: tx.TX_ACTIONS,
        value: '',
      },
      {
        display: tx.TX_INV_EDIT_TAGS,
        value: this.ACTION_KEY_EDIT_TAGS,
      },
    ];

    if(isFeatureAvailable(FEATURE_CATEGORIES)) {
      dropdownOptions.push({
        display: tx.TX_INV_EDIT_CATEGORIES,
        value: this.ACTION_KEY_EDIT_CATEGORIES,
      });
    }

    if(this.props.productObj && this.props.productObj.productSet) {
      dropdownOptions.push({
        display: tx.TX_INV_CHANGE_SET,
        value: this.ACTION_KEY_CHANGE_SET,
      });
    }

    return dropdownOptions;
  }

  triggerProductAction(evt) {
    const actionKey = evt.target.value;

    switch(actionKey) {
      case this.ACTION_KEY_CHANGE_SET:
        this.props.openChangeSetModal();
        break;
      case this.ACTION_KEY_EDIT_CATEGORIES:
        this.props.openCategoryManagerModal();
        break;
      case this.ACTION_KEY_EDIT_TAGS:
        this.props.openTagManagerModal();
        break;
      default:
        return null;
    }
  }

  render() {

    const {t} = this.props;

    return <div className={'ProductDetailsView adminSection'}>
      <div className='adminSectionTitle'>
        <div className='asTitleWrapper'>{t(tx.TX_INV_VIEW_PRODUCT_PRODUCT_DETAILS)}</div>
        <div className='asTitleActionsWrapper'>
          <div className='asTitleActions'>
            <select 
              className='asTitleActionElement'
              value={''} 
              disabled={this.props.productPending}
              onChange={this.triggerProductAction.bind(this)}>
              {this.getProductActions().map((actionObj, i) => {
                return <option key={i} value={actionObj.value}>{t(actionObj.display)}</option>;
              })}
            </select>
          </div>
        </div>
      </div>
      <div className={'adminSectionBody fixed'}>
        <div className='adminSectionBodyWrapper'>
          <div className='adminViewWrapper'>
            <div className='productViewWrapper'>
              <div className='productViewImageSection'>
                <div className={this.isComponentImg() ? 'componentImg productViewImageWrapper' : 'productViewImageWrapper'}>
                  {this.getProductImage()}
                </div>
              </div>
              <div className='productViewDetailSection'>
                <div className='pdvWrapper'>
                  <div className='pdvTitle'>
                    <span lang={this.getProductLanguage()}>{this.getProductName()}</span>
                    {this.getCost()}
                  </div>
                  <div className='pdvProductLine'>{this.getProductLineName()}</div>
                  <div className='pdvSku'>{this.getSku()}</div>
                  <div className='pdvLink'>
                    {this.props.productObj && this.props.productObj.is_enabled ?
                      <Link to={this.getProductLink()} target='_blank'>
                        <div className='pdvLinkWrapper'>
                          <div className='pdvLinkCopy'>{t(tx.TX_VIEW)}</div>
                          <div className='pdvLinkIcon'>
                            <Icon value={ICON_OPEN_EXTERNAL} iconClass='linkIcon' />
                          </div>
                        </div>
                      </Link> :
                      <div className={'pdvLinkWrapper disabled'}>
                        <div className='pdvLinkCopy'>{t(tx.TX_CURRENTLY_DISABLED)}</div>
                      </div>
                    }
                  </div>
                  <div className='pdvKeyValue'>
                    {this.props.productObj && this.props.productObj.releaseDate ?
                      <div className='kvRow'>
                        <div className='kvKey'>{t(tx.TX_RELEASE_DATE)}</div>
                        <div className='kvValue'>{serverDateFull(this.props.productObj.releaseDate)}</div>
                      </div> :
                      null
                    }
                    
                    <div className='kvRow'>
                      <div className='kvKey'>{t(tx.TX_WEIGHT)}</div>
                      <div className='kvValue'>
                        
                        {!this.getWeightValue() ?
                          <div className='kvNone'>{t(tx.TX_NONE)}</div> :
                          <>
                            <div className='kvPrimary'>{this.getWeightDisplay()}</div>
                            {this.isWeightDefault() ?
                              <div className='kvDefaultTag'>
                                <div className='kvDefaultTagValue'>{t(tx.TX_DEFAULT)}</div>
                              </div> :
                              null
                            }
                          </>
                        }

                      </div>
                    </div>
                  </div>
                  <div className={this.shouldTruncateDescription() ? 'pdvDescriptionWrapper unlimited' : 'pdvDescriptionWrapper'} ref={this.descriptionElement}>
                    <div 
                      className={'pdvDescription'} 
                      lang={this.getProductLanguage()} 
                      dangerouslySetInnerHTML={{ __html: this.getProductDescription() }} />
                    <div className='pdvDescriptionGradient'></div>
                  </div>
                  {this.getSecondaryDescription() ?
                    <div 
                      className='pdvSecondaryDescription' 
                      lang={this.getProductLanguage()}
                      dangerouslySetInnerHTML={{ __html: this.getSecondaryDescription() }} /> :
                    null
                  }
                  {this.getFlipProductDescription() ?
                    <div className={'pdvDescriptionWrapper unlimited flipDescription'}>
                      <div 
                        className='pdvDescription' 
                        lang={this.getProductLanguage()} 
                        dangerouslySetInnerHTML={{ __html: this.getFlipProductDescription() }} />
                    </div> :
                    null
                  }
                  {this.getFlipSecondaryDescription() ?
                    <div className='pdvSecondaryDescription' dangerouslySetInnerHTML={{ __html: this.getFlipSecondaryDescription() }} /> :
                    null
                  }
                </div>
              </div>
            </div>
            {this.isEditAllowed() ?
              <div className='adminViewButtonWrapper'>
                <div className={'productDetailsButton adminButton'} onClick={this.props.toggleEdit.bind(this)}>{t(tx.TX_EDIT)}</div>
              </div> :
              null
            }
          </div>
        </div>
      </div>
      <div className='adminSectionPending' style={{display: this.props.productPending ? 'block' : 'none'}}>
        <div className='adminSectionPendingScreen'></div>
        <div className='adminSectionPendingWrapper'>
          <LoadingIcon />
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailsView));