import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import * as tx from '../../../constants/strings';

import { breaksToBrs } from '../../../utils/formatting';

import styles from './_styles/ProductPageDetails.module.scss';

let allActionCreators = Object.assign({});

export class ProductPageDetails extends Component {

  constructor(props) {
    super(props);

    this.descriptionBreakStyle = { margin: '0 0 0.5em 0' };
  }

  render() {

    const {t} = this.props;

    return <div className={styles.ProductPageDetails}>
      <div className={styles.detailsWrapper}>
        <div className={styles.headerWrapper}></div>
        <div className={styles.borderWrapper}>
          {!this.props.productObj.description ?
            <div className={styles.genericDetails}>
              <div className={`${styles.noDesc} FlexCenter`}>{t(tx.TX_PRODUCT_PAGE_NO_DESCRIPTION)}</div>
            </div> :
            <div className={styles.genericDetails} dangerouslySetInnerHTML={{ __html: breaksToBrs(this.props.productObj.description, this.descriptionBreakStyle) }} />
          }
        </div>
      </div>
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductPageDetails));