import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ICON_VIEW } from '../../../../constants/icons';
import { IMG_GENERIC_PRODUCT } from '../../../../constants/images';
import * as tx from '../../../../constants/strings';
// import { TABLE_VALUE_LINK_TARGET } from '../../../../constants/tables';

import { 
  getMediaAlt, 
  getOrderedMedia, 
} from '../../../../utils/product';

import Icon from '../../../Icons/Icon';
import StaticImage from '../../../Image/StaticImage';

const allActionCreators = Object.assign({});

export class ProductDetailRowMedia extends Component {

  getProductThumbnail() {
    if(!this.props.product) { return null; }

    if(this.props.product.foreignModel && !this.props.product.foreignModel.useProductImages) {
      return this.props.product.foreignModel.getThumbnailImage();
    }

    const productMedia = getOrderedMedia(this.props.product);

    if(productMedia.length > 0) {
      return <StaticImage 
                imgObj={{
                  src: productMedia[0].src, 
                  alt: getMediaAlt(this.props.product, productMedia[0]), 
                  noTranslate: true,
                }} />;      
    }

    return <StaticImage 
              imgObj={IMG_GENERIC_PRODUCT} />;
  }

  render() {

    if(!this.props.product) {
      return null;
    }

    const { t } = this.props;
    const { TABLE_VALUE_LINK_TARGET } = require('../../../../constants/tables');

    return <div className={'ProductDetailRowMedia'}>
      {TABLE_VALUE_LINK_TARGET === '_blank' ?
        <a href={this.props.product.getAdminUrl()} className='mediaLinkWrapper' target={TABLE_VALUE_LINK_TARGET}>
          <div className='mediaWrapper'>{this.getProductThumbnail()}</div>
          <div className='mediaActionWrapper'>
            <div className='mediaAction'>
              <div className='mediaActionLiner'>
                <div className='mediaActionIcon'>
                  <Icon value={ICON_VIEW} />
                </div>
                <div className='mediaActionLabel'>{t(tx.TX_VIEW)}</div>
              </div>
            </div>
          </div>
        </a> :
        <Link to={this.props.product.getAdminUrl()} className='mediaLinkWrapper'>
          <div className='mediaWrapper'>{this.getProductThumbnail()}</div>
          <div className='mediaActionWrapper'>
            <div className='mediaAction'>
              <div className='mediaActionLiner'>
                <div className='mediaActionIcon'>
                  <Icon value={ICON_VIEW} />
                </div>
                <div className='mediaActionLabel'>{t(tx.TX_VIEW)}</div>
              </div>
            </div>
          </div>
        </Link>
      }
    </div>;
  }
}

function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, allActionCreators)(withTranslation()(ProductDetailRowMedia));